import Menu from '../Menu/Menu'
import { isAcademyExAu } from '../../lib/detectEnvironment'
import Footer from '../Footer/Footer'
import { useEffect, useState } from 'react'
import { createContentfulClient, getFooter, getMenu } from '../../lib/contentful'
import { TypeResourceSet } from '../../types/contentful'
import HeadBlock from '../SeoBlock/HeadBlock'

const ErrorBoundaryContent = () => {
  const [menu, setMenu] = useState<TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>>(null)
  const [footer, setFooter] = useState<TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>>(null)
  const contentfulClient = createContentfulClient()
  const isSubdomain = isAcademyExAu()

  useEffect(() => {
    const asyncGetters = async () => {
      const [menuData, footerData] = await Promise.all([
        getMenu(contentfulClient, isSubdomain),
        getFooter(contentfulClient, isSubdomain),
      ])

      setMenu(menuData)
      setFooter(footerData)
    }

    asyncGetters()
  }, [])

  return (
    <>
      <HeadBlock title="Something went wrong..." noIndex />
      {menu && <Menu menu={menu} theme={'Light Navigation'} />}
      <main className="mt-navbar py-24 flex flex-col items-center justify-center gap-16">
        <div className="flex flex-col justify-start gap-6 items-center text-center">
          <h2 className="text-4xl font-extrabold leading-10 font-['Obviously']">
            There’s been a glitch 😳
          </h2>
          <div className="flex flex-col gap-3">
            <h3 className="text-xl font-normal tracking-wide">
              Sorry, we’re not quite sure what went wrong.
            </h3>
            <h3 className="text-xl font-normal tracking-wide -mb-3">
              Try another page or {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
              <a
                className="text-accent-4-700 text-xl font-normal underline tracking-wide"
                href={'mailto:hello@academyex.com'}
              >
                contact us.
              </a>
            </h3>
          </div>
        </div>
      </main>
      {footer && <Footer menu={footer} colour={'#FFFFFF'} />}
    </>
  )
}

export default ErrorBoundaryContent
