import React from 'react'
import { TypeMarketoFormButtonBlock } from '../../types/contentful'
import Button, { contentfulSize, contentfulType } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import MarketoButton from '../Marketo/MarketoButton'

type MarketoFormButtonBlockProps = {
  entry: TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const MarketoFormModalBlock = ({ entry }: MarketoFormButtonBlockProps) => {
  const {
    title,
    buttonLabel,
    style,
    size,
    icon,
    marketoFormId,
    marketoAreaOfInterest,
    marketoLeadSource,
    mobileWidth,
  } = entry.fields || {}
  return (
    <MarketoButton
      title={title}
      marketoEnquiryFormId={marketoFormId}
      marketoAreaOfInterest={marketoAreaOfInterest}
      marketoLeadSource={marketoLeadSource}
    >
      {(handleClick, loading) => (
        <Button
          id={marketoFormId}
          buttonStyle={contentfulType[style]}
          size={contentfulSize[size]}
          onClick={handleClick}
          disabled={loading}
          mobileWidth={mobileWidth}
        >
          <Icon className="w-5 h-5 mr-2" name={icon} />
          {buttonLabel}
        </Button>
      )}
    </MarketoButton>
  )
}

export default MarketoFormModalBlock
