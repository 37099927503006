import React from 'react'
import classNames from 'classnames'
import { TypeListContentBlock } from '../../types/contentful'
import CTAListItem from './CTAListItem'

type CTAListBlockProps = {
  entry: TypeListContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  containerClassName?: string
}

const CTAListBlock = ({ entry, containerClassName }: CTAListBlockProps) => {
  return (
    <div
      className={classNames(
        'flex flex-col gap-[.7rem] text-sm seperator bg-white',
        containerClassName
      )}
    >
      {entry.fields.listItems.map(listItem => (
        <CTAListItem item={listItem} key={listItem?.sys?.id} />
      ))}
    </div>
  )
}

export default CTAListBlock
