import {
  TypeButton,
  TypeHeroBlock,
  TypeImageWithFocalPoint,
  TypeMarketoFormButtonBlock,
  TypeVideo,
} from '../../types/contentful'
import isDarkColor from '../../helpers/isDarkColor'
import { Icon } from '../Icon/Icon'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import VideoSlide from '../HeroSlider/VideoSlide'
import ImageSlide from '../HeroSlider/ImageSlide'
import { isContentfulType } from '../../lib/contentful'
import ReactMarkdown from 'react-markdown'
import React, { useRef } from 'react'
import { HeroBlockShapes } from '../../types/content'
import HubspotFormButtonBlock from '../MarketoFormButtonBlock/HubspotFormButtonBlock'
import Container from '../Container/Container'
import { Swiper, SwiperSlide } from 'swiper/react'
import { buildSwiperDefaultProps } from '../slider.constants'
import 'swiper/css'
import 'swiper/css/effect-fade'
import type SwiperCore from 'swiper'
import { MediaType } from '../../types/types'
import classNames from 'classnames'
import remarkBreaks from 'remark-breaks'

type NewHeroBlockProps = {
  entry: TypeHeroBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const HeroBlockV3 = ({
  entry: {
    fields: {
      preTitle,
      title,
      titleFontSize = 'Large',
      backgroundColor,
      description,
      content,
      ctaButtons,
      shape,
      backgroundShape,
      fontColor,
      accentColor,
      hideDescriptionMobile,
      isMobileMediaFirst = true,
    },
    sys,
  },
}: NewHeroBlockProps) => {
  const isDarkClassname = isDarkColor(backgroundColor) ? 'dark' : ''
  const swiperRef = useRef<SwiperCore>()

  const hasBulletpoints = /-\s/.test(description)
  return (
    <Container
      className="full-width mt-navbar py-6 px-4 xl:px-20"
      style={fontColor ? { color: fontColor } : {}}
      id={sys.id}
    >
      <div className="max-w-[90rem] md:mx-[3rem] xl:m-auto">
        <div
          className={`absolute top-0 left-0 bottom-0 right-0 -z-10 ${
            backgroundShape === HeroBlockShapes.Razor
              ? 'lg:razor max-w-[90rem] md:mx-[1rem] lg:mx-[3rem] xl:m-auto'
              : ''
          }`}
          style={{ background: `${backgroundColor}` }}
        ></div>

        <div
          className={`flex flex-col lg:flex-row justify-between lg:ml-10 text-center lg:text-start my-6 items-center gap-10 md:gap-6 xl:gap-16`}
        >
          <div
            className={classNames(
              'lg:w-1/2 flex flex-col md:mb-[2rem] lg:mb-0 flex-shrink-0 gap-6',
              isMobileMediaFirst && 'order-2 lg:order-none'
            )}
          >
            <div>
              {preTitle && (
                <h2
                  style={accentColor ? { color: accentColor } : {}}
                  className={`text-secondary-ui-600 full-width lg:constrained-width`}
                >
                  {preTitle}
                </h2>
              )}

              {title && (
                <ReactMarkdown
                  className="relative font-primary lg:constrained-width"
                  components={{
                    h1: props => (
                      <h1
                        className={classNames('', {
                          'text-6xl': titleFontSize === 'Large',
                          'text-5xl': titleFontSize === 'Medium-Large',
                          'text-[40px] leading-[48px]': titleFontSize === 'Medium',
                        })}
                        {...props}
                      />
                    ),
                    h2: props => (
                      <h2
                        className={classNames('', {
                          'text-6xl': titleFontSize === 'Large',
                          'text-5xl': titleFontSize === 'Medium-Large',
                          'text-[40px] leading-[40px]': titleFontSize === 'Medium',
                        })}
                        {...props}
                      />
                    ),
                  }}
                >
                  {title}
                </ReactMarkdown>
              )}
            </div>

            {!!description && (
              <ReactMarkdown
                remarkPlugins={[remarkBreaks]}
                className={classNames(
                  '[&>p>a]:text-[blue] [&>p>a]:underline [&>p>a]:underline-offset-4 font-secondary font-thin text-[1.25rem] leading-[2.188rem]',
                  hideDescriptionMobile && 'hidden md:block',
                  hasBulletpoints && 'text-left'
                )}
                linkTarget="_blank"
                components={{
                  // Removes margin if it's the last paragraph or the only paragraph
                  p: ({ ...props }) => {
                    return <p className={'only:m-0 last:m-0'} {...props} />
                  },
                }}
              >
                {description}
              </ReactMarkdown>
            )}

            {ctaButtons && (
              <div className="flex full-width lg:constrained-width item-center justify-center lg:justify-normal flex-col md:flex-row gap-6 flex-wrap [&>*:first-child]:w-full md:[&>*:first-child]:w-auto">
                {ctaButtons?.map((ctaButton, index) => (
                  <div
                    key={`ctaButton-${index}`}
                    className={index > 0 ? `lg:ml-0 lg:mt-0 ${isDarkClassname}` : ''}
                  >
                    {isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                      ctaButton,
                      'button'
                    ) &&
                      !!ctaButton?.fields?.url && (
                        <Button
                          id={ctaButton?.sys.id}
                          buttonStyle={contentfulType[ctaButton?.fields?.style]}
                          size={contentfulSize[ctaButton?.fields?.size]}
                          hoverColor={ctaButton?.fields?.hoverColor}
                          className="!h-[2.625rem] !md:h-[3.125rem]"
                          backgroundColor={
                            ctaButton.fields?.style?.toLocaleLowerCase() === contentfulType.Solid &&
                            accentColor
                              ? accentColor
                              : ctaButton?.fields?.color
                          }
                          textColor={
                            accentColor &&
                            ctaButton.fields?.style?.toLocaleLowerCase() === contentfulType.Solid &&
                            backgroundColor
                          }
                          href={ctaButton.fields?.url}
                          variant={contentfulVariant[ctaButton?.fields?.variant]}
                          urlBehaviour={ctaButton.fields?.urlBehaviour}
                          isVideoVariantTrackable={ctaButton?.fields?.isVideoVariantTrackable}
                          showOnMobileOnly={ctaButton?.fields?.showOnMobileOnly}
                          mobileWidth={ctaButton?.fields?.mobileWidth}
                        >
                          {ctaButton.fields.align !== 'Right' && ctaButton.fields.icon && (
                            <Icon className="w-5 h-5 mr-2" name={ctaButton.fields.icon} />
                          )}
                          {ctaButton.fields?.label}
                          {ctaButton.fields.align === 'Right' && ctaButton.fields.icon && (
                            <Icon className="w-5 h-5 ml-2" name={ctaButton.fields.icon} />
                          )}
                        </Button>
                      )}

                    {isContentfulType<
                      TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
                    >(ctaButton, 'marketoFormButtonBlock') && (
                      <HubspotFormButtonBlock
                        customClass="!h-[2.625rem] !md:h-[3.125rem]"
                        portalId={ctaButton.fields.portalId}
                        formId={ctaButton.fields.marketoFormId}
                        title={ctaButton.fields.title}
                        buttonLabel={ctaButton.fields.buttonLabel}
                        buttonColor={ctaButton.fields.buttonColor}
                        style={contentfulType[ctaButton?.fields?.style]}
                        textColor={
                          isDarkColor(
                            ctaButton.fields.buttonColor
                              ? ctaButton.fields.buttonColor
                              : backgroundColor
                          )
                            ? 'white'
                            : 'black'
                        }
                        size={contentfulSize[ctaButton?.fields?.size]}
                        icon={ctaButton.fields.icon}
                        buttonHoverColor={ctaButton.fields.buttonHoverColor}
                        formInstanceId={ctaButton.sys.id}
                        showOnMobileOnly={ctaButton?.fields?.showOnMobileOnly}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          {content?.length > 0 && (
            <Swiper
              {...buildSwiperDefaultProps({
                enabled: content.length > 1,
                horizontalClass:
                  'absolute flex justify-center z-40 !bottom-1 left-1/2 transform -translate-x-1/2',
              })}
              className={`!w-full lg:!w-auto ${content?.length > 1 ? '!pb-10' : ''}`}
              onBeforeInit={swiper => {
                swiperRef.current = swiper
              }}
              enabled={content?.length > 1}
              key={`swiper-hero-block-${title}`}
            >
              {content
                ?.filter(
                  slide =>
                    (isContentfulType<TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                      slide,
                      'video'
                    ) &&
                      slide.fields.variant === MediaType.VIDEO) ||
                    isContentfulType<TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                      slide,
                      'imageWithFocalPoint'
                    )
                )
                ?.map((slide, index) => {
                  return (
                    <SwiperSlide key={index} virtualIndex={index}>
                      {isContentfulType<TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                        slide,
                        'video'
                      ) ? (
                        slide?.fields?.image?.fields?.image?.fields?.file?.url ? (
                          <div className="full-width md:constrained-width self-center">
                            <div
                              className={classNames(
                                'm-auto w-[20rem] md:w-[30rem] md:h-[22rem]',
                                slide.fields.variant === 'Video' &&
                                  'lg:w-[40rem] aspect-video lg:aspect-square'
                              )}
                            >
                              <VideoSlide
                                block={slide}
                                shape={shape}
                                className="relative m-auto top-0 left-0 w-[20rem] aspect-video lg:aspect-square md:w-[30rem] md:h-[22rem]"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center justify-center lg:justify-start">
                            <VideoSlide
                              block={slide}
                              shape={shape}
                              className="lg:ml-[2.5rem] w-[22em] md:w-[33rem]"
                            />
                          </div>
                        )
                      ) : isContentfulType<
                          TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
                        >(slide, 'imageWithFocalPoint') ? (
                        <ImageSlide
                          block={slide}
                          shape={shape}
                          className="self-center relative m-auto top-0 left-0 w-[21.5rem] h-[20rem] md:w-[28rem] md:h-[27rem] xl:w-[30rem]"
                        />
                      ) : (
                        <></>
                      )}
                    </SwiperSlide>
                  )
                })}
              {content?.length > 1 && (
                <>
                  <Icon
                    name="ArrowLeft"
                    className="cursor-pointer absolute bottom-0 left-1/2 -translate-x-14 z-50"
                    onClick={() => swiperRef.current?.slidePrev()}
                  />
                  <Icon
                    name="ArrowRight"
                    className="cursor-pointer absolute bottom-0 left-1/2 translate-x-10 z-50"
                    onClick={() => swiperRef.current?.slideNext()}
                  />
                </>
              )}
            </Swiper>
          )}
        </div>
      </div>
    </Container>
  )
}

export default HeroBlockV3
