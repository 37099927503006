import React from 'react'
import Head from 'next/head'

const AcademyEXFavIcon = () => {
  return (
    <Head>
      <link rel="apple-touch-icon" sizes="180x180" href="/academyex/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/academyex/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/academyex/favicon-16x16.png" />
      <link rel="manifest" href="/academyex/site.webmanifest" />
      <link rel="mask-icon" href="/academyex/safari-pinned-tab.svg" color="#fff" />
      <meta name="msapplication-TileColor" content="#fff" />
      <meta name="theme-color" content="#fff" />
    </Head>
  )
}

export default AcademyEXFavIcon
