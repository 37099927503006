import { TypeFeaturedContentBlock, TypeFeaturedContentBlockItem } from '../../types/contentful'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Icon } from '../Icon/Icon'
import Button from '../Button/Button'
import { Swiper, SwiperSlide } from 'swiper/react'
import type SwiperCore from 'swiper'
import { TypeEvent } from '../../types/contentful'
import { getEvents, getArticles } from '../../lib/contentful'
import { SSR_LOCALE } from '../../config/locale'
import { useContentfulClient } from '../../context/ContentfulClient/ContentfulClientContext'
import { SocialEvent } from '../../types/graphql'
import { ICourseExtended } from '../../types/types'
import useLocalisedContent from '../../context/Location/useLocalisedContent'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'

import { useContentfulResources } from '../ContentfulDocument/ContentfulResourcesProvider'
import isDarkColor from '../../helpers/isDarkColor'
import TitleBlock from '../TitleBlock/TitleBlock'
import { buildSwiperDefaultProps } from '../slider.constants'
import { RenderCard } from '../Card/RenderCard'

const FeaturedContentBlockFetchContent = ({
  preTitle,
  title,
  CTALink,
  CTALabel,
  featuredContentBlockItems,
  colour,
  align,
  description,
  backgroundShape,
  cardType,
}: TypeFeaturedContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']) => {
  const swiperRef = useRef<SwiperCore>()

  const [cards, setCards] = useState<Array<ICourseExtended>>([])
  // const { courses } = useContentfulResources()
  const { events } = useContentfulResources()

  const localizedCourses = useLocalisedContent(cards)
  const contentfulClient = useContentfulClient()

  const getEventCards = async () => {
    const eventsResult = await getEvents(contentfulClient)
    const contentfulEvents = events.filter(
      (event: SocialEvent) =>
        eventsResult.map(e => e.fields.slug[SSR_LOCALE]).includes(event.slug) &&
        event?.instances?.length
    )

    const filteredEvents = eventsResult
      .filter((event: TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>) =>
        contentfulEvents.map(e => e.slug).includes(event.fields.slug[SSR_LOCALE])
      )
      .sort((a, b) => {
        const aDate = new Date(a.sys.createdAt)
        const bDate = new Date(b.sys.createdAt)
        return aDate.getTime() - bDate.getTime()
      })

    return filteredEvents
  }

  // const getCourseCards = async () => {
  //   const courseResults = await getCourses(contentfulClient)
  //   const contentfulCourses = courses.filter(
  //     (course: Course) =>
  //       courseResults.map(c => c.fields.slug[SSR_LOCALE]).includes(course.slug) &&
  //       course?.publicIntakes?.length
  //   )
  //   const filteredCourses = courseResults.filter(
  //     (course: TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>) =>
  //       contentfulCourses.map(c => c.slug).includes(course.fields.slug[SSR_LOCALE])
  //   )

  //   return shuffleArray(mergeManyItemFields(filteredCourses, contentfulCourses, [])).slice(0, 2)
  // }

  const getArticlesCards = async () => {
    const articlesResults = await getArticles(contentfulClient)

    articlesResults
      .sort((a, b) => {
        const aDate = new Date(a.fields.postedDate[SSR_LOCALE])
        const bDate = new Date(b.fields.postedDate[SSR_LOCALE])
        return bDate.getTime() - aDate.getTime()
      })
      .splice(4)

    return articlesResults
  }

  const handleCards = async () => {
    // const cardTypes = [
    //   { flag: showEvent, fetchFunction: getEventCards },
    //   { flag: showCourses, fetchFunction: getCourseCards },
    //   { flag: showArticles, fetchFunction: getArticlesCards },
    // ]

    const cardsToSet = []
    // const selectedCardTypes = cardTypes.filter(cardType => cardType.flag).length
    // const cardsPerType = selectedCardTypes > 1 ? 2 : 4

    // for (const cardType of cardTypes) {
    //   if (cardType.flag) {
    //     const cards = await cardType.fetchFunction()
    //     // const shuffledCards = shuffleArray(cards).slice(0, cardsPerType)
    //     cardsToSet.push(...cards)
    //   }
    // }

    if (cardType === 'Articles') {
      const articles = await getArticlesCards()
      cardsToSet.push(...articles)
    }

    if (cardType == 'Events') {
      const events = await getEventCards()
      cardsToSet.push(...events)
    }

    // const shuffledCards = shuffleArray(cardsToSet)

    // setCardFields(cardsToSet)
    setCards(cardsToSet)
  }

  // const setCardFields = cards => {
  //   cards?.forEach(card => {
  //     card.fields.size = {
  //       [SSR_LOCALE]: 'Small',
  //     }
  //     card.fields.shape = {
  //       [SSR_LOCALE]: 'Square',
  //     }
  //   })
  // }

  useEffect(() => {
    handleCards()
  }, [])

  const isDark = colour && isDarkColor(colour)
  const containerClasses = `full-width py-3 md:py-6 lg:py-12 ${isDark ? 'text-white dark' : ''}`
  const itemAlignment = useMemo(() => {
    switch (align) {
      case 'Left':
        return 'justify-start'
      case 'Center':
        return 'justify-center'
      case 'Right':
        return 'justify-end'
      default:
        return 'justify-start'
    }
  }, [align])
  const backgroundStyle = useMemo(() => {
    switch (backgroundShape) {
      case 'Bolt':
        return 'bolt-wide'
      case 'Bubble':
        return 'bubble-wide'
      case 'Cube':
        return 'cube-wide'
      case 'Tab':
        return 'tab-wide'
      default:
        return ''
    }
  }, [backgroundShape])

  const descriptionBottom =
    (
      featuredContentBlockItems as TypeFeaturedContentBlockItem<
        'WITHOUT_UNRESOLVABLE_LINKS',
        string
      >[]
    )?.[0]?.fields?.type === 'Promotion'
  return (
    <div className={containerClasses} style={backgroundShape ? {} : { backgroundColor: colour }}>
      <div className="container mx-auto">
        <div
          className={`absolute h-[88%] w-full container mx-auto top-28 left-0 bottom-16 sm:bottom-6 right-0 -z-10 ${
            backgroundStyle || ''
          }`}
          style={backgroundShape ? { backgroundColor: colour } : {}}
        />
        <div className="flex items-end justify-between mb-9 sm:mb-12">
          <div className={`-mb-8 w-full`}>
            <TitleBlock
              title={title}
              preTitle={preTitle}
              headingType={`h2`}
              align={align ? align.toLowerCase() : 'left'}
              size={`5xl`}
              description={descriptionBottom ? '' : description}
            />
          </div>

          {/* Hidden on Mobile */}
          {CTALink && CTALabel && (
            <Button className="hidden sm:flex flex-shrink-0" buttonStyle="outline" href={CTALink}>
              {CTALabel}
              <Icon className="ml-1 w-4 h-4" name="ArrowRight" />
            </Button>
          )}
        </div>

        {/* For devices greater than SM */}
        <div
          className={`hidden sm:grid lg:flex sm:grid-cols-2 auto-rows-[0px] overflow-hidden gap-x-8 grid-rows-1 ${itemAlignment}`}
        >
          {cards &&
            cards.length > 0 &&
            localizedCourses.map((item, index) => {
              return (
                index <= 3 && (
                  <div className="lg:w-[23%]" key={index}>
                    <RenderCard
                      key={`${item.sys.id}`}
                      item={item}
                      hidePrice={cardType === 'Events'}
                    />
                  </div>
                )
              )
            })}
        </div>

        {descriptionBottom && (
          <div className="relative text-center mt-4 text-xs">{description}</div>
        )}

        {/* For mobile */}
        <div className="sm:hidden">
          <Swiper
            {...buildSwiperDefaultProps({
              enabled: cards?.length > 1,
              horizontalClass:
                'absolute flex justify-center z-40 top-1 left-1/2 transform -translate-x-1/2',
            })}
            onBeforeInit={swiper => {
              swiperRef.current = swiper
            }}
            key={`swiper-featured-content-block-${title}`}
          >
            <div className="mt-12">
              {localizedCourses?.map((item, index) => {
                return (
                  <SwiperSlide key={index} className="mt-14 mb-5">
                    {/* For mobile all the sizes are "Large Square" */}
                    <RenderCard
                      key={`${item.sys.id}`}
                      item={item}
                      hidePrice={cardType === 'Events'}
                    />
                  </SwiperSlide>
                )
              })}
            </div>
            <Icon
              name="ArrowLeft"
              className="cursor-pointer absolute top-0 left-1/2 -translate-x-14 z-50"
              onClick={() => swiperRef.current?.slidePrev()}
            />
            <Icon
              name="ArrowRight"
              className="cursor-pointer absolute top-0 left-1/2 translate-x-10 z-50"
              onClick={() => swiperRef.current?.slideNext()}
            />
          </Swiper>
          {CTALink && CTALabel && (
            <div className="flex justify-end">
              <Button className="sm:hidden" buttonStyle="outline" href={CTALink}>
                {CTALabel}
                <Icon className="ml-1 w-4 h-4" name="ArrowRight" />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FeaturedContentBlockFetchContent
