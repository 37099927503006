import { useState, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'

const useAccordionAnimation = (initialState: boolean, speed: number) => {
  const [open, setOpen] = useState(initialState)
  const [animating, setAnimating] = useState(false)
  const animate = useDebouncedCallback(() => setAnimating(false), speed)

  const toggleAnimation = useCallback(() => {
    if (animating) return
    setAnimating(true)
    setOpen(!open)
    animate()
  }, [animating, setAnimating, setOpen, open, animate])

  return { open, animating, toggleAnimation }
}

export default useAccordionAnimation
