import { Icon } from '../Icon/Icon'
import ReactPlayer from 'react-player/lazy'
import { Dialog } from '@headlessui/react'
import useVideoAnalyticsTracking from '../../helpers/hooks/useVideoAnalyticsTracking'

type FullscreenVideoProps = {
  onClose: () => void
  url: string
  id?: string
  isTrackable?: boolean
  title?: string
}

const FullscreenVideo = ({ onClose, url, id, isTrackable, title }: FullscreenVideoProps) => {
  const { handlers } = useVideoAnalyticsTracking(
    { videoUrl: url, contentfulId: id, contentfulTitle: title },
    isTrackable
  )

  return (
    <Dialog open onClose={onClose} as={'div'} className="z-[1001] fixed inset-0 flex bg-black-80">
      <Dialog.Panel className="mx-auto my-auto w-[80%] relative max-w-[1400px] flex justify-center aspect-video">
        <button
          type="button"
          onClick={onClose}
          className="w-8 h-8 cursor-pointer gap-2 bg-[#BFCAC8] text-[#1A1A1A] border-1 flex-row px-2 py-1 text-sm border-[#BFCAC8] absolute right-3 top-3 flex items-center justify-center rounded-full transition-colors duration-300 z-10"
        >
          <Icon className="w-3 h-3" name={'Close'} />
        </button>
        <ReactPlayer
          width={'100%'}
          height={'100%'}
          url={url}
          controls={true}
          playing={true}
          {...handlers}
        />
        {/* skeleton loader */}
        <div className="bg-black opacity-5 absolute -z-10 top-0 w-full h-full animate-pulse"></div>
      </Dialog.Panel>
    </Dialog>
  )
}

export default FullscreenVideo
