import React, { ReactNode } from 'react'
import { TypeImageWithFocalPoint, TypeQuoteContentBlock, TypeVideo } from '../../types/contentful'
import Image from 'next/legacy/image'
import Link from 'next/link'
import getFocalRatio from '../../helpers/getFocalRatio'
import { getImageWithFocalPoint } from '../contentful-ui-utils'
import VideoSlide from '../HeroSlider/VideoSlide'
import Quote from '../Icons/Quote'
import { Icon } from '../Icon/Icon'
import { MediaType } from '../../types/types'
import useTextClamp from '../../helpers/hooks/useTextClamp'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { isContentfulType } from '../../lib/contentful'
import ImageSlide from '../HeroSlider/ImageSlide'

type QuoteCardProps = {
  entry: TypeQuoteContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const QuoteCard = ({ entry }: QuoteCardProps) => {
  const {
    copy,
    person,
    redirect,
    content,
    courseName,
    courseUrl,
    backgroundColor,
    accentColor,
    subtitleColor,
  } = entry.fields
  const photo = getImageWithFocalPoint(
    person?.fields?.hero as
      | TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
      | TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>
  )
  const focalRatio = getFocalRatio(photo?.fields)
  const { isExpanded, shouldClamp, clampTextRef, toggleExpand } = useTextClamp(6)
  // manually change the line-clamp when needed
  const clampStyle = shouldClamp && !isExpanded ? 'line-clamp-6' : ''
  const buttonLabel = isExpanded ? 'Show less' : 'Show more'

  const LinkWrapper = ({ href, children }: { href: string; children: ReactNode }) => {
    return href ? (
      <Link href={href} legacyBehavior>
        {children}
      </Link>
    ) : (
      <>{children}</>
    )
  }

  const cssVars = {
    '--backgroundColor': backgroundColor,
    '--accentColor': accentColor,
    '--subtitleColor': subtitleColor,
  } as React.CSSProperties

  const renderFooter = (
    <div
      className={classNames(
        'relative flex items-center p-2 lg:p-5',
        accentColor ? 'bg-[--accentColor]' : 'bg-accent-4-50'
      )}
    >
      {photo?.fields?.image?.fields?.file?.url && (
        <div
          className={`relative w-12 h-12 rounded-full overflow-clip border-primary-ui-white border-2 shrink-0`}
        >
          <Image
            src={`https:${photo.fields.image.fields.file.url}`}
            alt={photo.fields.title}
            layout="fill"
            objectFit="cover"
            objectPosition={`${focalRatio?.x || 50}% ${focalRatio?.y || 50}%`}
          />
        </div>
      )}

      {!photo?.fields?.image?.fields?.file?.url && (
        <div className="rounded-full overflow-clip border-primary-ui-white border-2 shrink-0 bg-secondary-ui-50 w-12 h-12 flex items-center justify-center">
          <Icon name={'AcademyEXLogo'} className="w-5 h-5 scale-110 text-secondary-ui-200" />
        </div>
      )}

      <div className={'text-primary-ui-900 text-sm lg:text-base ml-3'}>
        <div
          className={`font-bold`}
          title={`${person?.fields?.firstName} ${person?.fields?.lastName}`}
        >
          {person?.fields?.firstName} {person?.fields?.lastName}
        </div>

        {!courseName && (
          <span
            className={'capitalize text-accent-4-700 font-thin line-clamp-2'}
            title={person?.fields?.title}
          >
            {person?.fields?.title}
          </span>
        )}

        {courseName && (
          <div
            className={classNames('font-thin  line-clamp-2', {
              'hover:underline': courseUrl,
              'text-[--subtitleColor]': subtitleColor,
              'text-accent-4-700': !subtitleColor && courseUrl,
            })}
          >
            <span title={courseName}>
              <LinkWrapper href={courseUrl}>{courseName}</LinkWrapper>
            </span>
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div
      className={classNames(
        'flex flex-col gap-0 border-2 rounded-md overflow-hidden justify-between',
        (redirect || shouldClamp) && 'hover:shadow-xl',
        backgroundColor ? 'border-[--backgroundColor]' : 'border-accent-4-50'
      )}
      style={cssVars}
    >
      <div
        className={classNames(
          'w-full p-4',
          backgroundColor ? 'bg-[--backgroundColor]' : 'bg-white'
        )}
      >
        <Quote color={entry?.fields?.accentColor || '#CCCCCC'} />

        {content &&
          isContentfulType<TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>>(content, 'video') &&
          content.fields.variant === MediaType.VIDEO && (
            <VideoSlide
              block={content as TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>}
              className={
                'relative mt-6 w-full h-[12rem] overflow-hidden rounded-md duration-300 hover:scale-105 transition'
              }
            />
          )}

        {content &&
          isContentfulType<TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
            content,
            'imageWithFocalPoint'
          ) && (
            <ImageSlide
              block={content}
              className={
                'relative mt-6 w-full h-[12rem] overflow-hidden rounded-md duration-300 hover:scale-105 transition'
              }
              shape={'None'}
            />
          )}

        <ReactMarkdown
          components={{
            p: ({ ...props }) => {
              return (
                <p
                  className={classNames('text-secondary-ui-600 mt-6', clampStyle)}
                  ref={clampTextRef}
                >
                  {props.children}
                </p>
              )
            },
            a: ({ ...props }) => {
              return (
                <>
                  <br />
                  <Link href={props.href}>
                    <span className="text-accent-4-700 underline underline-offset-4">
                      {props.children}
                    </span>
                    <span className="text-accent-4-700 no-underline">{' >'}</span>
                  </Link>
                </>
              )
            },
          }}
        >
          {copy}
        </ReactMarkdown>

        {shouldClamp && (
          <span
            className={classNames(
              'cursor-pointer text-accent-4-700 flex flex-row gap-2 items-center',
              isExpanded && 'py-4'
            )}
            onClick={() => toggleExpand()}
          >
            {buttonLabel}
            <Icon
              className={'h-5 w-5 mb-1'}
              name={isExpanded ? 'ChevronUpIcon' : 'ChevronDownIcon'}
            />
          </span>
        )}
      </div>
      <div>
        {person?.fields && !redirect && renderFooter}
        {person?.fields && redirect && (
          <LinkWrapper href={courseUrl ? courseUrl : `/people/${person.fields.slug}` || ''}>
            {renderFooter}
          </LinkWrapper>
        )}
      </div>
    </div>
  )
}

export default QuoteCard
