// This is the country that is used for server side rendering.
export const SSR_COUNTRY = 'NZ'
export const SSR_LOCALE = 'en-NZ'

export const countriesToSeeNZ = [
  'CK', // Cook Islands
  'NU', // Niue
  'NZ', // New Zealand
]

export const regionsToSeeNZ = ['QLD']
