import React from 'react'
import { TypeNavigationMenu, TypeResourceSet } from '../../types/contentful'
import { isContentfulType } from '../../lib/contentful'
import FooterGroup from './FooterGroup'
import FooterSocialIcons from './FooterSocialIcons'
import Link from 'next/link'
import isDarkColor from '../../helpers/isDarkColor'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import SVG from 'react-inlinesvg'
import classNames from 'classnames'

type FooterDefaultProps = {
  menu?: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
  colour?: string
  bgColourClass?: string
  customClass?: string
}

const FooterDefault = ({ menu, colour, bgColourClass, customClass }: FooterDefaultProps) => {
  const { footerInfo, lightLargeLogo, darkLargeLogo, footerCopyright } = menu?.fields ?? {}
  const resources = [...(menu?.fields.resources || [])]
  const social =
    resources[resources.length - 1]?.fields?.title === 'Social' ? resources.pop() : undefined
  let containerClasses = customClass ? customClass : 'p-10'
  if (!bgColourClass) {
    if (!colour) containerClasses += ' bg-primary-brand '
    if (colour && isDarkColor(colour)) containerClasses += ' dark'
  } else {
    containerClasses += ` ${bgColourClass}`
  }

  const logoImage =
    colour && isDarkColor(colour)
      ? darkLargeLogo?.fields?.file?.url
      : lightLargeLogo?.fields?.file?.url

  const cssVars = {
    '--svg-color': isDarkColor(colour) ? 'white' : 'black',
  } as React.CSSProperties

  const footerInfoClass = classNames('mt-4 [&>p]:m-1 [&>p>a]:text-black	 not-italic', {
    '[&>p>a]:text-black': !isDarkColor(colour),
    '[&>p>a]:text-white': isDarkColor(colour),
  })

  return (
    <footer className={containerClasses} style={{ background: colour }}>
      <div className="mx-auto container px-6 dark:text-white">
        <div className="flex flex-col md:flex-row md:gap-6 border-b-[1px] border-primary-ui-900 dark:border-white">
          <div className="block min-w-[33%] w-full md:w-auto md:flex lg:pb-0 lg:mb-0 md:mb-4 md:py-6">
            <div
              className={
                'flex flex-col gap-6 items-center md:items-start text-primary-ui-900 dark:text-white font-light text-center md:text-left'
              }
            >
              <Link href={'/'}>
                {lightLargeLogo?.fields?.file.contentType === 'image/svg+xml' ? (
                  <div className={`w-auto max-h-10 md:h-10`}>
                    <SVG
                      className="max-h-[2.5rem] md:max-h-[3rem] [&>*]:fill-[--svg-color]"
                      src={`https:${lightLargeLogo?.fields?.file.url}`}
                      width="100%"
                      height="100%"
                      style={cssVars}
                    />
                  </div>
                ) : (
                  <>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={logoImage}
                      alt={lightLargeLogo?.fields?.title}
                      className="max-h-10 w-auto"
                    />
                  </>
                )}
              </Link>
              {!!footerInfo && (
                <address className={footerInfoClass}>
                  {renderDocumentToReactComponents(footerInfo)}
                </address>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-1 md:flex-row md:gap-x-12 w-full py-6 md:flex-wrap justify-end max-full lg:mr-5">
            {resources?.map(menuItem => {
              if (
                isContentfulType<TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                  menuItem,
                  'navigationMenu'
                )
              ) {
                return <FooterGroup key={menuItem.sys.id} menu={menuItem} />
              }
            })}
          </div>
        </div>

        <div className="flex py-6 flex-col md:flex-row">
          <div
            className={
              'flex item-center justify-center md:items-start md:justify-start w-full md:w-1/2 text-center md:text-left py-2'
            }
          >
            {`© ${new Date().getFullYear()} ${footerCopyright}`}
          </div>
          <div
            className={
              'flex w-full md:w-1/2 items-center justify-center md:items-end md:justify-end text-center md:text-right py-2'
            }
          >
            {isContentfulType<TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
              social,
              'navigationMenu'
            ) && <FooterSocialIcons menu={social} />}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterDefault
