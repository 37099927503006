import { gql } from '@apollo/client'

export const GET_PUBLIC_EVENTS = gql`
  query getSocialEvents {
    events: getSocialEvents {
      id
      slug
      name
      priceNZ
      priceAU
      priceUS
      instances {
        id
        startsAt
        registrationEndDate
        registrationEndText
      }
      location
      thumbnailUrl
    }
  }
`

export const GET_EVENT = gql`
  query GetSocialEvent($slug: String!) {
    event: getSocialEvent(slug: $slug) {
      id
      name
      slug
      location
      thumbnailUrl
      instances {
        id
        startsAt
        ceremonyStartsAt
        registrationEndDate
        registrationEndText
      }
      status
      limit
      isFree
      priceNZ
      priceAU
      priceUS
      publishedAt
    }
  }
`
