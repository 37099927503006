import { useButton } from 'react-aria'
import { ReactNode, useRef } from 'react'
import Link from 'next/link'

type ButtonBaseProps = {
  children?: ReactNode
  className?: string
  href?: string
  id?: string
  [key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const ButtonBase = (props: ButtonBaseProps) => {
  const ref = useRef()

  const { buttonProps } = useButton(
    {
      ...props,
      elementType: props.href ? 'a' : 'button',
    },
    ref
  )

  const allProps = {
    ...buttonProps,
    ...props,
  }
  delete allProps['isVideoVariantTrackable']

  return (
    <>
      {!props.href ? (
        <button id={props.id} className={props.className} ref={ref} {...allProps}>
          {props.children}
        </button>
      ) : (
        <Link
          href={props.href}
          id={props.id}
          passHref
          className={props.className}
          ref={ref}
          {...allProps}
        >
          {props.children}
        </Link>
      )}
    </>
  )
}

export default ButtonBase
