import classNames from 'classnames'
import Icons from '../Icons'
import React from 'react'

export type IconName = keyof typeof Icons
type IconProps = {
  name: IconName | string
  baseClass?: string
  className?: string
  ariaLabel?: string
  onClick?(): void
  textColor?: string
  hoverColor?: string
  title?: string
}

export const Icon = ({
  name,
  className,
  baseClass,
  ariaLabel,
  textColor,
  hoverColor,
  ...rest
}: IconProps) => {
  // Prevent undefined (or in-development icons) crashing build
  const accesibilityLabel = ariaLabel || `${name} Icon`
  const Icon = Icons?.[name]

  const cssVars = {
    '--font-color': `${textColor}`,
    '--hover-color': `${hoverColor}`,
  } as React.CSSProperties

  if (!Icon) {
    return null
  }
  return (
    <span
      className={classNames(
        'block',
        className,
        baseClass,
        textColor && 'text-[--font-color]',
        hoverColor && 'hover:text-[--hover-color]'
      )}
      aria-label={accesibilityLabel}
      style={cssVars}
      {...rest}
    >
      <Icon className={className} />
    </span>
  )
}
