import React, { useMemo } from 'react'
import { TypeCardHighlightContentBlock } from '../../types/contentful'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import isDarkColor from '../../helpers/isDarkColor'
import TitleBlock from '../TitleBlock/TitleBlock'
import Container from '../Container/Container'
import classNames from 'classnames'

type CardHighlightContentBlockProps = {
  entry: TypeCardHighlightContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const CardHighlightContentBlockDefault = ({ entry }: CardHighlightContentBlockProps) => {
  const {
    preTitle,
    title,
    description,
    backgroundColour,
    leftColumn,
    rightColumn,
    alignment,
    headingType,
    leftColumnTextAlignment,
    rightColumnTextAlignment,
  } = entry.fields
  const containerClasses = classNames(
    'full-width py-3 md:py-6 lg:py-12',
    backgroundColour && isDarkColor(backgroundColour) && 'text-white dark'
  )
  const showImageAbove = useMemo(() => {
    const isImageOnRight = rightColumn?.content?.some(block => block?.data?.target?.fields?.image)
    return isImageOnRight && (!title || title === ' ') && !preTitle
  }, [])

  const getTextAlignment = (alignment: string) => {
    switch (alignment) {
      case 'Right':
        return 'text-right'

      case 'Center':
        return 'text-center'

      default:
      case 'Left':
        return 'text-left'
    }
  }

  return (
    <Container
      className={containerClasses}
      style={{ backgroundColor: entry.fields.backgroundColour }}
    >
      {(alignment === 'Top Left' || alignment === 'Top Right' || alignment === 'Top Center') && (
        <div
          className={classNames(
            'container mx-auto',
            alignment === 'Top Right' && 'md:ml-[50%] md:w-1/2'
          )}
        >
          <TitleBlock
            title={title}
            preTitle={preTitle}
            headingType="h2"
            align={`${
              alignment === 'Top Center'
                ? 'center'
                : alignment === 'Top Left'
                ? 'left'
                : alignment === 'Top Right'
                ? 'left'
                : ''
            }`}
            size={`5xl`}
            description={description}
            descriptionWidth="Wide"
          />
        </div>
      )}
      <div
        className={`container md:px-0 xl:px-6 mx-auto relative grid grid-cols-1 md:grid-cols-2 dark:text-primary-ui-white gap-x-14 gap-y-7 ${
          alignment === 'Top Left' || alignment === 'Top Right' ? 'items-top' : 'items-center'
        }`}
      >
        <div
          className={classNames(
            showImageAbove ? 'order-last md:order-1' : 'w-full',
            getTextAlignment(leftColumnTextAlignment)
          )}
        >
          {alignment === 'Center Left' && (
            <TitleBlock
              title={title}
              preTitle={preTitle}
              headingType={headingType || 'h2'}
              align="center"
              size={`5xl`}
            />
          )}
          {renderDocumentToReactComponents(leftColumn)}
        </div>
        <div
          className={classNames(
            showImageAbove && 'order-1 md:order-2',
            getTextAlignment(rightColumnTextAlignment)
          )}
        >
          {alignment === 'Center Right' && (
            <TitleBlock
              title={title}
              preTitle={preTitle}
              headingType={headingType || 'h2'}
              align="left"
              size={`5xl`}
            />
          )}
          {renderDocumentToReactComponents(rightColumn)}
        </div>
      </div>
    </Container>
  )
}

export default CardHighlightContentBlockDefault
