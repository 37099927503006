import {
  ContentfulClientApi,
  createClient,
  Entry,
  EntryCollection,
  EntrySkeletonType,
} from 'contentful'
import {
  TypeArticle,
  TypeArticleSkeleton,
  TypeBrowsePage,
  TypeBrowsePageSkeleton,
  TypeCourse,
  TypeCourseSkeleton,
  TypeEvent,
  TypeEventSkeleton,
  TypePage,
  TypePageFields,
  TypePageSkeleton,
  TypePerson,
  TypePersonSkeleton,
  TypeProvider,
  TypeProviderSkeleton,
  TypeResourceSet,
  TypeResourceSetSkeleton,
} from '../types/contentful'
import { ArticleType } from '../types/types'
import { Course, SocialEvent } from '../types/graphql'
import { GET_COURSE, GET_PUBLIC_COURSES } from '../gql/courses'
import { GET_EVENT, GET_PUBLIC_EVENTS } from '../gql/events'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { removeCircularReferences } from '../helpers/removeCircularReferences'
import { SSR_LOCALE } from '../config/locale'
import { isAcademyExAu } from './detectEnvironment'

// When pulling single items (pages, courses, etc)
// max depth to query data.
// note: This should be modified carefully and with
// extensive testing of production content
// We figured that 5 renders the largest page, but +1 for safety
const MAX_CONTENTFUL_DEPTH = 6

// Protect from adding wrong resource to menu resource group
export function isContentfulType<T extends Entry>(item: Entry, name: string): item is T {
  // Omit orphaned records from contentful
  if (!item?.fields) {
    return false
  }
  return item?.sys?.contentType?.sys?.id === name
}

export function createContentfulClient() {
  return createClient({
    space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
    environment: process.env.NEXT_PUBLIC_CONTENTFUL_BRANCH,
    // resolveLinks: true, // links are now resolved by default
  }).withoutUnresolvableLinks // remove unpublished / broken links. Very important.
}

// Get first item in the results list
function extractFirstResult<T, U extends EntrySkeletonType>(results: EntryCollection<U>): T | null {
  if (results.items.length === 0) {
    return null
  }
  return results.items?.[0] as T
}

/**
 * Get a generic page by slug
 *
 * @param client
 * @param slug
 */
export async function getPageBySlug(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  slug: string,
  isSubdomain?: boolean
): Promise<TypePage<'WITHOUT_UNRESOLVABLE_LINKS', string> | null> {
  const results = await client.withAllLocales.getEntries<TypePageSkeleton>({
    content_type: 'page',
    'fields.slug': slug,
    'fields.isSubdomain': isSubdomain,
    include: MAX_CONTENTFUL_DEPTH,
  })

  return removeCircularReferences(
    extractFirstResult<
      TypePage<'WITHOUT_UNRESOLVABLE_LINKS', string>,
      TypePageFields & EntrySkeletonType
    >(results)
  )
}

/**
 * Query all pages. Note: Set 'include' to a shallow number to get only paths
 * @param client
 * @param include
 */
export async function getPages(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  include: 0 | 2 | 3 | 4 | 1 | 5 | 6 | 7 | 8 | 9 | 10 = MAX_CONTENTFUL_DEPTH
): Promise<Array<TypePage<'WITHOUT_UNRESOLVABLE_LINKS', string>>> {
  const isSubdomain = isAcademyExAu()
  const results = await client.withAllLocales.getEntries<TypePageSkeleton>({
    content_type: 'page',
    'fields.isSubdomain': isSubdomain,
    include,
  })
  return removeCircularReferences(results.items)
}

/**
 * Get the home page
 *
 * @param client
 */
export async function getHomepage(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>
): Promise<TypePage<'WITHOUT_UNRESOLVABLE_LINKS', string> | null> {
  return await getPageBySlug(client, '/')
}

/**
 * Get main menu
 * @param client
 */
export async function getMenu(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  isSubdomain?: boolean
): Promise<TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string> | null> {
  const slug = isSubdomain ? 'Subdomain Header' : 'Header'
  const results = await client.getEntries<TypeResourceSetSkeleton>({
    content_type: 'resourceSet',
    'fields.type': slug,
    include: MAX_CONTENTFUL_DEPTH,
  })
  return removeCircularReferences(
    extractFirstResult<
      TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>,
      TypeResourceSetSkeleton
    >(results)
  )
}

/**
 * Get courses, with optional category filter
 * @param client
 * @param categories
 * @param featured Set to true to only get featured
 */
export async function getCourses(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  categories?: Array<string>,
  featured?: boolean
): Promise<Array<TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>>> {
  return await getBrowsableItems<TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
    client,
    'course',
    categories,
    featured
  )
}

/**
 * Get events
 * @param client
 * @param categories
 * @param featured Set to true to only get featured
 */
export async function getEvents(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  categories?: Array<string>,
  featured?: boolean
): Promise<Array<TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>>> {
  return await getBrowsableItems<TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
    client,
    'event',
    categories,
    featured
  )
}

/**
 * Get people
 * @param client
 * @param categories
 * @param featured Set to true to only get featured
 */
export async function getPeople(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  categories?: Array<string>,
  featured?: boolean
): Promise<Array<TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>>> {
  return await getBrowsableItems<TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
    client,
    'person',
    categories,
    featured
  )
}

/**
 * Get articles
 * @param client
 * @param categories
 * @param featured Set to true to only get featured
 */
export async function getArticles(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  categories?: Array<string>,
  featured?: boolean,
  type?: TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']['type']
): Promise<Array<TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>>> {
  return await getBrowsableItems<TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
    client,
    'article',
    categories,
    featured,
    null,
    type
  )
}

function getCourseCardFields(): Array<
  keyof TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']
> {
  // Excluded fields:
  // bookACallBackUrl, buttons, sections, marketoEnquiryFormId
  // Refer to Card.renderCourseCard for list of required fields
  return [
    'title',
    'featured',
    'slug',
    'thumbnail',
    'shape',
    'size',
    'hero',
    'tags',
    'displayOrder',
    'description',
    'timeframeIcon',
    'listItems',
    'durationDescriptionIcon',
    'eoiOnly',
  ]
}

function getArticleCardFields(): Array<
  keyof TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']
> {
  // Excluded fields
  // colour, content, footerColour
  // Refer to Card.renderArticleCard for list of required fields
  return [
    'title',
    'featured',
    'slug',
    'category',
    'author',
    'readTime',
    'hero',
    'shape',
    'size',
    'tags',
    'postedDate',
    'metaDescription',
    'type',
  ]
}

function getEventCardFields(): Array<
  keyof TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']
> {
  // Excluded fields
  // people, sections, footerColour, marketoEnquiryFormId
  // Refer to Card.renderEventCard for list of required fields
  return [
    'title',
    'featured',
    'slug',
    'thumbnail',
    'shape',
    'size',
    'hero',
    'tags',
    'description',
    'externalSignupLink',
    'listItems',
  ]
}

function getPersonCardFields(): Array<
  keyof TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']
> {
  // Excluded fields
  // provider, content, facebook, instagram, twitter, linkedin
  // Refer to Card.renderPersonCard for list of required fields
  return [
    'firstName',
    'lastName',
    'featured',
    'title',
    'slug',
    'tags',
    'hero',
    'shape',
    'size',
    'displayOrder',
    'description',
  ]
}

function getCardFields(type: 'course' | 'article' | 'event' | 'person') {
  switch (type) {
    case 'course':
      return getCourseCardFields()
    case 'article':
      return getArticleCardFields()
    case 'event':
      return getEventCardFields()
    case 'person':
      return getPersonCardFields()
    default:
      return undefined
  }
}

/**
 * Get a list of cards of a given type for the browse section
 *
 * @param client
 * @param type
 * @param categories
 * @param featured
 * @param browseIncluded
 */
export async function getBrowsableItems<
  T extends
    | TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
>(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  type: 'course' | 'article' | 'event' | 'person',
  categories?: Array<string>,
  featured?: boolean,
  browseIncluded?: boolean,
  articleType?: 'Article' | 'Case-study' | 'Blog'
): Promise<Array<T>> {
  // Filter selected fields so that it's not huge and crashes
  const fields = getCardFields(type)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const select: any = fields && 'sys,' + fields.map(field => `fields.${field}`).join(',')

  const filters = {}
  if (featured) {
    filters['fields.featured'] = true
  }
  if (browseIncluded) {
    filters['fields.browseIncluded'] = true
  }

  if (type === 'article') {
    filters['fields.isSubdomain'] = isAcademyExAu()
    if (articleType) {
      filters['fields.type'] = articleType
    }
  }

  if (type === 'person') {
    filters['fields.isSubdomain'] = isAcademyExAu()
  }

  const results = await client.withAllLocales.getEntries<
    TypeCourseSkeleton | TypeEventSkeleton | TypeArticleSkeleton | TypePersonSkeleton
  >({
    content_type: type,
    include: MAX_CONTENTFUL_DEPTH,
    limit: 1000,
    select, // for some reason this works as a comma separated string (contentful 9), but doesn't work when it is the expected type of an array (contentful 10)
    ...filters,
  })

  // filter by category
  const filtered =
    categories?.length > 0
      ? (
          results as EntryCollection<
            TypeCourseSkeleton,
            'WITHOUT_UNRESOLVABLE_LINKS' | 'WITH_ALL_LOCALES',
            string
          >
        ).items.filter(item => {
          return (
            item.fields.tags?.[SSR_LOCALE].filter(tag =>
              categories.includes(tag.fields.slug[SSR_LOCALE])
            ).length > 0
          )
        })
      : results.items

  return removeCircularReferences(filtered)
}

/**
 * Get course
 * @param client
 * @param slug
 */
export async function getCourse(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  slug: string | string[]
): Promise<TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string> | null> {
  const results = await client.withAllLocales.getEntries<TypeCourseSkeleton>({
    content_type: 'course',
    'fields.slug': Array.isArray(slug) ? slug[0] : slug,
    include: MAX_CONTENTFUL_DEPTH,
  })
  return removeCircularReferences(
    extractFirstResult<TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>, TypeCourseSkeleton>(
      results
    )
  )
}

// List of pages in the order they should appear in the menu
export const BrowsePageOrder: Array<
  TypeBrowsePage<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']['type']
> = ['home', 'courses', 'events', 'stories', 'people', 'blogs', 'case-studies']

/**
 * Get pages for the /browse section
 * @param client
 */
export async function getBrowsePages(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>
): Promise<Array<TypeBrowsePage<'WITHOUT_UNRESOLVABLE_LINKS', string>>> {
  const results = await client.withAllLocales.getEntries<TypeBrowsePageSkeleton>({
    content_type: 'browsePage',
    'fields.isSubdomain': isAcademyExAu(),
    include: MAX_CONTENTFUL_DEPTH,
  })

  const pages = removeCircularReferences(results)

  // Do some magic to sort based on the order we like
  return pages.items.sort(
    (left, right) => left.fields.displayOrder[SSR_LOCALE] - right.fields.displayOrder[SSR_LOCALE]
  )
}

export async function getBrowseCards(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  page: TypeBrowsePage<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']['type'],
  categories?: Array<string | null>
): Promise<
  Array<
    | TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>
  >
> {
  const promises: Array<
    Promise<
      Array<
        | TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>
        | TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>
        | TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
        | TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>
      >
    >
  > = []
  const featured = page === 'home'

  // Get courses if on home / course pages
  if (page === 'home' || page === 'courses') {
    promises.push(
      getBrowsableItems<TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
        client,
        'course',
        categories,
        featured,
        true
      )
    )
  }

  // Events
  if (page === 'home' || page === 'events') {
    promises.push(
      getBrowsableItems<TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
        client,
        'event',
        categories,
        featured,
        true
      )
    )
  }

  // People
  if (page === 'home' || page === 'people') {
    promises.push(
      getBrowsableItems<TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
        client,
        'person',
        categories,
        featured,
        true
      )
    )
  }

  // Articles
  if (page === 'home' || page === 'stories' || page === 'blogs' || page === 'case-studies') {
    promises.push(
      getBrowsableItems<TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
        client,
        'article',
        categories,
        featured,
        true,
        ArticleType.ARTICLE
      )
    )
  }

  // Blog
  if (page === 'blogs') {
    promises.push(
      getBrowsableItems<TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
        client,
        'article',
        categories,
        featured,
        true,
        ArticleType.BLOG
      )
    )
  }

  if (page === 'case-studies') {
    promises.push(
      getBrowsableItems<TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
        client,
        'article',
        categories,
        featured,
        true,
        ArticleType.CASE_STUDY
      )
    )
  }

  // Merge all items, sorting by modified date
  const resolved = await Promise.all(promises)
  return resolved.flat(1)
}

export async function getProviders(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>
): Promise<Array<TypeProvider<'WITHOUT_UNRESOLVABLE_LINKS', string> | null>> {
  const results = await client.withAllLocales.getEntries<TypeProviderSkeleton>({
    content_type: 'provider',
  })

  return removeCircularReferences(results.items)
}

/**
 * Get event
 * @param slug
 * @param client
 */
export async function getEvent(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  slug: string | string[]
): Promise<TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string> | null> {
  const results = await client.withAllLocales.getEntries<TypeEventSkeleton>({
    content_type: 'event',
    'fields.slug': Array.isArray(slug) ? slug[0] : slug,
    include: MAX_CONTENTFUL_DEPTH,
  })
  return removeCircularReferences(
    extractFirstResult<TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>, TypeEventSkeleton>(results)
  )
}

/**
 * Get article
 * @param client
 * @param slug
 */
export async function getArticle(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  slug: string | string[],
  isSubdomain?: boolean,
  type?: TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']['type']
): Promise<TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string> | null> {
  const filters = {}
  if (isSubdomain) {
    filters['fields.isSubdomain'] = true
  }
  if (type) {
    filters['fields.type'] = type
  }

  const results = await client.getEntries<TypeArticleSkeleton>({
    content_type: 'article',
    'fields.slug': Array.isArray(slug) ? slug[0] : slug,
    ...filters,
    include: MAX_CONTENTFUL_DEPTH,
  })
  return removeCircularReferences(
    extractFirstResult<TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>, TypeArticleSkeleton>(
      results
    )
  )
}

/**
 * Get Person
 * @param client
 * @param slug
 */
export async function getPerson(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  slug: string | string[]
): Promise<TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string> | null> {
  const results = await client.getEntries<TypePersonSkeleton>({
    content_type: 'person',
    'fields.slug': Array.isArray(slug) ? slug[0] : slug,
    include: MAX_CONTENTFUL_DEPTH,
    'fields.isSubdomain': isAcademyExAu(),
  })
  return removeCircularReferences(
    extractFirstResult<TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>, TypePersonSkeleton>(
      results
    )
  )
}

/**
 * Get the styleguide page
 *
 * @param client
 */
export async function getStyleGuidePage(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>
): Promise<TypePage<'WITHOUT_UNRESOLVABLE_LINKS', string> | null> {
  return await getPageBySlug(client, 'style-guide')
}

/**
 * Get the community page
 *
 * @param client
 */
export async function getConnectPage(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>
): Promise<TypePage<'WITHOUT_UNRESOLVABLE_LINKS', string> | null> {
  return await getPageBySlug(client, 'connect')
}

export async function getFooter(
  client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>,
  isSubdomain?: boolean
): Promise<TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string> | null> {
  const type = isSubdomain ? 'Subdomain Footer' : 'Footer'
  const results = await client.getEntries<TypeResourceSetSkeleton>({
    content_type: 'resourceSet',
    'fields.type': type,
    include: MAX_CONTENTFUL_DEPTH,
  })
  return extractFirstResult<
    TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>,
    TypeResourceSetSkeleton
  >(results)
}

/**
 * Query all data required by contentful content blocks
 *
 * @param apollo The apollo client
 * @return The list of resources used
 */
export async function getGraphqlResources(
  apollo: ApolloClient<NormalizedCacheObject>
): Promise<{ courses: Array<Course>; events: Array<SocialEvent> }> {
  const [courses, events] = isAcademyExAu()
    ? [[], []]
    : await Promise.all([getGraphQLCourses(apollo), getGraphQLEvents(apollo)])
  return { courses, events }
}

/**
 * Query courses on the lms
 *
 * @param apollo The apollo client
 * @return Array<Course>
 */
export async function getGraphQLCourses(
  apollo: ApolloClient<NormalizedCacheObject>
): Promise<Array<Course>> {
  const courses = await apollo.query<{ courses: Array<Course> }>({
    query: GET_PUBLIC_COURSES,
  })

  return courses.data.courses
}

/**
 * Query events on the lms
 *
 * @param apollo The apollo client
 * @return Array<SocialEvent>
 */
export async function getGraphQLEvents(
  apollo: ApolloClient<NormalizedCacheObject>
): Promise<Array<SocialEvent>> {
  const events = await apollo.query<{ events: Array<SocialEvent> }>({
    query: GET_PUBLIC_EVENTS,
  })

  return events.data.events
}

/**
 * Query course on the lms
 *
 * @param apollo The apollo client
 * @param slug Course slug
 * @return Course
 */
export async function getGraphqlCourse(
  apollo: ApolloClient<NormalizedCacheObject>,
  slug: string
): Promise<Course> {
  // When trying to query for a course with slug, if not found the server returns an error

  if (!slug || slug === 'undefined') {
    return null
  }

  try {
    const {
      data: { course },
    } = await apollo.query<{ course: Course }>({
      query: GET_COURSE,
      variables: { slug: slug },
    })

    return course
  } catch (e) {
    return null
  }
}

/**
 * Query event on the lms
 *
 * @param apollo The apollo client
 * @param slug Event slug
 * @return SocialEvent
 */
export async function getGraphqlEvent(
  apollo: ApolloClient<NormalizedCacheObject>,
  slug: string
): Promise<SocialEvent> {
  // When trying to query for a event with slug, if not found the server returns an error

  if (!slug || slug === 'undefined') {
    return null
  }

  try {
    const {
      data: { event },
    } = await apollo.query<{ event: SocialEvent }>({
      query: GET_EVENT,
      variables: { slug: slug },
    })

    return event
  } catch (e) {
    return null
  }
}
