import { TypeHeroBlock, TypeImageWithFocalPoint, TypeVideo } from '../../types/contentful'
import React from 'react'
import Container from '../Container/Container'
import Image from 'next/image'
import getFocalRatio from '../../helpers/getFocalRatio'
import InfiniteVerticalScroll from './DynamicTextCarrousel/InfiniteVerticalScroll'
import { isContentfulType } from '../../lib/contentful'
import VideoSlide from '../HeroSlider/VideoSlide'
import classNames from 'classnames'
import { TextComponent } from './HeroBlockV4'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'

const words = [
  { word: 'factor', id: 'factor1' },
  { word: 'cellence', id: 'excellence2' },
  { word: 'perience', id: 'experience3' },
  { word: 'traordinary', id: 'extraordinary4' },
  { word: 'hilaration', id: 'exhilaration5' },
  { word: 'ponential', id: 'exponential6' },
  { word: 'ceptional', id: 'exceptional7' },
  { word: 'ploration', id: 'exploration8' },
  { word: 'citement', id: 'excitement10' },
  { word: 'emplary', id: 'exemplary11' },
  { word: 'factor', id: 'factor12' },
  { word: 'cellence', id: 'excellence13' },
  { word: 'perience', id: 'experience14' },
  { word: 'traordinary', id: 'extraordinary15' },
  { word: 'hilaration', id: 'exhilaration16' },
  { word: 'ponential', id: 'exponential17' },
  { word: 'ceptional', id: 'exceptional18' },
  { word: 'ploration', id: 'exploration19' },
]

type NewHeroBlockProps = {
  entry: TypeHeroBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const HeroBlockDynamic = ({ entry }: NewHeroBlockProps) => {
  const {
    backgroundColor,
    content,
    accentColor = '#E46FDE',
    noTopMargin,
    mobileImagePlacement = 'Top',
    enableExFactorAnimation,
    fontColor,
    heroBanner,
  } = entry.fields

  const focalRatio = isContentfulType<
    TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
  >(content?.[0], 'imageWithFocalPoint')
    ? getFocalRatio(content[0]?.fields)
    : undefined

  return (
    <>
      <Container
        className={classNames('overflow-hidden full-width md:h-[29.3rem] flex flex-col', {
          'mt-navbar md:mt-0': noTopMargin,
          'mt-navbar': !noTopMargin,
        })}
        id={entry?.sys?.id}
      >
        {isContentfulType<TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
          content?.[0],
          'imageWithFocalPoint'
        ) &&
          mobileImagePlacement === 'Top' && (
            <div className="relative md:hidden h-[25rem] md:h-[29.3rem] w-full top-0 right-0 -z-20">
              <Image
                src={`https:${content[0].fields?.image?.fields?.file?.url}`}
                priority
                objectPosition={`${focalRatio.x}% ${focalRatio.y}%`}
                alt="hero image"
                layout="fill"
                objectFit="cover"
                className="relative h-[29.3rem]"
              />
            </div>
          )}

        {isContentfulType<TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
          content?.[0],
          'video'
        ) && (
          <div
            className={classNames(
              'relative md:hidden w-full top-0 right-0',
              content[0]?.fields?.image && 'h-[25rem] md:h-[29.3rem] '
            )}
          >
            <VideoSlide block={content[0]} className="w-full h-full" priority />
          </div>
        )}

        <div
          className={
            'hidden md:block bolt-right-2 overflow-hidden h-[29.3rem] md:w-[57.5%] md:absolute top-0 left-12 xl:left-14 2xl:left-16 bottom-0 right-0 z-20'
          }
          style={{ background: `${accentColor}` }}
        />

        <div
          className={classNames(
            'md:bolt-right overflow-hidden md:h-[29.3rem] md:w-[57.5%] md:absolute top-0 left-0 bottom-0 right-0 z-30',
            enableExFactorAnimation && 'h-[21.125rem]',
            !enableExFactorAnimation && 'h-full'
          )}
          style={{ background: `${backgroundColor}` }}
        >
          {enableExFactorAnimation ? (
            <InfiniteVerticalScroll words={words} color={fontColor} />
          ) : (
            <TextComponent
              entry={entry}
              containerClass="max-w-full py-12 md:py-3 lg:py-0 md:max-w-[450px] text-balance md:!pr-10 lg:!pr-14 lg:max-w-[710px] flex-shrink-0 gap-4 md:gap-1 lg:gap-6"
            />
          )}
        </div>

        {isContentfulType<TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
          content?.[0],
          'imageWithFocalPoint'
        ) && (
          <div
            className={classNames(
              mobileImagePlacement === 'Top' &&
                'hidden md:block md:absolute w-1/2 h-[29.3rem] top-0 right-0 -z-20',
              mobileImagePlacement === 'Bottom' &&
                'relative md:block h-[20rem] md:absolute md:w-1/2 md:h-[29.2rem] top-0 bottom-0 right-0 -z-20'
            )}
          >
            <Image
              src={`https:${content[0].fields?.image?.fields?.file?.url}`}
              priority
              objectPosition={`${focalRatio.x}% ${focalRatio.y}%`}
              alt="hero image"
              layout="fill"
              objectFit="cover"
              className="h-[29.3rem]"
            />
          </div>
        )}

        {isContentfulType<TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
          content?.[0],
          'video'
        ) && (
          <div
            className={classNames(
              'hidden md:block md:absolute w-1/2 top-0 right-0',
              content[0]?.fields?.image ? 'h-[29.3rem]' : 'bg-black h-[29.2rem]'
            )}
          >
            <VideoSlide
              block={content[0]}
              priority
              className={classNames(
                'w-full h-full',
                !content[0]?.fields?.image &&
                  'scale-75 relative left-[10%] max-h-[29.3rem] justify-center'
              )}
              innerClassName="max-w-[1000px]"
              playerClassName="!static !aspect-video"
            />
          </div>
        )}
      </Container>

      {heroBanner && (
        <div className="full-width bg-black flex justify-center items-center p-4 text-balance text-center">
          <div className="text-white text-center [&>p]:m-0 [&>p>a]:text-[#DFE147]">
            {renderDocumentToReactComponents(heroBanner)}
          </div>
        </div>
      )}
    </>
  )
}

export default HeroBlockDynamic
