import React, { useState } from 'react'
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'
import { Loading } from '../Loading/Loading'
import * as Sentry from '@sentry/nextjs'
import { useUser } from '@auth0/nextjs-auth0/client'
import { getCookie, setCookie } from 'cookies-next'
import axios from 'axios'
import { useRouter } from 'next/router'
import { hsFormSubmit } from '../../modules/ecommerce/product-data-layer'

interface HubspotEmbeddedFormProps {
  portalId: string
  formId: string
  title: string
  displayTitle?: boolean
  isModal?: boolean
  formInstanceId?: string
  verifyEmail?: boolean
}

export const HubspotEmbeddedForm = ({
  portalId,
  formId,
  title,
  displayTitle,
  isModal,
  formInstanceId,
  verifyEmail,
}: HubspotEmbeddedFormProps) => {
  const [loading, setLoading] = useState(true)
  // todo: WIP email
  const [, setVerificationEmailSent] = useState(false)
  const [, setVerificationEmailError] = useState('false')

  const router = useRouter()
  const isEcommercePage =
    router.pathname.includes('/courses/') || router.pathname.includes('/events/')

  const { user } = useUser()

  const getName = async () => {
    try {
      const apiUrl = `/api/getHSForm?formid=${formId}`
      const apiResponse = await axios.get(apiUrl)
      return apiResponse.data.name
    } catch (err) {
      Sentry.captureException(err)
      return ''
    }
  }

  const handleSubmit = async (email, emailTitle, emailBody, redirectUrl, buttonLabel) => {
    try {
      const response = await fetch('/api/verify-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          email_title: emailTitle,
          email_body: emailBody,
          redirect_url: redirectUrl,
          button_label: buttonLabel,
        }),
      })

      if (response.ok) {
        setVerificationEmailSent(true)
      } else {
        const errorText = await response.text()
        setVerificationEmailError(`Failed to send verification email: ${errorText}`)
      }
    } catch (error) {
      setVerificationEmailError('Failed to send verification email')
    }
  }

  const { loaded, error } = useHubspotForm({
    portalId: portalId,
    formId: formId,
    target: `#form-${formInstanceId ? formInstanceId : formId}`,
    onBeforeFormInit: () => setLoading(false),
    formInstanceId: formInstanceId,
    onFormReady: form => {
      if (verifyEmail) {
        const emailInput = form.querySelector('input[name="email"]')
        let email
        try {
          email = user?.email || getCookie('hubspot-email') || ''
        } catch (e) {
          Sentry.captureException(e)
          email = user?.email || ''
        }

        if (emailInput && email) {
          emailInput.value = email
          emailInput.dispatchEvent(new Event('input', { bubbles: true }))
        }
      }
    },
    onFormSubmit: async form => {
      const formName = await getName()
      const emailInput = form.querySelector('input[name="email"]')

      hsFormSubmit(formName, formId, !isEcommercePage)

      if (verifyEmail) {
        const emailCookie = getCookie('hubspot-email')

        if (!emailCookie) {
          try {
            setCookie('hubspot-email', emailInput.value)
          } catch (e) {
            Sentry.captureException(e)
          }
        }

        const email = emailInput.value
        const emailTitle = 'Download Prospectus'
        const emailBody = 'Hello<br>World?'
        const redirectUrl = `${window.location.href}?download=true`
        const buttonLabel = 'Download Prospectus'

        handleSubmit(email, emailTitle, emailBody, redirectUrl, buttonLabel)
      }
    },
  })

  if (error) {
    Sentry.captureException(error)
  }

  return (
    <div className="w-full gap-6 flex flex-col items-center justify-center">
      {displayTitle && title && (
        <h2 className="font-primary font-bold mb-4 mt-0 text-2xl text-center">{title}</h2>
      )}
      <div className={`w-full ${!isModal ? 'max-w-[62rem]' : ''}`}>
        {formId && loaded && <div id={`form-${formInstanceId ? formInstanceId : formId}`}></div>}
        {loading && <Loading />}
        {!formId && (
          <div className="w-full h-full flex items-center justify-center">
            formId was not provided to the form
          </div>
        )}
      </div>
    </div>
  )
}
