import { TypeButton, TypeImageGrid, TypeMarketoFormButtonBlock } from '../../types/contentful'
import TitleBlock from '../TitleBlock/TitleBlock'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import { isContentfulType } from '../../lib/contentful'
import MarketoFormButtonBlock from '../MarketoFormButtonBlock/MarketoFormButtonBlock'
import { useMemo } from 'react'
import { Icon } from '../Icon/Icon'
import isDarkColor from '../../helpers/isDarkColor'
import Container from '../Container/Container'
import ImageGridItem from './ImageGridItem'
import classNames from 'classnames'

type ImageGridProps = {
  entry: TypeImageGrid<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const ImageGrid = ({ entry }: ImageGridProps) => {
  const { mobileBehavior, ctaButtons, ctaPosition, headingType } = entry.fields

  const gridFlow = useMemo(
    () =>
      mobileBehavior === 'Vertical'
        ? 'grid-flow-row md:grid-flow-col p-10 pt-0'
        : 'grid-flow-col pl-[10%]',
    [mobileBehavior]
  )

  const backgroundStyle = useMemo(() => {
    switch (entry?.fields?.backgroundShape) {
      case 'Bolt':
        return 'bolt-wide'
      case 'Bubble':
        return 'bubble-wide'
      case 'Cube':
        return 'cube-wide'
      case 'Tab':
        return 'tab-wide'
      default:
        return ''
    }
  }, [entry?.fields?.backgroundShape])

  const hasImages = entry?.fields?.imageGridItems
  return (
    <Container
      className={`${!hasImages && 'inline-block'} full-width pb-1`}
      style={
        entry?.fields?.backgroundShape ? {} : { backgroundColor: entry?.fields?.backgroundColour }
      }
      id={entry.sys.id}
    >
      <div
        className={classNames('relative  container mx-auto', {
          'pb-6': !hasImages && !ctaButtons,
          'mt-16 mb-10': entry?.fields?.title || entry?.fields?.preTitle,
        })}
      >
        {entry?.fields?.backgroundShape && (
          <div
            className={`absolute top-0 left-0 -bottom-10 right-0 -z-10 pb-[10rem] -mt-2 ${backgroundStyle}`}
            style={
              entry?.fields?.backgroundShape
                ? { backgroundColor: entry?.fields?.backgroundColour }
                : {}
            }
          />
        )}

        <TitleBlock
          title={entry?.fields?.title}
          preTitle={entry?.fields?.preTitle}
          headingType={headingType || 'h2'}
          size={'5xl'}
          description={entry?.fields?.description}
          textColor={entry?.fields?.textColor}
          descriptionWidth={entry?.fields?.descriptionWidth}
          contenfulId={entry.sys.id}
        />

        {ctaButtons && ctaPosition === 'top' && (
          <div className="flex flex-col md:flex-row justify-center gap-3 items-center md:items-stretch mb-[4rem]">
            {ctaButtons &&
              ctaButtons.length >= 1 &&
              ctaButtons?.map((button, index) => {
                if (
                  isContentfulType<
                    TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
                  >(button, 'marketoFormButtonBlock')
                ) {
                  return (
                    <MarketoFormButtonBlock
                      entry={button}
                      key={index}
                      hsformInstanceId={`${entry?.sys?.id}-${index}`}
                    />
                  )
                } else if (
                  isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                    button,
                    'button'
                  ) &&
                  button?.fields?.url
                ) {
                  return (
                    <Button
                      id={button?.sys?.id}
                      href={button?.fields?.url}
                      buttonStyle={contentfulType[button?.fields?.style]}
                      size={contentfulSize[button?.fields?.size]}
                      variant={contentfulVariant[button?.fields?.variant]}
                      urlBehaviour={button?.fields?.urlBehaviour}
                      hoverColor={button?.fields?.hoverColor}
                      backgroundColor={button?.fields?.color}
                      key={index}
                      hoverTextColor={
                        button?.fields?.hoverColor &&
                        (isDarkColor(button?.fields?.hoverColor) ? 'white' : 'black')
                      }
                      isVideoVariantTrackable={button?.fields?.isVideoVariantTrackable}
                      showOnMobileOnly={button?.fields?.showOnMobileOnly}
                      mobileWidth={button?.fields?.mobileWidth}
                    >
                      <Icon className="w-5 h-5 mr-2" name={button.fields.icon} />
                      {button?.fields?.label}
                    </Button>
                  )
                }
              })}
          </div>
        )}

        {entry?.fields?.imageGridItems && (
          <div
            className={`full-width sm:constrained-width grid ${gridFlow} md:p-0 auto-cols-[minmax(80%,_1fr)] ${
              entry.fields.imageGridItems.length === 2
                ? entry?.fields?.columnWidth === 'Narrow'
                  ? 'md:auto-cols-[18%]'
                  : ' md:auto-cols-[50%] lg:auto-cols-[30%] !gap-12'
                : 'md:auto-cols-[296px]'
            } gap-12 sm:gap-4 mt-4 sm:max-w-full overflow-x-scroll lg:justify-center`}
            style={entry?.fields?.textColor ? { color: entry?.fields?.textColor } : {}}
          >
            {entry?.fields?.imageGridItems?.map((item, index) => {
              return (
                <ImageGridItem
                  item={item}
                  key={index}
                  mobileBehavior={mobileBehavior}
                  imageSize={entry.fields.imageSize}
                />
              )
            })}
          </div>
        )}

        {ctaPosition !== 'top' && (
          <div className="flex flex-col md:flex-row justify-center gap-3 items-center md:items-stretch">
            {ctaButtons &&
              ctaButtons.length >= 1 &&
              ctaButtons?.map((button, index) => {
                if (
                  isContentfulType<
                    TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
                  >(button, 'marketoFormButtonBlock')
                ) {
                  return (
                    <MarketoFormButtonBlock
                      entry={button}
                      key={index}
                      hsformInstanceId={`${entry?.sys?.id}-${index}`}
                    />
                  )
                } else if (
                  isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                    button,
                    'button'
                  ) &&
                  button?.fields?.url
                ) {
                  return (
                    <Button
                      id={button?.sys?.id}
                      href={button?.fields?.url}
                      buttonStyle={contentfulType[button?.fields?.style]}
                      size={contentfulSize[button?.fields?.size]}
                      variant={contentfulVariant[button?.fields?.variant]}
                      urlBehaviour={button?.fields?.urlBehaviour}
                      hoverColor={button?.fields?.hoverColor}
                      backgroundColor={button?.fields?.color}
                      key={index}
                      hoverTextColor={
                        button?.fields?.hoverColor &&
                        (isDarkColor(button?.fields?.hoverColor) ? 'white' : 'black')
                      }
                      isVideoVariantTrackable={button?.fields?.isVideoVariantTrackable}
                      showOnMobileOnly={button?.fields?.showOnMobileOnly}
                      mobileWidth={button?.fields?.mobileWidth}
                    >
                      <Icon className="w-5 h-5 mr-2" name={button.fields.icon} />
                      {button?.fields?.label}
                    </Button>
                  )
                }
              })}
          </div>
        )}
      </div>
    </Container>
  )
}

export default ImageGrid
