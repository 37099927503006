import React, { useMemo } from 'react'
import { styles } from '../../config/contentful'
import { fontSize, HeadingProps } from '../Typography/Typography'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { TypeMarketoFormButtonBlock, TypeButton } from '../../types/contentful'
import { renderCTAButtons } from '../CTAButtons/CTAButtons'

type TitleBlockProps = {
  title: string
  preTitle: string
  children?: React.ReactNode
  hasImage?: boolean
  headingType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  align?: string
  isHero?: boolean
  size?: HeadingProps['size']
  description?: string
  textColor?: string
  customClassname?: string
  contenfulId?: string
  descriptionWidth?: 'Narrow' | 'Wide'
  preTitleAlignment?: 'Left' | 'Center' | 'Right'
  titleAlignment?: 'Left' | 'Center' | 'Right'
  descriptionAlignment?: 'Left' | 'Center' | 'Right'
  titleColor?: string
  preTitleColor?: string
  descriptionColor?: string
  isQuote?: boolean
  backgroundColor?: string
  paddingTop?: string
  paddingBottom?: string
  contentLinkColor?: string
  ctaButtons?:
    | (
        | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
        | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
      )[]
}

const getOverideAlignmentClass = (alignment: string) => {
  switch (alignment) {
    case 'Left':
      return '!text-left'
    case 'Center':
      return '!text-center'
    case 'Right':
      return '!text-right'
    default:
      return ''
  }
}

const TitleBlock = ({
  title,
  preTitle,
  children,
  customClassname,
  hasImage = false,
  headingType = 'h1',
  align = 'center',
  isHero = false,
  size = '6xl',
  description,
  textColor,
  contenfulId,
  descriptionWidth,
  preTitleAlignment,
  titleAlignment,
  descriptionAlignment,
  isQuote,
  titleColor,
  preTitleColor,
  descriptionColor,
  backgroundColor,
  paddingTop,
  paddingBottom = '16',
  contentLinkColor,
  ctaButtons,
}: TitleBlockProps) => {
  const HeadingTag = headingType

  const headingStyles = {
    h1: styles.renderNode.heading_1,
    h2: styles.renderNode.heading_2,
    h3: styles.renderNode.heading_3,
    h4: styles.renderNode.heading_4,
    h5: styles.renderNode.heading_5,
    h6: styles.renderNode.heading_6,
  }

  const headingStyle = classNames(
    headingStyles[headingType] || styles.renderNode.heading_1,
    fontSize[size]
  )

  const preTitleAlignmentClass = useMemo(
    () => getOverideAlignmentClass(preTitleAlignment),
    [preTitleAlignment]
  )
  const titleAlignmentClass = useMemo(
    () => getOverideAlignmentClass(titleAlignment),
    [titleAlignment]
  )
  const descriptionAlignmentClass = useMemo(
    () => getOverideAlignmentClass(descriptionAlignment),
    [descriptionAlignment]
  )

  const defaultTextPosition = useMemo(() => {
    switch (align) {
      case 'left':
        return 'text-left'
      case 'center':
        return 'text-center'
      case 'right':
        return 'text-right'
      default:
        return ''
    }
  }, [align])

  const descriptionClass = descriptionWidth !== 'Wide' ? 'w-[90%] lg:w-1/2' : 'w-[90%]'
  return (
    <div
      className={classNames(
        backgroundColor ? 'full-width px-6' : 'w-full',
        isHero && '!pt-28 md:pt-36 bg-title-block'
      )}
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        paddingTop: `${paddingTop}px`,
        paddingBottom: `${paddingBottom}px`,
      }}
    >
      <div className={classNames(hasImage ? 'bg-title-block' : isHero && 'pb-12')}>
        {preTitle && (
          <p
            className={classNames(
              'uppercase font-inter mb-0',
              !preTitleColor && 'text-secondary-ui-600 dark:text-primary-ui-white',
              defaultTextPosition,
              customClassname,
              preTitleAlignmentClass
            )}
            style={preTitleColor ? { color: preTitleColor } : undefined}
          >
            {preTitle}
          </p>
        )}
        <HeadingTag
          className={classNames(
            headingStyle,
            defaultTextPosition,
            customClassname,
            titleAlignmentClass
          )}
          id={`${contenfulId}-title`}
          style={titleColor ? { color: titleColor } : undefined}
        >
          {title && title?.includes(':')
            ? title.split(':').map((part, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <br />}
                  {part}
                  {index === 0 && ':'}
                </React.Fragment>
              ))
            : title}
        </HeadingTag>
        {!!description && (
          <div
            className={classNames(
              'flex',
              (title || preTitle) && 'mt-6',
              isQuote ? 'items-start' : 'items-center'
            )}
            style={{
              ...(descriptionColor && { color: descriptionColor }),
              ...(!isQuote && { justifyContent: align }),
              ...(contentLinkColor && { '--contentLinkColor': contentLinkColor }),
            }}
            id={`${contenfulId}-description`}
          >
            <ReactMarkdown
              className={classNames(
                'text-xl text-balance',
                'last:[&>p]:mb-0',
                'markdown',
                contentLinkColor ? '[&>p>a]:text-[--contentLinkColor]' : undefined,
                descriptionClass,
                defaultTextPosition,
                customClassname,
                descriptionAlignmentClass
              )}
            >
              {description}
            </ReactMarkdown>
          </div>
        )}

        {ctaButtons && ctaButtons.length >= 1 && (
          <div className="mt-4 flex flex-col md:flex-row justify-center gap-3 items-center md:items-stretch mb-[4rem]">
            {renderCTAButtons({ buttons: ctaButtons })}
          </div>
        )}

        {children}
      </div>
    </div>
  )
}

export default TitleBlock
