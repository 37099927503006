import ButtonBase from '../ButtonBase/ButtonBase'
import React, { useState } from 'react'
import { ButtonPropTypes } from './Button'
import FullscreenVideo from '../FullscreenVideo/FullscreenVideo'

const VideoButton = ({ children, url, id, ...rest }: ButtonPropTypes) => {
  const [fullscreen, setFullscreen] = useState(false)

  const openFullscreen = () => {
    setFullscreen(true)
  }

  return (
    <>
      <ButtonBase id={id} onClick={openFullscreen} className={rest.className}>
        {children}
      </ButtonBase>

      {fullscreen && (
        <FullscreenVideo
          onClose={() => setFullscreen(false)}
          url={url}
          id={id}
          isTrackable={rest?.isVideoVariantTrackable}
          title={rest?.title}
        />
      )}
    </>
  )
}

export default VideoButton
