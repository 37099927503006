import { ProductList } from './product-list.model'
import { Product } from './product.model'
import { IEventExtended } from '../../../types/types'
import * as memoized from 'memoizee'
import { getEventPriceByCurrency, getLocalizedFields } from '../../../lib/locale'

const createProduct = (
  listId: ProductList,
  event: IEventExtended,
  country: string,
  region?: string,
  index?: number
) => {
  const fields = getLocalizedFields<IEventExtended>(event, country)?.fields

  const categories = {}
  fields.tags
    ?.map(tag => tag?.fields?.label)
    .forEach((tag, index) => {
      categories[`item_category${index === 0 ? '' : index + 1}`] = tag
    })

  const { priceUS, priceNZ, priceAU } = fields
  const price = getEventPriceByCurrency({ country, region, priceUS, priceNZ, priceAU })

  const product = {
    ...listId,
    item_id: fields.id,
    item_name: fields.title,
    item_brand: 'academyEX',
    ...categories,
    price: price,
    quantity: 1,
  } as Product
  if (index) {
    product.index = index
  }
  return product
}
export const createEventProduct = memoized(createProduct)

const createProducts = (
  listId: ProductList,
  events: IEventExtended[],
  country: string,
  region?: string
): Product[] => {
  return events.map((event, index) => createEventProduct(listId, event, country, region, index))
}
export const createEventProducts = memoized(createProducts)
