import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(advancedFormat)
dayjs.extend(timezone)

const NZT = 'Pacific/Auckland'

export const date = (dateString: string): Dayjs => {
  return dayjs(dateString).tz(NZT)
}

export { dayjs }
