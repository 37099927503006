// Render Course
import { useLocation } from '../../context/Location/LocationProvider'
import React from 'react'
import { getCourseTypeSlug } from '../../lib/courses'
import { formatPrice, getCurrency, useDualPrices } from '../../lib/locale'
import { Course, CourseType } from '../../types/graphql'
import { getImageWithFocalPoint } from '../contentful-ui-utils'
import Card from './Card'
import CardV2 from './CardV2'
import { CardShape, CardSize } from '../../types/content'
import { ICourseExtended, Price } from '../../types/types'
import {
  TypeButton,
  TypeImageWithFocalPoint,
  TypeMarketoFormButtonBlock,
  TypeVideo,
} from '../../types/contentful'
import { GET_COURSE } from '../../gql/courses'
import { useQuery } from '@apollo/client'

type CourseCardProps = {
  course: ICourseExtended
  shape?: CardShape
  size?: CardSize
  layoutType?: 'Default' | 'Description' | 'Promotion'
  cornersStyle?: 'Rounded' | 'Square'
  button?:
    | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  onCardClick?: () => void
  externalLink?: string
}
export const CourseCard = ({
  course,
  onCardClick,
  size,
  shape,
  layoutType,
  button,
  cornersStyle,
  externalLink,
}: CourseCardProps) => {
  const {
    title,
    type,
    slug,
    tags,
    pricingOptions,
    thumbnail,
    hero,
    timeframe,
    credits,
    imageUrl,
    description,
    durationDescription,
    timeframeIcon,
    durationDescriptionIcon,
    startType,
    eoiOnly,
    listItems,
    internationalScholarshipInfo,
    domesticScholarshipInfo,
  } = course.fields

  const { country, region } = useLocation()

  const typeSlug = getCourseTypeSlug(type)
  const currency = getCurrency(country, region)
  const price = formatPrice({
    priceNZ: pricingOptions?.priceNZDomestic,
    priceAU: pricingOptions?.priceAUOnline,
    priceUS: pricingOptions?.priceUSOnline,
    country,
    region,
  })

  const { prices } = useDualPrices({
    _currency: 'USD',
    priceNZDomestic: pricingOptions?.priceNZDomestic,
    priceNZOnshore: pricingOptions?.priceNZOnshore,
    priceAUOnline: pricingOptions?.priceNZOnshore,
    priceAUOnshore: pricingOptions?.priceAUOnshore,
    priceUSOnline: pricingOptions?.priceUSOnline,
    priceUSOnshore: pricingOptions?.priceUSOnshore,
    includeCountryCodeOverride: false,
  })

  const isComingSoon = course?.fields?.publicIntakes && !course?.fields?.publicIntakes?.length
  const isFree =
    type === CourseType.FreeCourse ||
    (type === CourseType.Microcredential && !price) ||
    price === Price.FREE

  const data = useQuery<{ course: Course }>(GET_COURSE, {
    variables: { slug: slug },
    skip: layoutType !== 'Promotion',
  })
  if (layoutType === 'Promotion') {
    const intakes = data?.data?.course?.publicIntakes
    return (
      <CardV2
        title={title}
        description={description}
        slug={`/courses/${typeSlug}/${slug}`}
        onCardClick={onCardClick}
        size={size}
        thumbnailWithFocalPoint={
          thumbnail ||
          getImageWithFocalPoint(
            hero as
              | TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
              | TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>
          )
        }
        thumbnailUrl={imageUrl}
        cardType="Course"
        tags={tags}
        priceFree={isFree}
        prices={prices}
        currency={currency}
        timeframe={timeframe}
        credits={credits}
        shape={shape}
        isComingSoon={isComingSoon}
        type={layoutType}
        cornersStyle={cornersStyle}
        button={button}
        durationDescription={durationDescription}
        timeframeIcon={timeframeIcon}
        durationDescriptionIcon={durationDescriptionIcon}
        intakes={intakes}
        startType={startType}
        {...course}
      />
    )
  } else {
    return (
      <Card
        title={title}
        description={description}
        slug={externalLink || `/courses/${typeSlug}/${slug}`}
        onCardClick={onCardClick}
        size={size}
        thumbnailWithFocalPoint={
          thumbnail ||
          getImageWithFocalPoint(
            hero as
              | TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
              | TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>
          )
        }
        thumbnailUrl={imageUrl}
        cardType="Course"
        tags={tags}
        priceFree={isFree}
        price={price}
        currency={currency}
        // timeframe={timeframe}
        credits={credits}
        shape={shape}
        isComingSoon={isComingSoon}
        type={layoutType}
        courseType={type}
        cornersStyle={cornersStyle}
        button={button}
        durationDescription={durationDescription}
        timeframeIcon={timeframeIcon}
        durationDescriptionIcon={durationDescriptionIcon}
        eoiOnly={(type === CourseType.Microcredential && country !== 'NZ') || eoiOnly}
        listItems={listItems}
        internationalScholarshipInfo={internationalScholarshipInfo}
        domesticScholarshipInfo={domesticScholarshipInfo}
        {...course}
      />
    )
  }
}
