import { TypeHeroSlide, TypeImageWithFocalPoint } from '../../types/contentful'
import getFocalRatio from '../../helpers/getFocalRatio'
import { getShapeClass } from '../Shapes/Shapes'
import Image from 'next/legacy/image'
import React from 'react'

type ImageSlideProps = {
  block: TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
  shape:
    | TypeHeroSlide<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']['shape']
    | 'Cube Masters'
    | 'None'
  className: string
  index?: number
}

const ImageSlide = ({ block, shape, className, index }: ImageSlideProps) => {
  if (!block?.fields) {
    return null
  }
  const focalRatio = getFocalRatio(block?.fields)
  return (
    <div className={`${className} ${getShapeClass('Large Square', shape)}`}>
      <Image
        src={`https:${block.fields.image.fields.file.url}`}
        alt={block.fields.image.fields.description || block.fields.title}
        layout="fill"
        objectFit="cover"
        priority={index === 0} // only load first image on initial load
        objectPosition={`${focalRatio.x}% ${focalRatio.y}%`}
      />
    </div>
  )
}

export default ImageSlide
