import React from 'react'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import { Icon } from '../Icon/Icon'
import { Document } from '@contentful/rich-text-types'
import classNames from 'classnames'
import isDarkColor from '../../helpers/isDarkColor'

interface TopBannerProps {
  topBannerText: Document
  setShowTopBanner?: (value: boolean) => void
  bgColor?: string
}

const TopBanner = ({ topBannerText, setShowTopBanner, bgColor }: TopBannerProps) => {
  return (
    <div
      id="top-banner"
      className={classNames(
        'full-width py-3 bottom-0 px-5',
        isDarkColor(bgColor) ? 'text-white' : 'text-black'
      )}
      style={{ backgroundColor: bgColor || 'var(--color-brand-600)' }}
    >
      <div className="flex content-between items-center gap-3">
        <div
          className={classNames(
            'text-center w-full [&>p]:mb-0',
            isDarkColor(bgColor) ? '[&>p>a]:text-white' : '[&>p>a]:text-black'
          )}
        >
          {renderDocumentToReactComponents(topBannerText)}
        </div>
        <Icon
          name="Close"
          className="w-3 h-3 cursor-pointer"
          onClick={() => setShowTopBanner(false)}
        />
      </div>
    </div>
  )
}

interface BottomBannerProps {
  bottomBannerText: Document
  bgColor?: string
}

const BottomBanner = ({ bottomBannerText, bgColor }: BottomBannerProps) => {
  return (
    <div
      className={classNames(
        'bottom-0 full-width text-center py-3 !absolute md:hidden [&>p]:mb-0',
        isDarkColor(bgColor) ? 'text-white [&>p>a]:text-white' : 'text-black [&>p>a]:text-black'
      )}
      style={{ backgroundColor: bgColor || 'var(--color-brand-600)' }}
    >
      {renderDocumentToReactComponents(bottomBannerText)}
    </div>
  )
}

interface PersonalisedOfferProps {
  personalisedOfferText: Document
  bgColor?: string
}

const PersonalisedOffer = ({ personalisedOfferText, bgColor }: PersonalisedOfferProps) => (
  <div
    className={classNames(
      'hidden md:-bottom-6 -left-2 lg:-left-8 md:w-[170px] min-h-[145px] py-8 md:absolute md:cube md:flex flex-col justify-center text-center [&>p]:mb-0',
      isDarkColor(bgColor) ? 'text-white [&>p>a]:text-white' : 'text-black [&>p>a]:text-black'
    )}
    style={{ backgroundColor: bgColor || 'var(--color-brand-600)' }}
  >
    {renderDocumentToReactComponents(personalisedOfferText)}
  </div>
)

export { TopBanner, BottomBanner, PersonalisedOffer }
