import { isContentfulType } from '../../lib/contentful'
import { TypeButton, TypeMarketoFormButtonBlock } from '../../types/contentful'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import MarketoButton from '../Marketo/MarketoButton'
import { Icon } from '../Icon/Icon'
import React from 'react'
import { EmbeddedFormTypes } from '../../types/types'
import HubspotFormButtonBlock from '../MarketoFormButtonBlock/HubspotFormButtonBlock'
import classNames from 'classnames'

type CTAButtonProps = {
  buttons: (
    | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  )[]
  onClick?: () => void
  slug?: string
  className?: string
}

export const renderCTAButtons = ({ buttons, onClick, slug, className }: CTAButtonProps) => {
  if (!buttons) return
  return buttons?.map(button => {
    if (
      isContentfulType<TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
        button,
        'marketoFormButtonBlock'
      )
    ) {
      return (
        <>
          {button?.fields?.formType === EmbeddedFormTypes.MARKETO_FORM_MODAL && (
            <MarketoButton
              key={button?.sys?.id}
              marketoEnquiryFormId={button?.fields?.marketoFormId}
              marketoAreaOfInterest={button?.fields?.marketoAreaOfInterest}
              marketoLeadSource={button?.fields?.marketoLeadSource}
              preTitle="Get in touch"
              title="We look forward to hearing from you"
            >
              {(handleClick, loading) => (
                <Button
                  className={classNames('justify-center', className)}
                  buttonStyle={contentfulType[button?.fields?.style]}
                  size={contentfulSize[button?.fields?.size]}
                  onClick={handleClick}
                  disabled={loading}
                  showOnMobileOnly={button?.fields?.showOnMobileOnly}
                  mobileWidth={button?.fields?.mobileWidth}
                >
                  {button?.fields?.buttonLabel}
                </Button>
              )}
            </MarketoButton>
          )}
          {button?.fields?.formType === EmbeddedFormTypes.HUBSPOT_FORM_MODAL && (
            <HubspotFormButtonBlock
              key={button?.sys.id}
              portalId={button?.fields?.portalId}
              formId={button?.fields?.marketoFormId}
              title={button?.fields?.title}
              buttonLabel={button?.fields?.buttonLabel}
              buttonColor={button?.fields?.buttonColor}
              style={contentfulType[button?.fields?.style]}
              size={contentfulSize[button?.fields?.size]}
              icon={button?.fields?.icon}
              buttonHoverColor={button?.fields?.buttonHoverColor}
              formInstanceId={button?.sys?.id}
              showOnMobileOnly={button?.fields?.showOnMobileOnly}
              mobileWidth={button?.fields?.mobileWidth}
            />
          )}
        </>
      )
    } else if (
      isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(button, 'button')
    ) {
      return (
        <Button
          id={button?.sys?.id}
          key={button?.sys.id}
          className={classNames('justify-center flex-shrink-0', className)}
          buttonStyle={contentfulType[button?.fields?.style]}
          hoverColor={button?.fields?.hoverColor}
          size={contentfulSize[button?.fields?.size]}
          backgroundColor={button?.fields?.color}
          href={button?.fields?.url?.trim() === '' ? slug : button?.fields?.url}
          urlBehaviour={button?.fields?.urlBehaviour}
          variant={contentfulVariant[button?.fields.variant]}
          isVideoVariantTrackable={button?.fields?.isVideoVariantTrackable}
          showOnMobileOnly={button?.fields?.showOnMobileOnly}
          mobileWidth={button?.fields?.mobileWidth}
          onClick={onClick}
        >
          {button?.fields.icon && (
            <Icon
              className={classNames({
                'w-6 h-6 mr-3': contentfulSize[button?.fields?.size] === 'xl',
                'w-4 h-4 mr-1':
                  contentfulSize[button?.fields?.size] === 'base' ||
                  contentfulSize[button?.fields?.size] === 'sm',
              })}
              name={button?.fields?.icon}
            />
          )}
          {button?.fields?.label}
        </Button>
      )
    }
  })
}
