import React from 'react'
import { Icon } from '../Icon/Icon'

type phoneBlockProps = {
  phoneNumber: string
  id: string
  color: string
  mobileColor?: string
  callable?: boolean
}

const PhoneBlock = ({ phoneNumber, id, color, mobileColor, callable }: phoneBlockProps) => {
  const cssVars = {
    '--default-color': `${color}`,
    '--mobile-color': `${mobileColor}`,
  } as React.CSSProperties

  return (
    <a
      className={`flex flex-row justify-center items-center gap-2 ${
        mobileColor ? 'text-[--mobile-color] md:text-[--default-color]' : 'text-[--default-color]'
      }`}
      href={callable ? `tel:${phoneNumber}` : undefined}
      id={id}
      style={cssVars}
    >
      <Icon name={'PhoneIcon'} className="w-5 h-5" />

      <span className="hidden md:block text-sm">{phoneNumber}</span>
    </a>
  )
}

export default PhoneBlock
