import AcademyEX from './academy-ex.svg'
import AcademyEXCorner from './academy-ex-corner.svg'
import AcademyEXLogo from './academy-ex-logo.svg'
import AcademyEXUpskilling from './academy-ex-upskilling.svg'
import AcademyEXImpact from './academy-ex-impact.svg'
import AcademyEXProjects from './academy-ex-projects.svg'
import AcademyEXGlobal from './academy-ex-global.svg'
import AcademyExMaoriLearners from './academy-ex-maori-learners.svg'
import AcademyExPacificLearners from './academy-ex-pacific-learners.svg'
import AcademyEXKnowledge from './academy-ex-knowledge.svg'
import AcademyEXLeading from './academy-ex-leading.svg'
import ArrowLeft from './arrow-left.svg'
import ArrowRight from './arrow-right.svg'
import BallPlane from './ball-plane.svg'
import Check from './check.svg'
import ClipboardSolid from './clipboard-solid.svg'
import Close from './close.svg'
import Circle from './circle.svg'
import Community from './community.svg'
import Connect from './connect.svg'
import Diamond from './diamond.svg'
import Digital from './digital.svg'
import EFL from './efl.svg'
import Events from './events.svg'
import Facebook from './facebook.svg'
import Filter from './filter.svg'
import Github from './github.svg'
import Group from './group.svg'
import Hamburger from './hamboiger.svg'
import Hexagon from './hexagon.svg'
import House from './house.svg'
import Instagram from './instagram.svg'
import Knobbly from './knobbly.svg'
import LinkedIn from './linkedin.svg'
import Medal from './medal.svg'
import MegaPhone from './mega-phone.svg'
import Mail from './mail.svg'
import Mentorship from './mentorship.svg'
import Pathways from './pathways.svg'
import Play from './play.svg'
import Project from './project.svg'
import Resources from './resources.svg'
import Search from './search-white.svg'
import SemiCircle from './semi-circle.svg'
import Support from './support.svg'
import Student from './student.svg'
import TFL from './tfl.svg'
import Tips from './tips.svg'
import TML from './tml.svg'
import ToggleDown from './toggle-down.svg'
import ToggleUp from './toggle-up.svg'
import Twitter from './twitter.svg'
import Youtube from './youtube.svg'
import BadgeSolid from './badge-solid.svg'
import BookSolid from './book-solid.svg'
import CalendarOutline from './calendar-outline.svg'
import FireSolid from './fire-solid.svg'
import LinkOutline from './link-outline.svg'
import LinkSolid from './link-solid.svg'
import LocationSolid from './location-solid.svg'
import ShareOutline from './share-outline.svg'
import ShareSolid from './share-solid.svg'
import TrendingSolid from './trending-solid.svg'
import CalendarSolid from './calendar-solid.svg'
import ChatSolid from './chat-solid.svg'
import GlobeSolid from './globe-solid.svg'
import TiktokSolid from './tiktok-solid.svg'
import TplSolid from './tpl-solid.svg'
import UsersSolid from './users-solid.svg'
import LightbulbSolid from './lightbulb-solid.svg'
import LightningSolid from './lightning-solid.svg'
import LDSolid from './ld-solid.svg'
import WWW from './www.svg'
import DocSolid from './doc-solid.svg'

import {
  AcademicCapIcon,
  ArrowDownTrayIcon,
  BoltIcon,
  BriefcaseIcon,
  BuildingLibraryIcon,
  BuildingOfficeIcon,
  CameraIcon,
  CheckBadgeIcon,
  ClipboardDocumentListIcon,
  CreditCardIcon,
  CursorArrowRaysIcon,
  HandThumbUpIcon,
  HomeIcon as Home,
  LinkIcon,
  RocketLaunchIcon,
  ShareIcon,
  ShoppingCartIcon,
  StarIcon,
  TruckIcon,
  VideoCameraIcon,
  WrenchScrewdriverIcon,
  SpeakerWaveIcon,
  AdjustmentsVerticalIcon,
  DocumentMagnifyingGlassIcon,
  Cog8ToothIcon,
  ComputerDesktopIcon,
  ArrowsPointingOutIcon,
  PlayIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid'

import {
  AcademicCapIcon as AcademicCapIconOutline,
  ArrowUpRightIcon,
  CalendarIcon,
  MapPinIcon,
  PhoneArrowUpRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  EnvelopeIcon as MailOutline,
  ArrowTopRightOnSquareIcon,
  ArrowRightEndOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
  CurrencyDollarIcon,
  DocumentCheckIcon as DocumentCheckOutlineIcon,
  ArrowRightCircleIcon,
  ClockIcon as ClockOutline,
  MegaphoneIcon as MegaPhoneOutline,
  BoltIcon as BoltOutline,
} from '@heroicons/react/24/outline'

import {
  CheckCircleIcon as CheckCircleSolid,
  BellIcon as BellSolid,
  ShieldCheckIcon as ShieldCheckSolid,
  PhoneIcon,
  BoltIcon as BoltSolid,
  MegaphoneIcon as MegaPhoneSolid,
  CurrencyDollarIcon as CurrencyDollarSolid,
  SparklesIcon,
  ClockIcon,
} from '@heroicons/react/24/solid'

export const Icons = {
  AcademyEX,
  AcademyEXCorner,
  AcademyEXLogo,
  AcademyEXGlobal,
  AcademyEXImpact,
  AcademyEXKnowledge,
  AcademyEXLeading,
  AcademyEXProjects,
  AcademyEXUpskilling,
  AcademyExMaoriLearners,
  AcademyExPacificLearners,
  ArrowDownTray: ArrowDownTrayIcon,
  ArrowLeft,
  ArrowRight,
  ArrowUpRight: ArrowUpRightIcon,
  ArrowTopRightOnSquareIcon,
  ArrowRightEndOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
  ArrowRightCircleIcon,
  BallPlane,
  BellSolid,
  Bolt: BoltIcon,
  BoltOutline,
  BoltSolid,
  Briefcase: BriefcaseIcon,
  BuildingLibrary: BuildingLibraryIcon,
  BuildingOffice: BuildingOfficeIcon,
  Calendar: CalendarIcon,
  Camera: CameraIcon,
  Circle,
  Check,
  CheckBadge: CheckBadgeIcon,
  CheckCircleSolid,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardSolid,
  ClipboardDocumentList: ClipboardDocumentListIcon,
  Clock: ClockIcon,
  Close,
  Community,
  Connect,
  CreditCard: CreditCardIcon,
  CurrencyDollarIcon,
  CurrencyDollarSolid,
  CursorArrowRays: CursorArrowRaysIcon,
  Diamond,
  Digital,
  DocSolid,
  DocumentCheckOutlineIcon,
  EFL,
  Events,
  Facebook,
  Filter,
  Github,
  GraduateHat: AcademicCapIcon,
  GraduateHatOutline: AcademicCapIconOutline,
  Group,
  HandThumbUpIcon,
  Hamburger,
  Hexagon,
  House,
  Home,
  Instagram,
  Knobbly,
  LinkedIn,
  Link: LinkIcon,
  Location: MapPinIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  Mail,
  Medal,
  MegaPhone,
  MegaPhoneSolid,
  MegaPhoneOutline,
  Mentorship,
  Pathways,
  PhoneArrowUpRight: PhoneArrowUpRightIcon,
  PhoneIcon,
  Play,
  Project,
  Resources,
  RocketLaunch: RocketLaunchIcon,
  MailOutline,
  Search,
  SemiCircle,
  Share: ShareIcon,
  ShieldCheckSolid,
  ShoppingCart: ShoppingCartIcon,
  Star: StarIcon,
  Student,
  Support,
  TFL,
  Tips,
  TML,
  ToggleDown,
  ToggleUp,
  Truck: TruckIcon,
  Twitter,
  Youtube,
  WrenchScrewdriver: WrenchScrewdriverIcon,
  WWW,
  BadgeSolid,
  BookSolid,
  CalendarOutline,
  ClockOutline,
  FireSolid,
  LinkOutline,
  LinkSolid,
  LightbulbSolid,
  LightningSolid,
  LocationSolid,
  ShareOutline,
  ShareSolid,
  SparklesIcon,
  TrendingSolid,
  CalendarSolid,
  ChatSolid,
  GlobeSolid,
  TiktokSolid,
  TplSolid,
  UsersSolid,
  LDSolid,
  VideoCamera: VideoCameraIcon,
  PlayIcon,
  SpeakerWaveIcon,
  AdjustmentsVerticalIcon,
  DocumentMagnifyingGlassIcon,
  Cog8ToothIcon,
  ComputerDesktopIcon,
  ArrowsPointingOutIcon,
  QuestionMarkCircleIcon,
}

// When updating contentful icons, copy iconNames to ./migration/utils.ts so that the icons are always up to date
// const iconNames = Object.keys(Icons).sort()
// console.log(iconNames)

export default Icons
