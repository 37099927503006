import { ProductList } from './model/product-list.model'
import {
  checkoutStart,
  setDisplayedProducts,
  setSelectedProduct,
  setViewedProduct,
} from './product-data-layer'
import { createEventProduct, createEventProducts } from './model/event.adapter'
import { IEventExtended } from '../../types/types'
import { getCurrency } from '../../lib/locale'

export const setDisplayedEvents = (
  list: ProductList,
  events: IEventExtended[],
  country: string,
  region?: string
) =>
  setDisplayedProducts(
    list,
    getCurrency(country, region),
    createEventProducts(list, events, country, region)
  )

export const setSelectedEvent = (
  list: ProductList,
  event: IEventExtended,
  country: string,
  region?: string,
  index?: number
) =>
  setSelectedProduct(
    list,
    getCurrency(country, region),
    createEventProduct(list, event, country, region, index)
  )

export const setViewedEvent = (
  list: ProductList,
  event: IEventExtended,
  country: string,
  region?: string
) =>
  setViewedProduct(
    list,
    getCurrency(country, region),
    createEventProduct(list, event, country, region)
  )

export const startEventCheckout = (
  list: ProductList,
  event: IEventExtended,
  country: string,
  region?: string
) =>
  checkoutStart(
    list,
    { currency: getCurrency(country, region) },
    createEventProduct(list, event, country, region)
  )
