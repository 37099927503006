export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  DateTime: any
  DateTimeTz: any
  JSON: any
}

export type Course = {
  __typename?: 'Course'
  badgeUrl?: Maybe<Scalars['String']>
  courseProvider: CourseProvider
  credits?: Maybe<Scalars['Int']>
  ctaUrl?: Maybe<Scalars['String']>
  deliveryMethod?: Maybe<Scalars['String']>
  durationDescription?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  interviewUrl?: Maybe<Scalars['String']>
  nzqaLevel?: Maybe<Scalars['String']>
  overview?: Maybe<Scalars['String']>
  paymentMethods: Array<CoursePaymentMethod>
  period?: CoursePeriod
  pricingOptions: {
    priceNZDomestic: Scalars['Int']
    priceNZOnshore: Scalars['Int']
    priceAUOnline: Scalars['Int']
    priceAUOnshore: Scalars['Int']
    priceUSOnline: Scalars['Int']
    priceUSOnshore: Scalars['Int']
  }
  programme?: Maybe<Programme>
  publicIntakes: Array<CourseIntake>
  requiredCompletedCourseIds?: Maybe<Array<Maybe<Scalars['String']>>>
  slug: Scalars['String']
  startType: CourseStartType
  timeframe?: Maybe<Scalars['String']>
  title: Scalars['String']
  type: CourseType
  typeSlug?: Maybe<Scalars['String']>
}

export type Event = {
  __typename?: 'Event'
  badgeUrl?: Maybe<Scalars['String']>
  courseProvider: CourseProvider
  credits?: Maybe<Scalars['Int']>
  ctaUrl?: Maybe<Scalars['String']>
  deliveryMethod?: Maybe<Scalars['String']>
  durationDescription?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  interviewUrl?: Maybe<Scalars['String']>
  isComingSoon: Scalars['Boolean']
  isFree: Scalars['Boolean']
  isRecommended: Scalars['Boolean']
  nzqaLevel?: Maybe<Scalars['String']>
  overview?: Maybe<Scalars['String']>
  paymentMethods: Array<CoursePaymentMethod>
  period: CoursePeriod
  priceAU: Scalars['Int']
  priceNZ: Scalars['Int']
  priceUS: Scalars['Int']
  programme?: Maybe<Programme>
  publicIntakes: Array<CourseIntake>
  requiredCompletedCourseIds?: Maybe<Array<Maybe<Scalars['String']>>>
  slug: Scalars['String']
  soiUrl?: Maybe<Scalars['String']>
  startType: CourseStartType
  timeframe?: Maybe<Scalars['String']>
  title: Scalars['String']
  type: CourseType
  typeSlug?: Maybe<Scalars['String']>
}

export type CourseEdition = CourseEditionInterface & {
  __typename?: 'CourseEdition'
  accessExpired: Scalars['Boolean']
  course: Course
  dateEnd?: Maybe<Scalars['DateTimeTz']>
  dateStart?: Maybe<Scalars['DateTimeTz']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  overview: Scalars['String']
  slug: Scalars['String']
  title: Scalars['String']
  version: Scalars['Int']
}

export type CourseEditionInterface = {
  canView: Scalars['Boolean']
  course: Course
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  overview: Scalars['String']
  slug: Scalars['String']
  title: Scalars['String']
  version: Scalars['Int']
}

export type CourseIntake = {
  __typename?: 'CourseIntake'
  canEnrol: Scalars['Boolean']
  courseEdition?: Maybe<PublicCourseEdition>
  dateEnd?: Maybe<Scalars['Date']>
  dateStart?: Maybe<Scalars['Date']>
  enrolUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isEnrolled: Scalars['Boolean']
  slug: Scalars['String']
  timeframe: CourseIntakeTimeframe
  timeframeDescription?: Maybe<Scalars['String']>
  title: Scalars['String']

}

export enum CourseIntakeTimeframe {
  FullTime = 'FULL_TIME',
  Na = 'NA',
  PartTime = 'PART_TIME',
}

export enum CoursePaymentMethod {
  BankTransfer = 'BANK_TRANSFER',
  CreditCard = 'CREDIT_CARD',
  FeesFree = 'FEES_FREE',
  Invoice = 'INVOICE',
  Social = 'SOCIAL',
  Studylink = 'STUDYLINK',
}

export enum CoursePeriod {
  Module = 'module',
  Phase = 'phase',
  Residency = 'residency',
  Session = 'session',
  Sprint = 'sprint',
  Week = 'week',
}

export enum openToType{
  INTERNATIONAL_ONLY ='INTERNATIONAL_ONLY',
  DOMESTIC_ONLY = 'DOMESTIC_ONLY',
  INTERNATIONAL_AND_DOMESTIC = 'INTERNATIONAL_AND_DOMESTIC'
}

export type CourseProvider = {
  __typename?: 'CourseProvider'
  colour?: Maybe<Scalars['String']>
  id: Scalars['ID']
  slug: Scalars['String']
  title: Scalars['String']
}

export enum CourseStartType {
  Fixed = 'Fixed',
  Rolling = 'Rolling',
}

// At time of writing, the order of this enum is important, it is used to sort the course type filter at BrowseFilter.tsx
export enum CourseType {
  Masters = 'MASTERS',
  PostgradCertificate = 'POSTGRAD_CERTIFICATE',
  Microcredential = 'MICROCREDENTIAL',
  FreeCourse = 'FREE_COURSE',
  ShortCourse = 'SHORT_COURSE',
}

export enum Currency {
  Aud = 'AUD',
  Nzd = 'NZD',
  Usd = 'USD',
}

export enum EventPaymentMethod {
  CreditCard = 'CREDIT_CARD',
  Social = 'SOCIAL',
}

export type EventRegisterResponse = {
  eventInstance?: Maybe<SocialEventInstance>
  message: Scalars['String']
  status: EventRegisterResult
}

export enum EventRegisterResult {
  FailAlreadyRegistered = 'FAIL_ALREADY_REGISTERED',
  FailCanNotRegister = 'FAIL_CAN_NOT_REGISTER',
  FailCodeExpired = 'FAIL_CODE_EXPIRED',
  FailCodeInvalidEvent = 'FAIL_CODE_INVALID_EVENT',
  FailCodeNotValid = 'FAIL_CODE_NOT_VALID',
  FailCodeUsed = 'FAIL_CODE_USED',
  FailNotFreeEvent = 'FAIL_NOT_FREE_EVENT',
  FailNotPaidEvent = 'FAIL_NOT_PAID_EVENT',
  FailPayment = 'FAIL_PAYMENT',
  IncompleteRequiresAction = 'INCOMPLETE_REQUIRES_ACTION',
  Success = 'SUCCESS',
}

export enum MarketoDataType {
  Checkbox = 'checkbox',
  Checkboxes = 'checkboxes',
  Currency = 'currency',
  Date = 'date',
  Double = 'double',
  Email = 'email',
  Hidden = 'hidden',
  Htmltext = 'htmltext',
  Number = 'number',
  Phone = 'phone',
  Picklist = 'picklist',
  Radio = 'radio',
  Radiobuttons = 'radiobuttons',
  Range = 'range',
  Select = 'select',
  SingleCheckbox = 'single_checkbox',
  String = 'string',
  Telephone = 'telephone',
  Text = 'text',
  Textarea = 'textarea',
  Url = 'url',
}

export type MarketoFormField = {
  __typename?: 'MarketoFormField'
  columnNumber?: Maybe<Scalars['Int']>
  dataType: MarketoDataType
  fieldMetaData?: Maybe<Scalars['JSON']>
  formPrefill?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  label?: Maybe<Scalars['String']>
  maxLength?: Maybe<Scalars['Int']>
  required?: Maybe<Scalars['Boolean']>
  rowNumber?: Maybe<Scalars['Int']>
  validationMessage?: Maybe<Scalars['String']>
  visibilityRules?: Maybe<Scalars['JSON']>
}

export type Programme = {
  __typename?: 'Programme'
  courseProvider: CourseProvider
  id: Scalars['ID']
  slug: Scalars['String']
  title: Scalars['String']
}

export type PublicCourseEdition = CourseEditionInterface & {
  __typename?: 'PublicCourseEdition'
  canView: Scalars['Boolean']
  course: Course
  dateEnd?: Maybe<Scalars['Date']>
  dateStart?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  overview: Scalars['String']
  slug: Scalars['String']
  title: Scalars['String']
  version: Scalars['Int']
  timeframeDescription?: Maybe<Scalars['String']>
  priceNZDomestic: Scalars['Int']
  priceNZOnshore: Scalars['Int']
  priceAUOnline: Scalars['Int']
  priceAUOnshore: Scalars['Int']
  priceUSOnline: Scalars['Int']
  priceUSOnshore: Scalars['Int']
  openTo: openToType

}

export type SocialEvent = {
  __typename?: 'SocialEvent'
  areaOfInterest?: Maybe<Scalars['String']>
  ctaUrl?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  instances: Array<SocialEventInstance>
  isFree: Scalars['Boolean']
  leadSource?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  location?: Maybe<Scalars['String']>
  marketoFormId?: Maybe<Scalars['String']>
  name: Scalars['String']
  priceAU: Scalars['Int']
  priceNZ: Scalars['Int']
  priceUS: Scalars['Int']
  publishedAt?: Maybe<Scalars['DateTimeTz']>
  registerUrl?: Maybe<Scalars['String']>
  slug: Scalars['String']
  status: SocialEventStatus
  thumbnailUrl?: Maybe<Scalars['String']>
}

export type SocialEventInstance = {
  __typename?: 'SocialEventInstance'
  canRegister: Scalars['Boolean']
  ceremonyStartsAt?: Maybe<Scalars['DateTimeTz']>
  id: Scalars['ID']
  isRegistered: Scalars['Boolean']
  socialEvent: SocialEvent
  startsAt: Scalars['DateTimeTz']
  registrationEndDate?: Maybe<Scalars['DateTimeTz']>
  registrationEndText?: Maybe<Scalars['String']>
}

export enum SocialEventStatus {
  Published = 'published',
  Unpublished = 'unpublished',
}
