import React from 'react'
import { TypeListItem } from '../../types/contentful'
import { Icon } from '../Icon/Icon'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import classNames from 'classnames'

type LeftAlignedListItemProps = {
  listItem: TypeListItem<'WITHOUT_UNRESOLVABLE_LINKS', string>
  index: number | string
  iconContainerClass: string
  iconWrapperClass: string
}

const LeftAlignedListItem = ({
  listItem,
  iconContainerClass,
  iconWrapperClass,
  index,
}: LeftAlignedListItemProps) => {
  return listItem?.fields?.variant === 'v2' ? (
    <div
      className={classNames('py-2.5 px-5 flex-col gap-2.5 flex text-left [&>p]:mb-0')}
      style={{ backgroundColor: listItem.fields.colour }}
    >
      <h3 className="justify-start font-primary text-black text-[21px] leading-loose mb-0">
        {listItem.fields.title}
      </h3>
      {listItem.fields.content && renderDocumentToReactComponents(listItem.fields.content)}
    </div>
  ) : (
    <div className="pt-6" key={`${listItem.sys.id}-${index}`}>
      <div
        className="relative flow-root rounded-lg px-6 pb-8"
        style={{
          backgroundColor: listItem.fields.colour,
        }}
      >
        <div className="absolute rounded-lg left-0 top-0 right-0 bottom-0 z-0 bg-primary-ui-white opacity-90" />
        <div className={iconContainerClass}>
          <span
            className={iconWrapperClass}
            style={{
              backgroundColor: listItem.fields.colour,
            }}
          >
            <Icon
              className="h-6 w-6 text-primary-ui-black"
              aria-hidden="true"
              name={listItem.fields.icon}
            />

            {listItem?.fields?.link && (
              <Icon className={'h-6 w-6 absolute right-0 top-0'} name={'ArrowUpRight'} />
            )}
          </span>
          <h3 className="mt-8 text-lg font-medium tracking-tight mb-5">{listItem.fields.title}</h3>
          {renderDocumentToReactComponents(listItem.fields.content)}
        </div>
      </div>
    </div>
  )
}

export default LeftAlignedListItem
