import { TypeTag } from '../../types/contentful'
import { useEffect, useState } from 'react'
import { Icon } from '../Icon/Icon'

type CategoryDropdownProps = {
  tags?: Array<TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>>
  selectedTag?: TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>
  onSelection: (tag?: TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>) => Promise<void>
  pretext?: string
}
const CategoryDropdown = ({ tags, selectedTag, onSelection, pretext }: CategoryDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const chevronClasses = `ml-1 w-7 inline transition-all relative -left-2 lg:inline`
  const dropdownContainerClass = `absolute right-2 lg:left-1/3 lg:-translate-x-1/3 bg-white drop-shadow mt-2 w-64 z-10 transition-all h-0 overflow-hidden ${
    isOpen ? '!h-fit' : ''
  }`

  const [icon, setIcon] = useState(isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon')

  useEffect(() => {
    setIcon(isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon')
  }, [isOpen])

  return (
    <div className={'relative lg:inline cursor-pointer'}>
      <div
        className="flex flex-row justify-center items-end mb-6 ml-2 lg:m-0 lg:gap-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-col justify-start items-centre lg:flex-row gap-1.5">
          {pretext || 'Explore subjects'}
          <span className={'underline'}>
            {selectedTag ? selectedTag.fields.label : 'choose option'}
          </span>
        </div>

        <Icon name={icon} className={chevronClasses} />
      </div>

      <div className={dropdownContainerClass}>
        <ul className={'list-none font-light font-secondary p-0 m-0 py-5 cursor-pointer'}>
          <li
            className={'text-base text-left border-b-[1px] border-secondary-ui-100 px-5 py-2'}
            onClick={() => {
              setIsOpen(!isOpen)
              onSelection(null)
            }}
          >
            Anything
          </li>

          {tags
            ?.filter(tag => tag?.fields)
            .map((t: TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>) => (
              <li
                key={`${t.fields.slug}-home-filter`}
                className={'text-base text-left px-5 py-2'}
                onClick={() => {
                  setIsOpen(!isOpen)
                  onSelection(t)
                }}
              >
                {t.fields.label}
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default CategoryDropdown
