import { TypeHeroSlide, TypeVideo } from '../../types/contentful'
import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import { getShapeClass } from '../Shapes/Shapes'
import Image from 'next/legacy/image'
import getFocalRatio from '../../helpers/getFocalRatio'
import { Icon } from '../Icon/Icon'
import FullscreenVideo from '../FullscreenVideo/FullscreenVideo'
import PodcastBlock from '../VideoBlock/PodcastBlock'
import { MediaType } from '../../types/types'
import useVideoAnalyticsTracking from '../../helpers/hooks/useVideoAnalyticsTracking'
import classNames from 'classnames'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

type VideoBlockProps = {
  block: TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>
  shape?: TypeHeroSlide<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']['shape'] | 'Cube Masters'
  className: string
  innerClassName?: string
  playerClassName?: string
  showControlBar?: boolean
  priority?: boolean
}

const ThumbnailImitationControlBar = () => {
  const icons = [
    'SpeakerWaveIcon',
    'AdjustmentsVerticalIcon',
    'DocumentMagnifyingGlassIcon',
    'Cog8ToothIcon',
    'ComputerDesktopIcon',
    'ArrowsPointingOutIcon',
  ]

  return (
    <div className="absolute flex justify-center bottom-1 left-[0.8em] right-[0.8em] gap-1">
      <div className="text-white bg-black py-1 px-3 rounded">
        <Icon name={'PlayIcon'} className="w-4 h-4 text-white" />
      </div>
      <div className="w-full bg-black flex flex-row items-center gap-2 md:gap-3 rounded">
        <div className="w-1/3 md:w-[55%] h-1 bg-gray ml-2 rounded" />
        {icons.map((icon, index) => (
          <Icon key={index} name={icon} className="w-4 h-4 text-white" />
        ))}
      </div>
    </div>
  )
}

const VideoSlide = ({
  block,
  shape,
  className,
  innerClassName,
  playerClassName = 'absolute top-0 left-0',
  showControlBar,
  priority,
}: VideoBlockProps) => {
  // Allow custom image to be passed in
  const [fullscreen, setFullscreen] = useState(false)
  const { handlers } = useVideoAnalyticsTracking(
    {
      videoUrl: block.fields.video,
      contentfulId: block.sys.id,
      contentfulTitle: block.fields.title,
    },
    block.fields.isTrackable
  )

  // Omit orphaned slides
  if (!block.fields) {
    return null
  }

  const focalRatio = getFocalRatio(block?.fields?.image?.fields)
  return (
    <>
      {block.fields.variant === MediaType.PODCAST ? (
        <PodcastBlock block={block} />
      ) : block?.fields?.image ? (
        <div
          className={classNames(
            'cursor-pointer relative',
            className,
            getShapeClass('Large Square', shape)
          )}
          id={block?.sys?.id}
          onClick={() => {
            setFullscreen(true)
          }}
        >
          <Image
            src={`https:${block.fields.image.fields.image.fields.file.url}`}
            alt={
              block.fields.image.fields.image.fields.description ||
              block.fields.image.fields.image.fields.title
            }
            layout="fill"
            objectFit="cover"
            objectPosition={`${focalRatio.x}% ${focalRatio.y}%`}
            priority={priority}
          />
          {!showControlBar ? (
            block.fields.playButton !== 'v2' ? (
              <Icon
                name={'Play'}
                className="w-12 h-30 absolute left-[50%] top-[50%] ml-[-30px] mt-[-30px] text-white"
              />
            ) : (
              <div className=" absolute left-[50%] top-[50%] ml-[-30px] mt-[-30px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="73"
                  height="75"
                  viewBox="0 0 73 75"
                  fill="none"
                >
                  <g filter="url(#filter0_d_829_13110)">
                    <path
                      d="M36.5 66.0388C53.897 66.0388 68 51.4794 68 33.5194C68 15.5594 53.897 1 36.5 1C19.103 1 5 15.5594 5 33.5194C5 51.4794 19.103 66.0388 36.5 66.0388Z"
                      fill={block.fields.playButtonColor || '#FF6100'}
                    />
                    <path
                      d="M65.5 33.5194C65.5 50.1743 52.4418 63.5388 36.5 63.5388C20.5582 63.5388 7.5 50.1743 7.5 33.5194C7.5 16.8645 20.5582 3.5 36.5 3.5C52.4418 3.5 65.5 16.8645 65.5 33.5194Z"
                      stroke="white"
                      strokeWidth="5"
                    />
                  </g>
                  <path
                    d="M35.1216 22.6827C33.9134 21.8511 32.3599 21.7736 31.0796 22.481C29.7993 23.1884 29 24.5658 29 26.0649V42.3246C29 43.8238 29.7993 45.2012 31.0796 45.9086C32.3599 46.616 33.9134 46.5384 35.1216 45.7069L46.9341 37.577C48.0295 36.8231 48.6875 35.5539 48.6875 34.1948C48.6875 32.8357 48.0295 31.5665 46.9341 30.8126L35.1216 22.6827Z"
                    fill="white"
                  />

                  <defs>
                    <filter
                      id="filter0_d_829_13110"
                      x="0.14375"
                      y="0.0812497"
                      width="72.7125"
                      height="74.7513"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="3.9375" />
                      <feGaussianBlur stdDeviation="2.42812" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.39 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_829_13110"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_829_13110"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            )
          ) : (
            showControlBar && <ThumbnailImitationControlBar />
          )}
        </div>
      ) : (
        <div className={classNames('flex items-center', className)}>
          <div className={classNames('relative aspect-video w-full', innerClassName)}>
            <ReactPlayer
              className={playerClassName}
              url={block.fields.video}
              controls={true}
              playing={false}
              autoPlay={false}
              width={'100%'}
              height={'100%'}
              {...handlers}
            />
            {/* skeleton loader */}
            <div className="bg-black opacity-5 absolute -z-10 top-0 w-full h-full animate-pulse"></div>
          </div>
        </div>
      )}
      {fullscreen && (
        <FullscreenVideo
          onClose={() => setFullscreen(false)}
          url={block.fields.video}
          isTrackable={block?.fields?.isTrackable}
          id={block.sys.id}
          title={block?.fields?.title}
        />
      )}
    </>
  )
}

export default VideoSlide
