import { TypeButton, TypeImageGridItem, TypeMarketoFormButtonBlock } from '../../types/contentful'
import getFocalRatio from '../../helpers/getFocalRatio'
import Image from 'next/legacy/image'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import { H3 } from '../Typography/Typography'
import ReactMarkdown from 'react-markdown'
import { isContentfulType } from '../../lib/contentful'
import MarketoFormButtonBlock from '../MarketoFormButtonBlock/MarketoFormButtonBlock'
import Link from 'next/link'
import { Icon } from '../Icon/Icon'
import isDarkColor from '../../helpers/isDarkColor'
import { CSSProperties, useState } from 'react'
import classNames from 'classnames'
import remarkBreaks from 'remark-breaks'

type ImageGridProps = {
  item: TypeImageGridItem<'WITHOUT_UNRESOLVABLE_LINKS', string> & {
    fields?: TypeImageGridItem<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields'] & {
      onClick?: () => void
    }
  }
  imageSize: 'Fit' | 'Tall' | 'Square'
  mobileBehavior: 'Vertical' | 'Horizontal'
}

const LinkWrapper = ({ url, children }: { url: string; children: React.ReactNode }) => {
  return url ? (
    <Link href={url} legacyBehavior>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
}

const ImageGridItem = ({ item, imageSize }: ImageGridProps) => {
  const { fields, sys } = item
  const [isHovered, setIsHovered] = useState(false)

  const focalRatio = getFocalRatio(fields?.image?.fields)

  const tabStyle = {
    '--tab-color': fields.tileTabColor,
  } as CSSProperties

  const cornerStyle = {
    // content: '', // this was causing hydration error on local - not sure if this is the cause of the errors on PROD, or if this is needed at all
    position: 'absolute',
    top: 0,
    right: 0,
    borderTop: '25px solid white',
    borderLeft: `25px solid ${fields.tileTabColor}`,
    width: 0,
  } as CSSProperties

  return (
    <LinkWrapper
      url={
        (fields?.ctaButton as TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>)?.fields?.url ||
        fields.slug
      }
      key={sys.id}
    >
      <div
        onClick={fields?.onClick}
        className={classNames(
          'flex flex-col items-center mb-2',
          ((fields?.ctaButton as TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>)?.fields?.url ||
            fields.slug ||
            fields?.onClick) &&
            'cursor-pointer'
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {fields.tileTabTitle && (
          <div
            className="flex items-center justify-center h-[63px] w-full relative bg-[--tab-color]"
            style={tabStyle}
          >
            {fields.tileTabColor && <div style={cornerStyle} />}
            <span
              className={classNames('font-primary text-3xl', {
                'text-white': isDarkColor(fields.tileTabColor),
                'text-black': !isDarkColor(fields.tileTabColor),
              })}
            >
              {fields.tileTabTitle}
            </span>
          </div>
        )}
        {fields?.image?.fields?.image?.fields?.file?.url && imageSize === 'Fit' ? (
          <div
            className={`relative w-full mb-4 flex items-center justify-center scroll-m-[20rem]`}
            id={fields?.image?.sys?.id}
          >
            <Image
              className={classNames('duration-300 transition', isHovered && 'scale-105')}
              src={`https:${fields?.image?.fields?.image?.fields?.file?.url}`}
              alt={fields?.image?.fields?.caption || fields?.image?.fields?.title}
              width={`${fields?.image?.fields?.image?.fields?.file?.details?.image?.width}`}
              height={`${fields?.image?.fields?.image?.fields?.file?.details?.image?.height}`}
            />
          </div>
        ) : fields?.image?.fields?.image?.fields?.file?.url ? (
          <div
            className={`relative w-full mb-4 aspect-[1/1] scroll-m-[20rem] ${
              imageSize === 'Tall' ? 'md:aspect-[1/2]' : ''
            }`}
            id={fields?.image?.sys?.id}
          >
            <Image
              className={classNames('duration-300 transition', isHovered && 'scale-105')}
              src={`https:${fields?.image?.fields?.image?.fields?.file?.url}`}
              alt={fields?.image?.fields?.caption || fields?.image?.fields?.title}
              layout="fill"
              objectFit="cover"
              objectPosition={`${focalRatio?.x}% ${focalRatio?.y}%`}
              // sizes={`20rem`}
            />
          </div>
        ) : null}

        {fields?.title && (
          <H3 size={'3xl'} align={'center'}>
            {fields?.title}
          </H3>
        )}

        {fields?.description && (
          <ReactMarkdown
            remarkPlugins={[remarkBreaks]}
            className={`[&>p>a]:text-[blue] [&>ul]:text-left [&>p>a]:underline [&>p>strong]:text-black [&>p>strong]:text-lg [&>p>p]:mb-[10px] text-secondary-ui-500 [&>p>a]:underline-offset-4 text-center font-normal font-secondary text-[1rem] leading-7 text-balance`}
            linkTarget="_blank"
          >
            {fields.description}
          </ReactMarkdown>
        )}
        {isContentfulType<TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
          fields?.ctaButton,
          'marketoFormButtonBlock'
        ) && <MarketoFormButtonBlock entry={fields?.ctaButton} hsformInstanceId={sys?.id} />}
        {isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
          fields?.ctaButton,
          'button'
        ) &&
          !!fields?.ctaButton?.fields?.url && (
            <div className="mt-auto mb-1">
              <Button
                id={fields?.ctaButton?.sys?.id}
                href={fields?.ctaButton?.fields?.url}
                buttonStyle={contentfulType[fields?.ctaButton?.fields?.style]}
                backgroundColor={fields?.ctaButton?.fields?.color}
                size={contentfulSize[fields?.ctaButton?.fields?.size]}
                variant={contentfulVariant[fields?.ctaButton?.fields?.variant]}
                hoverColor={fields?.ctaButton?.fields?.hoverColor}
                isVideoVariantTrackable={fields?.ctaButton?.fields?.isVideoVariantTrackable}
                textColor={
                  fields?.ctaButton?.fields?.color
                    ? isDarkColor(fields?.ctaButton?.fields?.color)
                      ? 'white'
                      : 'black'
                    : undefined
                }
                className={
                  contentfulType[fields?.ctaButton?.fields?.style] === 'solid' ? 'border-0' : ''
                }
                showOnMobileOnly={fields?.ctaButton?.fields?.showOnMobileOnly}
                mobileWidth={fields?.ctaButton?.fields?.mobileWidth}
              >
                <Icon className="w-5 h-5 mr-2" name={fields?.ctaButton?.fields?.icon} />
                {fields?.ctaButton?.fields?.label}
              </Button>
            </div>
          )}
      </div>
    </LinkWrapper>
  )
}

export default ImageGridItem
