import React from 'react'
import { TypeButton, TypeMarketoFormButtonBlock, TypeTextBlock } from '../../types/contentful'
import TitleBlock from '../TitleBlock/TitleBlock'

type TextBlockProps = {
  entry: TypeTextBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const TextBlock = ({ entry }: TextBlockProps) => {
  return (
    <TitleBlock
      title={entry?.fields?.title}
      preTitle={entry?.fields?.preTitle}
      headingType={'h2'}
      size={entry?.fields?.titleSize || '4xl'}
      preTitleAlignment={entry?.fields?.preTitleAlignment}
      descriptionAlignment={entry?.fields?.contentAlignment}
      titleAlignment={entry?.fields?.titleAlignment}
      description={entry?.fields?.content}
      isQuote={entry?.fields?.isQuote}
      descriptionWidth="Wide"
      titleColor={entry?.fields?.titleColor}
      preTitleColor={entry?.fields?.preTitleColor}
      descriptionColor={entry?.fields?.contentColor}
      contenfulId={entry.sys.id}
      backgroundColor={entry?.fields?.backgroundColor}
      paddingTop={entry?.fields?.paddingTop}
      paddingBottom={entry?.fields?.paddingBottom}
      contentLinkColor={entry?.fields?.contentLinkColor}
      ctaButtons={
        entry?.fields?.ctaButtons as
          | (
              | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
              | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
            )[]
      }
    />
  )
}

export default TextBlock
