import { Context, useContext } from 'react'
import crypto from 'crypto'

export const createContextHook =
  <C>(context: Context<C>, contextName: string, hookName: string) =>
  () => {
    const ctx = useContext(context)
    if (ctx === undefined) {
      throw new Error(`${hookName} must be used within a ${contextName} Provider`)
    }
    return ctx as NonNullable<C>
  }

export const generateLoginURL = (baseURL, redirectURL) => {
  const encodedRedirectURL = encodeURIComponent(redirectURL)
  const encodedBaseURL = encodeURIComponent(baseURL)

  const loginURL = `${baseURL}/api/auth/login/?returnTo=${encodedBaseURL}/redirect?callbackUrl=${encodedRedirectURL}`
  return loginURL
}

export const generatePayloadHash = (payload, key) => {
  const serializedPayload = JSON.stringify(payload)
  const hash = crypto
    .createHash('md5')
    .update(serializedPayload + key)
    .digest('hex')
  return hash
}
