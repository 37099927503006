import { ICourseExtended, IEventExtended } from '../../types/types'
import {
  setDisplayedCourses,
  setSelectedCourse,
  setViewedCourse,
  startCourseCheckout,
} from './course-product-data-layer'
import {
  BROWSE_COURSES_LIST,
  BROWSE_EVENTS_LIST,
  HOME_COURSES_LIST,
} from './model/product-lists.model'
import {
  setDisplayedEvents,
  setSelectedEvent,
  setViewedEvent,
  startEventCheckout,
} from './event-product-data-layer'
import { TypeArticle, TypePerson } from '../../types/contentful'

export type ProductsProps = {
  type: 'home' | 'courses' | 'events' | 'stories' | 'people' | 'blogs' | 'case-studies'
  products: Array<
    | ICourseExtended
    | IEventExtended
    | TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>
  >
  country: string
  region?: string
}

export type ProductProps = {
  type: 'home' | 'courses' | 'events' | 'stories' | 'people' | 'blogs' | 'case-studies'
  index?: number
  product:
    | ICourseExtended
    | IEventExtended
    | TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>
  country: string
  region?: string
  locationType?: string
}

export const setDisplayedProducts = ({ type, products, country, region }: ProductsProps) => {
  switch (type) {
    case 'home': {
      setDisplayedCourses(HOME_COURSES_LIST, products as ICourseExtended[], country, region)
      break
    }
    case 'courses': {
      setDisplayedCourses(BROWSE_COURSES_LIST, products as ICourseExtended[], country, region)
      break
    }
    case 'events': {
      setDisplayedEvents(BROWSE_EVENTS_LIST, products as IEventExtended[], country, region)
      break
    }
    default: {
      break
    }
  }
}

export const setSelectedProduct = ({ type, country, region, index, product }: ProductProps) => {
  switch (type) {
    case 'home': {
      setSelectedCourse(HOME_COURSES_LIST, product as ICourseExtended, country, region, index)
      break
    }
    case 'courses': {
      setSelectedCourse(BROWSE_COURSES_LIST, product as ICourseExtended, country, region, index)
      break
    }
    case 'events': {
      setSelectedEvent(BROWSE_EVENTS_LIST, product as IEventExtended, country, region, index)
      break
    }
    default: {
      break
    }
  }
}

export const setViewProduct = ({ type, country, region, product }: ProductProps) => {
  switch (type) {
    case 'home': {
      setViewedCourse(HOME_COURSES_LIST, product as ICourseExtended, country, region)
      break
    }
    case 'courses': {
      setViewedCourse(BROWSE_COURSES_LIST, product as ICourseExtended, country, region)
      break
    }
    case 'events': {
      setViewedEvent(BROWSE_EVENTS_LIST, product as IEventExtended, country, region)
      break
    }
    default: {
      break
    }
  }
}

export const startProductPurchase = ({
  type,
  country,
  region,
  product,
  locationType,
}: ProductProps) => {
  switch (type) {
    case 'home': {
      startCourseCheckout(HOME_COURSES_LIST, product as ICourseExtended, country, region)
      break
    }
    case 'courses': {
      startCourseCheckout(
        BROWSE_COURSES_LIST,
        product as ICourseExtended,
        country,
        region,
        locationType
      )
      break
    }
    case 'events': {
      startEventCheckout(BROWSE_EVENTS_LIST, product as IEventExtended, country, region)
      break
    }
    default: {
      break
    }
  }
}
