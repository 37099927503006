import React from 'react'
import { TypeArticle, TypeCardContentBlock, TypePerson } from '../../types/contentful'
import { RenderCard } from '../Card/RenderCard'
import { mergeItemFields } from '../../helpers/mergeItemFields'
import { useContentfulResources } from '../ContentfulDocument/ContentfulResourcesProvider'
import { ICourseExtended, IEventExtended } from '../../types/types'

type CardContentBlockProps = {
  entry: TypeCardContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const CardContentBlock = ({ entry }: CardContentBlockProps) => {
  const { events, courses } = useContentfulResources()
  const item = mergeItemFields(entry.fields.item, courses, events)
  return (
    <RenderCard
      item={
        item as
          | TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>
          | TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
          | ICourseExtended
          | IEventExtended
      }
      size="Medium Wide"
      shape={entry.fields.shape || 'Cube'}
    />
  )
}

export default CardContentBlock
