import {
  TypeButton,
  TypeImageWithFocalPoint,
  TypeListItem,
  TypeMarketoFormButtonBlock,
  TypePerson,
  TypeTag,
} from '../../types/contentful'
import Link from 'next/link'
import dayjs from 'dayjs'
import Image from 'next/legacy/image'
import getFocalRatio from '../../helpers/getFocalRatio'
import classnames from 'classnames'
import React from 'react'
import { Icon } from '../Icon/Icon'
import { getShapeClass } from '../Shapes/Shapes'
import { CardShape, CardSize } from '../../types/content'
import { getImageWithFocalPoint } from '../contentful-ui-utils'
import CardTag from './CardTag'
import { renderCTAButtons } from '../CTAButtons/CTAButtons'
import { CourseType } from '../../types/graphql'
import CTAListItem from '../ListContentBlock/CTAListItem'
import ReactMarkdown from 'react-markdown'
import { useRouter } from 'next/router'
import { date } from '../../lib/date'

type CardPropTypes = {
  title: string
  subtitle?: string
  description?: string
  className?: string
  slug?: string
  author?: TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
  readTime?: number
  postedDate?: string
  size?: CardSize
  thumbnailWithFocalPoint?: TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
  cardType?: 'Event' | 'Article' | 'Course' | 'Person'
  tags?: TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>[]
  hideTags?: boolean
  priceFree?: boolean
  price?: string
  currency?: string
  timeframe?: string
  credits?: number
  time?: string
  location?: string
  shape?: CardShape
  thumbnailUrl?: string
  type?: 'Default' | 'Description' | 'Promotion'
  cornersStyle?: 'Rounded' | 'Square'
  listItems?: TypeListItem<'WITHOUT_UNRESOLVABLE_LINKS', string>[]
  hidePrice?: boolean
  button?:
    | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  isComingSoon?: boolean
  backgroundColor?: string
  textColor?: string
  durationDescription?: string
  timeframeIcon?: string
  durationDescriptionIcon?: string
  onCardClick?: () => void
  courseType?: CourseType
  isCaseStudy?: boolean
  eoiOnly?: boolean
  internationalScholarshipInfo?: string
  domesticScholarshipInfo?: string
}

export const contentfulType = {
  Default: 'Default',
  Description: 'Description',
}

const Card = ({
  title,
  subtitle,
  description,
  slug,
  onCardClick,
  author,
  postedDate,
  size = 'Small Square',
  thumbnailWithFocalPoint,
  thumbnailUrl,
  readTime,
  cardType,
  tags,
  hideTags,
  priceFree,
  price,
  currency,
  timeframe,
  location,
  shape,
  type = 'Default',
  cornersStyle,
  button,
  hidePrice,
  isComingSoon = false,
  timeframeIcon = 'Calendar',
  courseType,
  isCaseStudy,
  eoiOnly,
  internationalScholarshipInfo,
  domesticScholarshipInfo,
  listItems,
}: CardPropTypes) => {
  const router = useRouter()
  const scholarshipInfo = slug.includes('?student_type=international')
    ? internationalScholarshipInfo
    : domesticScholarshipInfo

  const combinedDescription = `${description}  \n${scholarshipInfo || ''}`

  const formattedPostedDate = postedDate ? date(postedDate).format('MMM DD, YYYY') : null
  const thumbnailFocalRatio = getFocalRatio(thumbnailWithFocalPoint?.fields)

  const typeData = {
    Event: {
      label: 'Event',
      slug: '/browse/events',
    },
    Course: {
      label: 'Learn',
      slug: '/browse/courses',
    },
    Article: {
      label: isCaseStudy ? 'Case study' : 'Reading',
      slug: `/browse/stories`,
    },
    Person: {
      label: 'People',
      slug: '/browse/people',
    },
  }

  const cardStyle = classnames(
    size === 'Medium Wide' || size === 'Large Square' ? 'sm:col-span-2' : '',
    'flex flex-col gap-4'
  )

  const imageStyle = classnames(
    'relative block w-full',
    getShapeClass(size, shape),
    size === 'Medium Tall' || size === 'Large Square' ? 'h-80 sm:h-[40rem]' : 'h-80'
  )

  const tagStyle = classnames(cornersStyle === 'Square' ? '' : 'rounded-md ')
  const image = getImageWithFocalPoint(
    author?.fields?.hero as TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
  )
  const authorPhotoFocalRatio = getFocalRatio(image?.fields)

  return (
    <>
      <Link href={slug} className={cardStyle} onClick={onCardClick}>
        {/* Start Thumbnail */}
        {(thumbnailWithFocalPoint?.fields?.image?.fields?.file?.url || thumbnailUrl) && (
          <div className={imageStyle}>
            {isComingSoon && (
              <div
                className={`absolute z-10 right-3 top-3 bg-secondary-600 px-3 py-1 bg-[#E0E0E0] ${
                  cornersStyle === 'Square' ? '' : 'rounded-lg'
                }`}
              >
                coming soon
              </div>
            )}
            <Image
              className={`duration-300 hover:scale-105 transition`}
              src={
                thumbnailWithFocalPoint?.fields?.image?.fields?.file?.url
                  ? `https:${thumbnailWithFocalPoint?.fields?.image?.fields?.file?.url}`
                  : thumbnailUrl
              }
              alt={
                thumbnailWithFocalPoint?.fields?.image?.fields?.description ||
                thumbnailWithFocalPoint?.fields?.image?.fields?.title ||
                title
              }
              layout="fill"
              objectFit="cover"
              objectPosition={`${thumbnailFocalRatio.x}% ${thumbnailFocalRatio.y}%`}
              sizes={size === 'Small Square' ? '40rem' : '80rem'}
            />
          </div>
        )}
        {/* End Thumbnail */}

        {/* Title for Description */}
        {type === contentfulType.Description && (
          <div className="flex flex-col">
            <div className="font-primary text-2xl font-bold">{title}</div>

            {!!subtitle && <span className="font-primary">{subtitle}</span>}
          </div>
        )}

        {/* Start Tags */}
        {!hideTags && (
          <div className="flex justify-items-start flex-wrap gap-2">
            {type !== contentfulType.Description && (
              <CardTag
                isPrimeTag
                label={
                  (cardType === 'Article' && tags?.[0]?.fields?.label) || typeData[cardType].label
                }
                tagStyle={tagStyle}
              />
            )}
            {(cardType === 'Article' ? tags?.slice(1) : tags)?.map((tag, index) => {
              return (
                <CardTag
                  key={index}
                  label={tag?.fields?.label}
                  type={type}
                  tagStyle={tagStyle}
                  backgroundColor={tag?.fields?.backgroundColor}
                  textColor={tag?.fields?.textColor}
                />
              )
            })}
          </div>
        )}
        {/* End Tags */}

        {type === contentfulType.Default && (
          <div className="flex flex-col">
            {/* Title for Default */}

            <div className="font-primary text-2xl">{title}</div>
            {/* Subtitle */}
            {!!subtitle && (
              <span className="font-secondary text-base font-extralight">{subtitle}</span>
            )}
          </div>
        )}

        {/* Description */}
        {(isCaseStudy || type === contentfulType.Description) && description && (
          <ReactMarkdown
            className={`text-secondary-ui-700 ${isCaseStudy ? 'text-base' : 'text-sm font-medium'}`}
          >
            {combinedDescription}
          </ReactMarkdown>
        )}

        {listItems &&
          listItems?.map(
            listItem =>
              (listItem.fields.hideOnInternational
                ? !router.asPath.includes('?student_type=international') &&
                  !slug.includes('?student_type=international')
                : true) && (
                <CTAListItem
                  item={listItem}
                  key={listItem?.sys?.id}
                  className={
                    listItem.fields.title === 'Price' &&
                    '!font-medium [&>*>p]:text-sm [&>*>p]:mb-0 [&>*>p>strong]:!text-xl [&>*>p>strong]:!font-medium !w-auto'
                    // TODO: this classNames logic needs to be sorted out once we work out what we are doing with external events, ideally this information should be auto populated via the price in the LMS, possibly via a shell event
                  }
                  parentClassName={cardType === 'Event' && '!justify-start items-center'}
                  iconClassName={cardType === 'Event' && '!w-5 !h-5'}
                />
              )
          )}

        {/* Start Course Info */}
        {timeframe && cardType === 'Event' && (
          <div className="flex">
            <div className={`flex mr-4 flex-row items-start`}>
              <Icon className="w-5 h-5 mr-2 md:mr-1" name={timeframeIcon} />
              {timeframe}
            </div>
          </div>
        )}
        {/* End Course Info */}

        {/* Start Author, Date and Read Time */}
        {!isCaseStudy && (formattedPostedDate || readTime || author) && (
          <div className="flex pb-4">
            {image?.fields?.image?.fields?.file?.url && (
              <div className="relative w-10 h-10 rounded-full overflow-hidden mr-1.5">
                <Image
                  src={`https:${image?.fields?.image?.fields?.file.url}`}
                  alt={
                    image?.fields?.image?.fields.description || image?.fields?.image?.fields.title
                  }
                  layout="fill"
                  objectFit="cover"
                  objectPosition={`${authorPhotoFocalRatio.x}% ${authorPhotoFocalRatio.y}%`}
                  sizes="2.5rem"
                />
              </div>
            )}

            <div>
              {(author?.fields?.firstName || author?.fields?.lastName) && (
                <div className="text-secondary-ui-700 font-medium text-sm">{`${author?.fields?.firstName} ${author?.fields?.lastName}`}</div>
              )}
              <div className="text-secondary-ui-500 font-medium text-sm">
                {formattedPostedDate && <span>{formattedPostedDate}</span>}
                {formattedPostedDate && readTime > 0 && <span> &middot; </span>}
                {readTime > 0 && <span>{`${readTime} min read`}</span>}
              </div>
            </div>
          </div>
        )}
        {/* End Author, Date and Read Time */}

        {isCaseStudy && (
          <div className="flex flex-row items-center hover:underline">
            <p className="m-0 mr-3 text-accent-4-700">Learn more</p>
            <Icon name="ArrowRight" className="w-4 h-4 text-accent-4-700" />
          </div>
        )}

        {type === contentfulType.Default && (
          <>
            {eoiOnly || isComingSoon ? (
              <div className="font-medium">
                <span className="text-xl mr-1">Price on enquiry</span>
              </div>
            ) : (
              <>
                {/** Free prices */}
                {priceFree && !hidePrice && (
                  <div className="font-medium">
                    <span className="text-xl mr-1">Free</span>
                  </div>
                )}

                {/* Start Price */}
                {price && !priceFree && !hidePrice && (
                  <div className="font-medium">
                    {courseType === CourseType.Masters && currency === 'NZD' && !priceFree && (
                      <span className="text-sm font-thin mr-2">UP TO</span>
                    )}
                    <span className="text-xl mr-1">{price}</span>
                    <span className="text-sm">{currency}</span>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {location && (
          <div className="flex items-center mr-4">
            <Icon className="w-5 h-5 mr-1" name="Location" /> {location}
          </div>
        )}
      </Link>
      {type === contentfulType.Description &&
        button &&
        renderCTAButtons({ buttons: [button], slug })}
    </>
  )
}

export default Card
