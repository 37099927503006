import TagManager from 'react-gtm-module'
import { ProductList } from './model/product-list.model'
import { Product, ProductVideo } from './model/product.model'
import { Transaction } from './model/transaction.model'

/**
 * Clear the previous ecommerce object
 */
export const clearProducts = () => {
  const data = {
    dataLayer: {
      ecommerce: null,
    },
  }
  TagManager.dataLayer(data)
}

export const setDisplayedProducts = (list: ProductList, currency: string, items: Product[]) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'view_item_list',
      ecommerce: {
        currency,
        ...list,
        items,
      },
    },
  }
  TagManager.dataLayer(data)
}

export const setSelectedProduct = (list: ProductList, currency: string, item: Product) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'select_item',
      ecommerce: {
        ...list,
        currency,
        items: [item],
        item: item,
      },
    },
  }
  TagManager.dataLayer(data)
}

export const setViewedPage = (pageName: string, id: string, version: string) => {
  if (pageName === '/' || pageName === 'v2') {
    pageName = 'home'
  }

  const data = {
    dataLayer: {
      event: `view_${pageName}_page`,
      ABVariant: {
        version,
      },
    },
  }
  TagManager.dataLayer(data)
}

export const setViewedProduct = (list: ProductList, currency: string, item: Product) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'view_item',
      ecommerce: {
        ...list,
        currency,
        items: [item],
        item: item,
      },
    },
  }
  TagManager.dataLayer(data)
}

export const checkoutStart = (list: ProductList, transaction: Transaction, item: Product) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'begin_checkout',
      ecommerce: {
        ...list,
        ...transaction,
        items: [item],
        item: item,
      },
    },
  }
  TagManager.dataLayer(data)
}

export const hsFormSubmit = (formName: string, formId: string, clearEcommerce: boolean) => {
  const data = {
    dataLayer: {
      event: 'husbspot-form-submitted',
      formName: formName,
      formId: formId,
    },
  }

  TagManager.dataLayer(data)
}

export const checkoutAddPaymentInfo = (
  list: ProductList,
  transaction: Transaction,
  item: Product
) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'begin_checkout',
      ecommerce: {
        ...list,
        ...transaction,
        items: [item],
        item: item,
      },
    },
  }
  TagManager.dataLayer(data)
}

export const checkoutPurchase = (list: ProductList, transaction: Transaction, item: Product) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'purchase',
      ecommerce: {
        ...list,
        ...transaction,
        items: [item],
        item: item,
      },
    },
  }
  TagManager.dataLayer(data)
}

export const checkoutRefund = (list: ProductList, transaction: Transaction, item: Product) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'refund',
      ecommerce: {
        ...list,
        ...transaction,
        items: [item],
        item: item,
      },
    },
  }
  TagManager.dataLayer(data)
}

export const viewVideo = ({ videoUrl, contentfulId, contentfulTitle }: ProductVideo) => {
  const data = {
    dataLayer: {
      event: `video_start`,
      eventModel: {
        video_percent: 0,
        video_url: videoUrl,
        video_contentful_id: contentfulId,
        video_contentful_title: contentfulTitle,
        video_provider: videoUrl.includes('vimeo') ? 'vimeo' : undefined, // YouTube should be tracked by default by GTM so no need to specify
      },
    },
  }
  TagManager.dataLayer(data)
}

export const videoProgress = ({
  videoUrl,
  completePercentage,
  contentfulId,
  contentfulTitle,
}: ProductVideo) => {
  const data = {
    dataLayer: {
      event: `video_progress`,
      eventModel: {
        video_percent: completePercentage,
        video_url: videoUrl,
        video_contentful_id: contentfulId,
        video_contentful_title: contentfulTitle,
        video_provider: videoUrl.includes('vimeo') ? 'vimeo' : undefined, // YouTube should be tracked by default by GTM so no need to specify
      },
    },
  }
  TagManager.dataLayer(data)
}

export const videoComplete = ({ videoUrl, contentfulId, contentfulTitle }: ProductVideo) => {
  const data = {
    dataLayer: {
      event: `video_complete`,
      eventModel: {
        video_percent: 100,
        video_url: videoUrl,
        video_contentful_id: contentfulId,
        video_contentful_title: contentfulTitle,
        video_provider: videoUrl.includes('vimeo') ? 'vimeo' : undefined, // YouTube should be tracked by default by GTM so no need to specify
      },
    },
  }
  TagManager.dataLayer(data)
}
