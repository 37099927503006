import { useCallback, useState } from 'react'

type MarketoButtonChildrenCallback = (handleClick, loading) => JSX.Element

type MarketoButtonProps = {
  preTitle?: string
  title?: string
  marketoEnquiryFormId?: string
  marketoAreaOfInterest?: string
  marketoLeadSource?: string
  children: MarketoButtonChildrenCallback
}

const MarketoButton = ({
  marketoEnquiryFormId,
  marketoAreaOfInterest,
  marketoLeadSource,
  children,
}: MarketoButtonProps) => {
  // Helper loading state to prevent user interaction during loading
  const [loading, setLoading] = useState(false)

  // Callback to load marketo
  const loadMarketo = useCallback(() => {
    if (!process.env.NEXT_PUBLIC_MARKETO_URL || !process.env.NEXT_PUBLIC_MARKETO_MUNCHKIN_ID) {
      setLoading(false)
      return
    }
    window?.MktoForms2?.loadForm(
      process.env.NEXT_PUBLIC_MARKETO_URL,
      process.env.NEXT_PUBLIC_MARKETO_MUNCHKIN_ID,
      marketoEnquiryFormId,
      form => {
        form?.vals({
          Marketo_Form_Name__c: marketoAreaOfInterest,
          LeadSource: marketoLeadSource,
        })
        form?.onSuccess(() => {
          form?.getFormElem()?.hide()
          return false
        })
        window?.MktoForms2?.lightbox(form).show()
        setLoading(false)
      }
    )
  }, [marketoEnquiryFormId, marketoAreaOfInterest, marketoLeadSource])

  // Handle attempts to click form
  const handleClick = () => {
    // Don't let users click immediately after a form has started loading
    if (loading) {
      return false
    }
    setLoading(true)
    loadMarketo()
  }

  // delegate rendering
  return children(handleClick, loading)
}

export default MarketoButton
