import React from 'react'
import {
  TypeButton,
  TypeHeroBlock,
  TypeImageWithFocalPoint,
  TypeMarketoFormButtonBlock,
} from '../../types/contentful'
import isDarkColor from '../../helpers/isDarkColor'
import { CardShapes } from '../../types/content'
import Container from '../Container/Container'
import classNames from 'classnames'
import { isContentfulType } from '../../lib/contentful'
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import HubspotFormButtonBlock from '../MarketoFormButtonBlock/HubspotFormButtonBlock'
import ImageSlide from '../HeroSlider/ImageSlide'

type HeroBlockProps = {
  entry: TypeHeroBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

type TextComponentProps = {
  entry: TypeHeroBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  containerClass?: string
}

export const TextComponent = ({ entry, containerClass = '' }: TextComponentProps) => {
  const {
    title,
    description,
    backgroundColor,
    backgroundShape = CardShapes.Bolt,
    ctaButtons,
    titleFontSize = 'Large',
    hideDescriptionMobile,
    imagePlacement,
    preTitle,
    fontColor, // Title color
  } = entry.fields

  const isDarkClassname = classNames(isDarkColor(backgroundColor) && 'dark')
  const cssVars = {
    '--title-color': fontColor ? fontColor : 'var(--color-text-primary)',
  } as React.CSSProperties

  return (
    <div
      className={classNames(
        'w-full relative flex flex-col justify-center items-center md:min-h-[480px] text-center px-10 md:px-16',
        imagePlacement === 'Left' && 'md:order-2',
        isDarkClassname
      )}
    >
      <div
        className={classNames(
          'w-full h-full absolute -z-10 top-px md:top-0',
          backgroundShape === CardShapes.Bolt && 'bolt-mobile md:bolt-wide'
        )}
        style={{ backgroundColor }}
      />
      <div
        className={classNames(
          'flex flex-col',
          imagePlacement === 'Left' ? 'md:right-6' : 'md:left-6',
          containerClass
        )}
      >
        <div style={cssVars}>
          {preTitle && <p className="text-secondary-ui-600 dark:text-white">{preTitle}</p>}
          {title && (
            <ReactMarkdown
              className={classNames(
                'relative font-primary',
                fontColor ? 'text-[--title-color]' : 'text-[#1C1917] dark:text-white'
              )}
              components={{
                h1: props => (
                  <h1
                    className={classNames({
                      'text-6xl': titleFontSize === 'Large',
                      'text-[32px] lg:text-5xl !leading-[50px] lg:!leading-[60px]':
                        titleFontSize === 'Medium-Large',
                      'text-[40px] leading-[48px]': titleFontSize === 'Medium',
                    })}
                    {...props}
                  />
                ),
                h2: props => (
                  <h2
                    className={classNames({
                      'text-6xl': titleFontSize === 'Large',
                      'text-[32px] lg:text-5xl !leading-[50px] lg:!leading-[60px]':
                        titleFontSize === 'Medium-Large',
                      'text-[40px] leading-[48px]': titleFontSize === 'Medium',
                    })}
                    {...props}
                  />
                ),
              }}
            >
              {title}
            </ReactMarkdown>
          )}
        </div>

        {!!description && (
          <ReactMarkdown
            remarkPlugins={[remarkBreaks]}
            className={classNames(
              '[&>p>a]:text-[blue] [&>p>a]:underline [&>p>a]:underline-offset-4 font-secondary text-[#1C1917] lg:text-[20px] leading-9 lg:leading-10 [&>ul]:list-inside dark:text-white',
              hideDescriptionMobile && 'hidden md:block'
            )}
            linkTarget="_blank"
            components={{
              // Removes margin if it's the last paragraph or the only paragraph
              p: ({ ...props }) => {
                return <p className={'only:m-0 last:m-0'} {...props} />
              },
            }}
          >
            {description}
          </ReactMarkdown>
        )}

        {ctaButtons && (
          <div className="flex item-center justify-center flex-col md:flex-row gap-6 flex-wrap [&>*:first-child]:w-full md:[&>*:first-child]:w-auto">
            {ctaButtons?.map((ctaButton, index) => (
              <div
                key={`ctaButton-${index}`}
                className={index > 0 ? `lg:ml-0 lg:mt-0 ${isDarkClassname}` : undefined}
              >
                {isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                  ctaButton,
                  'button'
                ) &&
                  !!ctaButton?.fields?.url && (
                    <Button
                      id={ctaButton?.sys.id}
                      buttonStyle={contentfulType[ctaButton?.fields?.style]}
                      size={contentfulSize[ctaButton?.fields?.size]}
                      hoverColor={ctaButton?.fields?.hoverColor}
                      className="!h-[2.625rem] !md:h-[3.125rem]"
                      backgroundColor={ctaButton.fields?.color}
                      textColor={isDarkColor(ctaButton.fields?.color) ? 'white' : 'black'}
                      href={ctaButton.fields?.url}
                      variant={contentfulVariant[ctaButton?.fields?.variant]}
                      urlBehaviour={ctaButton.fields?.urlBehaviour}
                      isVideoVariantTrackable={ctaButton?.fields?.isVideoVariantTrackable}
                      showOnMobileOnly={ctaButton?.fields?.showOnMobileOnly}
                      mobileWidth={ctaButton?.fields?.mobileWidth}
                    >
                      {ctaButton.fields.align !== 'Right' && ctaButton.fields.icon && (
                        <Icon className="w-5 h-5 mr-2" name={ctaButton.fields.icon} />
                      )}
                      {ctaButton.fields?.label}
                      {ctaButton.fields.align === 'Right' && ctaButton.fields.icon && (
                        <Icon className="w-5 h-5 ml-2" name={ctaButton.fields.icon} />
                      )}
                    </Button>
                  )}

                {isContentfulType<TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                  ctaButton,
                  'marketoFormButtonBlock'
                ) && (
                  <HubspotFormButtonBlock
                    customClass="!h-[2.625rem] !md:h-[3.125rem]"
                    portalId={ctaButton.fields.portalId}
                    formId={ctaButton.fields.marketoFormId}
                    title={ctaButton.fields.title}
                    buttonLabel={ctaButton.fields.buttonLabel}
                    buttonColor={ctaButton.fields.buttonColor}
                    style={contentfulType[ctaButton?.fields?.style]}
                    textColor={
                      isDarkColor(
                        ctaButton.fields.buttonColor
                          ? ctaButton.fields.buttonColor
                          : backgroundColor
                      )
                        ? 'white'
                        : 'black'
                    }
                    size={contentfulSize[ctaButton?.fields?.size]}
                    icon={ctaButton.fields.icon}
                    buttonHoverColor={ctaButton.fields.buttonHoverColor}
                    formInstanceId={ctaButton.sys.id}
                    showOnMobileOnly={ctaButton?.fields?.showOnMobileOnly}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const HeroBlockV4 = ({ entry }: HeroBlockProps) => {
  const {
    content,
    shape,
    backgroundColor,
    backgroundShape = CardShapes.Bolt,
    imagePlacement,
  } = entry.fields

  const containerClasses = classNames(
    'full-width py-7 lg:py-10',
    backgroundColor && isDarkColor(backgroundColor) && 'text-white dark'
  )

  const ImageComponent = () => {
    return (
      <div
        className={classNames(
          'w-full h-full relative flex flex-col justify-center items-center',
          backgroundShape === CardShapes.Bolt && 'bolt-mobile md:bolt-wide',
          imagePlacement === 'Left' && 'md:order-1'
        )}
        style={{ backgroundColor }}
      >
        {content?.length > 0 && (
          <ImageSlide
            block={content?.[0] as TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>}
            shape={shape}
            className="h-[380px] md:h-[480px] w-full"
          />
        )}
      </div>
    )
  }

  return (
    <Container className={containerClasses} id={entry.sys.id}>
      <div className="relative flex flex-col md:flex-row container mx-auto items-center w-full">
        <div
          className="absolute inset-0 w-1/2 mx-auto hidden md:block"
          style={{ backgroundColor }}
        />
        <TextComponent entry={entry} containerClass="py-12 md:py-0 flex-shrink-0 gap-6 relative" />
        <ImageComponent />
      </div>
    </Container>
  )
}

export default HeroBlockV4
