import React, { useMemo } from 'react'
import { TypeHeroBlock } from '../../types/contentful'
import HeroBlockV2 from './HeroBlockV2'
import HeroBlockDynamic from './HeroBlockDynamic'
import HeroBlockDefault from './HeroBlockDefault'
import HeroBlockV3 from './HeroBlockV3'
import HeroBlockV4 from './HeroBlockV4'

type HeroBlockProps = {
  entry: TypeHeroBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const HeroBlock = ({ entry }: HeroBlockProps) => {
  const { variant } = entry.fields

  const renderVariant = useMemo(() => {
    switch (variant) {
      case 'v4':
        return <HeroBlockV4 entry={entry} />
      case 'v3':
        return <HeroBlockV3 entry={entry} />
      case 'v2':
        return <HeroBlockV2 entry={entry} />
      case 'Dynamic - Text Rotation':
        return <HeroBlockDynamic entry={entry} />
      case 'Default':
      default:
        return <HeroBlockDefault entry={entry} />
    }
  }, [variant, entry])

  return renderVariant
}

export default HeroBlock
