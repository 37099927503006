import classNames from 'classnames'
import React from 'react'

interface CardTagProp {
  label: string
  isPrimeTag?: boolean
  type?: 'Default' | 'Description' | 'Promotion'
  tagStyle?: string
  backgroundColor?: string
  textColor?: string
  islastTag?: boolean
}

const CardTag = ({
  label,
  isPrimeTag = false,
  tagStyle,
  backgroundColor = 'transparent',
  textColor,
}: CardTagProp) => {
  const styles = classNames(
    tagStyle,
    isPrimeTag &&
      'bg-primary-ui-black text-primary-ui-white text-sm px-3 py-0.5 hild transition-all duration-300',
    !isPrimeTag && 'text-sm px-3 py-0.5 transition-all duration-300'
  )

  return (
    <span
      style={{
        background: !isPrimeTag ? backgroundColor : 'black',
        color: !isPrimeTag ? textColor : 'white',
        border: backgroundColor === 'transparent' ? '1px solid black' : '1px solid transparent',
      }}
      className={`${styles}`}
    >
      {label}
    </span>
  )
}

export default CardTag
