import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { Bold, H2, H3, H4, H5, H6, Italic, Underline } from '../components/Typography/Typography'
import Link from 'next/link'
import { Options } from '@contentful/rich-text-react-renderer'
import { Fragment } from 'react'

export const styles = {
  renderMark: {
    underline: 'underline underline-offset-4',
    bold: 'font-bold',
    italic: 'italic',
  },
  renderNode: {
    heading_1: 'font-primary font-bold mb-5 mt-2 md:mt-4',
    heading_2: 'font-primary font-bold mb-4 mt-0',
    heading_3: 'font-primary font-bold mb-3 mt-0',
    heading_4: 'font-primary font-bold mb-2 mt-0',
    heading_5: 'font-primary font-bold mb-1 mt-0',
    heading_6: 'font-primary font-bold mb-0.5 mt-0',
    hyperlink: 'text-accent-4-700 underline underline-offset-4 dark:text-inherit',
  },
}

export const baseOptions = {
  renderMark: {
    [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // Fixes the trailing paragraphs inserted by contentful
      // https://github.com/contentful/rich-text/issues/101
      const isEmptyChildren = (children?.toString() || '').trim() === ''
      if (isEmptyChildren) return null

      return <p>{children}</p>
    },
    // Give heading 1 the same styles as heading 1, but as an h2 to avoid SEO issues
    [BLOCKS.HEADING_1]: (node, children) => <H2 size="5xl">{children}</H2>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
    [BLOCKS.HEADING_6]: (node, children) => <H6>{children}</H6>,
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri} legacyBehavior>
        <a
          className={styles.renderNode.hyperlink}
          target={`${node.data.uri.startsWith('/') ? '_self' : '_blank'}`}
          rel="noreferrer"
        >
          {children}
        </a>
      </Link>
    ),
  },
  renderText: text => {
    return text.split('\n').map((subText, i, arr) => {
      if (subText.trim() === '\\n') return <br key={i} />
      // The idea behind this is: if the previous line is empty and the current line is empty, don't render it the current line;
      // maximum of 1 empty line
      if (arr?.[i - 1]?.trim() === '' && subText.trim() === '') return

      return (
        <Fragment key={i}>
          {i > 0 && <br />}
          {subText}
        </Fragment>
      )
    })
  },
} as Options
