import { gql } from '@apollo/client'

// Core fields queried by all courses
// includes all fields required for display
const COURSE_FIELDS = gql`
  fragment courseFields on Course {
    id
    credits
    ctaUrl
    deliveryMethod
    durationDescription
    imageUrl
    nzqaLevel
    overview
    pricingOptions {
      priceNZDomestic: NZ_DOMESTIC
      priceNZOnshore: NZ_ONSHORE
      priceAUOnline: AU_ONLINE
      priceAUOnshore: AU_ONSHORE
      priceUSOnline: US_ONLINE
      priceUSOnshore: US_ONSHORE
    }
    startType
    slug
    timeframe
    title
    type
  }
`

export const GET_PUBLIC_COURSES = gql`
  query getPublicCourses {
    courses: getPublicCourses {
      publicIntakes {
        id
      }
      ...courseFields
    }
  }
  ${COURSE_FIELDS}
`

export const GET_COURSE = gql`
  query getPublicCourse($slug: String!) {
    course: getPublicCourse(slug: $slug) {
      ...courseFields
      publicIntakes {
        id
        canEnrol
        courseEdition {
          id
          title
          version
          overview
          slug
          canView
          dateStart
          dateEnd
          priceNZDomestic
          priceNZOnshore
          priceAUOnline
          priceAUOnshore
          priceUSOnline
          priceUSOnshore
          openTo
        }
        dateStart
        dateEnd
        enrolUrl
        isEnrolled
        slug
        title
        timeframe
        timeframeDescription
      }
    }
  }
  ${COURSE_FIELDS}
`
