import React from 'react'
import {
  TypeButton,
  TypeHeroBlock,
  TypeImageWithFocalPoint,
  TypeVideo,
} from '../../types/contentful'
import isDarkColor from '../../helpers/isDarkColor'
import { Icon } from '../Icon/Icon'
import TitleBlock from '../TitleBlock/TitleBlock'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import { RWebShare } from 'react-web-share'
import { isContentfulType } from '../../lib/contentful'
import VideoSlide from '../HeroSlider/VideoSlide'
import ImageSlide from '../HeroSlider/ImageSlide'
import useCopyToClipboard from '../../helpers/hooks/useCopyToClipboard'
import Container from '../Container/Container'
import { MediaType } from '../../types/types'
type HeroBlockProps = {
  entry: TypeHeroBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const HeroBlockDefault = ({
  entry: {
    fields: {
      preTitle,
      title,
      backgroundColor,
      iconColor,
      description,
      content,
      ctaButtons,
      showShareLinks,
      icon,
      shape,
      ctaButtonsAlignment,
    },
  },
}: HeroBlockProps) => {
  const copyToClipboard = useCopyToClipboard()

  const isDark = isDarkColor(backgroundColor)

  // Get current url
  const url = typeof window !== 'undefined' ? window.location.href : ''
  const isEnglish: boolean = /[A-Za-z]/.test(title)

  return (
    <Container className={backgroundColor && isDark ? 'dark' : ''}>
      <div
        className={`full-width text-primary-ui-black dark:text-primary-ui-white overflow-x-clip ${
          backgroundColor && isDark ? 'dark' : 'bg-primary-brand'
        } 'mb-[14.5rem] md:mb-[24.5rem] lg:mb-[34.5rem]'`}
        style={{ backgroundColor: backgroundColor || 'transparent' }}
      >
        <div
          className={`absolute w-[20rem] h-[28.5rem] left-1/2 top-1/2 -translate-x-1/2 overflow-visible -translate-y-1/2 ${
            iconColor ? '' : 'text-accent-6-600'
          }`}
          style={{ color: iconColor }}
        >
          <Icon name={icon} />
        </div>
        <div
          className={`relative z-10 container mx-auto flex flex-col ${
            isEnglish ? 'py-44' : 'pt-20 pb-44'
          }`}
        >
          <TitleBlock title={title} preTitle={preTitle}>
            {description && (
              <p className={`max-w-xl text-center mx-auto my-8 text-xl`}>{description}</p>
            )}
            <div
              className={`flex justify-center gap-4 my-8 ${
                ctaButtonsAlignment === 'Vertical' ? 'flex-col items-center' : 'flex-wrap'
              }`}
            >
              {ctaButtons?.map(
                (button: TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>, index) => (
                  <Button
                    href={button.fields.url}
                    id={button.sys.id}
                    key={index}
                    buttonStyle={contentfulType[button.fields.style]}
                    size={contentfulSize[button.fields.size]}
                    title={button.fields.label}
                    variant={contentfulVariant[button?.fields?.variant]}
                    urlBehaviour={button?.fields?.urlBehaviour}
                    isVideoVariantTrackable={button?.fields?.isVideoVariantTrackable}
                    showOnMobileOnly={button?.fields?.showOnMobileOnly}
                    mobileWidth={button?.fields?.mobileWidth}
                  >
                    {button.fields.label}
                    <Icon className="w-5 h-5 ml-1" name={button.fields.icon} />
                  </Button>
                )
              )}
              {showShareLinks === true && (
                <div className={`flex gap-4`}>
                  <Button
                    buttonStyle="outline"
                    size="xl"
                    iconOnly={true}
                    onClick={() => copyToClipboard(url)}
                    title="Copy to clipboard"
                  >
                    <Icon className="w-5 h-5" name="Link" />
                  </Button>
                  <RWebShare
                    data={{
                      text: title,
                      url: url,
                      title: `Share "${title}"`,
                    }}
                    sites={['facebook', 'twitter', 'linkedin', 'whatsapp', 'mail', 'copy']}
                  >
                    <Button buttonStyle="outline" size="xl" iconOnly={true} title="Share course">
                      <Icon className="w-5 h-5" name="Share" />
                    </Button>
                  </RWebShare>
                </div>
              )}
            </div>
          </TitleBlock>
          {isContentfulType<TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
            content?.[0],
            'imageWithFocalPoint'
          ) && (
            <div className={`h-0 overflow-y-visible mb-[10rem]`}>
              <ImageSlide
                block={content?.[0]}
                shape={shape}
                className={'relative h-[20rem] md:h-[30rem] lg:h-[40rem]'}
              />
            </div>
          )}
          {isContentfulType<TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
            content?.[0],
            'video'
          ) &&
            content?.[0].fields.variant === MediaType.VIDEO && (
              <div className={`h-0 overflow-y-visible mb-[10rem]`}>
                <VideoSlide
                  block={content?.[0]}
                  shape={shape}
                  className={'relative w-full h-[20rem] md:h-[30rem] lg:h-[40rem]'}
                />
              </div>
            )}
        </div>
      </div>
    </Container>
  )
}

export default HeroBlockDefault
