import React, { useEffect, useState, ReactNode, useCallback, createContext } from 'react'
import fetch from 'cross-fetch'
import * as Sentry from '@sentry/nextjs'
import { SSR_COUNTRY } from '../../config/locale'
import { LocationResponse } from './LocationResponse'
import createContextHook from '../../lib/createContextHook'
import { getCurrency } from '../../lib/locale'
import { Currency } from '../../types/graphql'

const getStorageItem = (name: string): string | null => {
  if (typeof window === 'undefined') {
    return null
  }
  try {
    const value = window.localStorage?.getItem(name)
    if (value && value !== 'undefined' && value !== 'null') {
      return value
    }
  } catch (error) {
    Sentry.captureException(`Error accessing localStorage item ${name} in LocationProvider:`, error)
  }
  return null
}

interface LocationContextInterface extends LocationResponse {
  loading?: boolean
  currency?: string
  changeCountry?: (country: string) => void
}

const LocationContext = createContext<LocationContextInterface>({})

export const useLocation = createContextHook(
  LocationContext,
  'LocationContextInterface',
  'useLocation'
)

const LocationProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [location, setLocation] = useState<LocationResponse | null>({
    country: SSR_COUNTRY,
  })
  const [currency, setCurrency] = useState<string>(Currency.Nzd)

  const changeCountry = useCallback(
    (country: string) => {
      try {
        const newLocation = { ...location, country }
        window.localStorage.setItem('loc', JSON.stringify(newLocation))
        window.localStorage.setItem('country', country)
        setLocation(newLocation)
      } catch (error) {
        Sentry.captureException(error)
      }
    },
    [location, setLocation]
  )

  useEffect(() => {
    setLoading(true)

    const locationStoredValue = getStorageItem('loc')
    if (locationStoredValue) {
      setLocation(JSON.parse(locationStoredValue))
      setCurrency(getCurrency(location?.country, location?.region))
    }

    const countryStoredValue = getStorageItem('country')
    if (countryStoredValue && !locationStoredValue) {
      const locationData = {
        country: countryStoredValue,
      }
      try {
        window.localStorage.setItem('loc', JSON.stringify(locationData))
        window.localStorage.setItem('country', countryStoredValue)
      } catch (error) {
        Sentry.captureException(error)
      }
      setLocation(locationData)
      setCurrency(getCurrency(location?.country, location?.region))
    }

    fetch(process.env.NEXT_PUBLIC_COUNTRY_DETECTOR || '/api/get-country')
      .then(res => res.json())
      .then((location: LocationResponse) => {
        // Store result if successful
        if (location.country) {
          try {
            window.localStorage.setItem('loc', JSON.stringify(location))
            window.localStorage.setItem('country', location.country)
          } catch (error) {
            Sentry.captureException(error)
          }
          setLocation(location)
          setCurrency(getCurrency(location.country, location.region))
        }
      })
      .catch(error => {
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <LocationContext.Provider value={{ ...location, loading, currency, changeCountry }}>
      {children}
    </LocationContext.Provider>
  )
}

export default LocationProvider
