import { TypeAccordionItem } from '../../types/contentful'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import React, { useCallback, useRef } from 'react'
import { Icon } from '../Icon/Icon'
import useAccordionAnimation from '../../helpers/hooks/useAccordionAnimation'
import { CSSTransition } from 'react-transition-group'

type AccordionBlockItemProps = {
  item: TypeAccordionItem<'WITHOUT_UNRESOLVABLE_LINKS', string>
  openByDefault?: boolean
}

const SPEED = 150

const AccordionBlockItem = ({ item, openByDefault }: AccordionBlockItemProps) => {
  const { open, toggleAnimation } = useAccordionAnimation(openByDefault, SPEED)
  const transitionRef = useRef<HTMLDivElement>(null)

  const handleClick = useCallback(() => {
    toggleAnimation()
  }, [toggleAnimation])

  return (
    <div className="border-t-1 border-accent-4-200 dark:border-primary-ui-white-20">
      <button
        id={item?.sys?.id}
        onClick={handleClick}
        className={'relative block w-full text-left py-5 my-1 font-medium'}
      >
        <p className="w-[95%] m-0">{item?.fields?.title}</p>
        <Icon
          name={open ? 'ToggleUp' : 'ToggleDown'}
          className="absolute right-0 top-3 text-accent-4-800 dark:text-primary-ui-white-50"
        />
      </button>
      <CSSTransition
        in={open}
        timeout={SPEED}
        classNames="accordion"
        unmountOnExit
        nodeRef={transitionRef}
      >
        <div ref={transitionRef}>{renderDocumentToReactComponents(item?.fields?.content)}</div>
      </CSSTransition>
    </div>
  )
}

export default AccordionBlockItem
