import React, { Component, ReactNode } from 'react'
import ErrorBoundaryContent from './ErrorBoundaryContent'
import { withRouter, SingletonRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'

interface ErrorBoundaryProps {
  children: ReactNode
  router: SingletonRouter
}

interface ErrorBoundaryState {
  error: string | null
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error: Error) {
    this.setState({ error: `${error.name}: ${error.message}` })
    Sentry.captureException(error)
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (prevProps.router.asPath !== this.props.router.asPath) {
      // Handle route change (browser back button)
      // TODO: this assumes that the error can be fixed by a route change
      this.setState({ error: null })
    }
  }

  render() {
    const { error } = this.state

    if (error) {
      return <ErrorBoundaryContent />
    }
    return <>{this.props.children}</>
  }
}

export default withRouter(ErrorBoundary)
