import { TypeVideo } from '../../types/contentful'
import dynamic from 'next/dynamic'
import Container from '../Container/Container'
import useVideoAnalyticsTracking from '../../helpers/hooks/useVideoAnalyticsTracking'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

type VideoBlockDefaultProps = {
  block: TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const VideoBlockDefault = ({ block }: VideoBlockDefaultProps) => {
  const { handlers } = useVideoAnalyticsTracking(
    {
      videoUrl: block.fields.video,
      contentfulId: block.sys.id,
      contentfulTitle: block.fields.title,
    },
    block.fields.isTrackable
  )
  // Allow custom image to be passed in
  const light = block.fields?.image?.fields?.image?.fields?.file?.url

  return (
    <Container className="w-full my-6 max-w-[952px] mx-auto">
      <div className="relative aspect-video">
        <ReactPlayer
          allow="autoplay"
          className="absolute top-0 left-0"
          url={block.fields.video}
          muted={block.fields.autoplay}
          controls={true}
          playing={block.fields.autoplay}
          width={'100%'}
          height={'100%'}
          light={light}
          autoPlay={block.fields.autoplay}
          {...handlers}
        />
        <div className="bg-black opacity-5 absolute -z-10 top-0 w-full h-full animate-pulse"></div>
      </div>
    </Container>
  )
}

export default VideoBlockDefault
