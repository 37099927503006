import React from 'react'

const ThemeProvider = () => {
  const aexTheme = {
    // These colors are all from this file:
    // https://www.figma.com/file/n36sKB5bbYuRkJWqIc2LeH/On-Demand?node-id=2701%3A105519&t=5r8uiVEVHjld3jUZ-0
    '--accent-2-50': '#FBFBE9',
    '--accent-2-100': '#F7F8D3',
    '--accent-2-200': '#F4F5C1',
    '--accent-2-700': '#ADAF1D',
    '--accent-2-900': '#56580E',
    '--accent-2-300': '#ADAF1D',
    '--accent-3-50': '#F3F6F6',
    '--accent-3-100': '#E8EDEC',
    '--accent-3-400': '#74908E',
    '--accent-3-600': '#194641',
    '--accent-4-50': '#d2dcf7',
    '--accent-4-100': '#EEF1FC',
    '--as-secondary-ui-50': '#F2F2F2',
    '--as-secondary-ui-400': '#808080',
    '--brand-50': '#FFF7F2',
    '--brand-100': '#FFEFE6',
    '--brand-600': '#FF6100',
    '--brand-900': '#BA4700',
    '--primaryFontFamily': 'Obviously, sans-serif',
    '--secondaryFontFamily': 'EduFavorit, sans-serif',
    '--bg-color': '#FFFFFF',
    '--course-nav-accent': '#004BD5',
    '--color-gray-500': '#666666',

    // These colors are from the old settings method, not figma:
    ' --primaryColor': "''",
    '--secondaryColor': "''",
    '--accentColor': "''",
    '--authBgColor': '#DFE147',
    '--courseBgColor': '#FFDFCC',
    '--eventbBgColor': '#D2DCF7',
    '--primaryFontColor': "''",
    '--secondaryFontColor': "''",
    '--accentFontColor': "''",
  }

  const themeProps = aexTheme
  const theme = Object.entries(themeProps).reduce(
    (acc, [key, value]) => `${acc}\n${key}: ${value};`,
    ''
  )

  return (
    <>
      <style jsx global>{`
        :root {
          ${theme}//current harcoded props
        }
        html {
          color: ${themeProps['--primaryFontColor']};
          font-family: ${themeProps['--secondaryFontFamily']};
        }
      `}</style>
    </>
  )
}

export default ThemeProvider
