import React, { useMemo } from 'react'
import { TypeFeaturedContentBlock } from '../../types/contentful'
import FeaturedContentBlockDefault from './FeaturedContentBlockDefault'
import FeaturedContentBlockQuotes from './FeaturedContentBlockQuotes'
import FeaturedContentBlockFetchContent from './FeaturedContentBlockFetchContent'

type FeaturedContentBlockProps = {
  entry: TypeFeaturedContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']
}

const FeaturedContentBlock = ({ entry }: FeaturedContentBlockProps) => {
  const { variation } = entry

  const renderVariant = useMemo(() => {
    switch (variation) {
      case 'Quotes':
        return <FeaturedContentBlockQuotes {...entry} />
      case 'Latest':
        return <FeaturedContentBlockFetchContent {...entry} />
      default:
        return <FeaturedContentBlockDefault {...entry} />
    }
  }, [variation, entry])

  return renderVariant
}

export default FeaturedContentBlock
