import { TypeAccordion } from '../../types/contentful'
import AccordionBlockItem from './AccordionBlockItem'
import TitleBlock from '../TitleBlock/TitleBlock'
import { fontSize, H3 } from '../Typography/Typography'
import isDarkColor from '../../helpers/isDarkColor'
import { CSSProperties } from 'react'
import classNames from 'classnames'

type AccordionBlockProps = {
  block: TypeAccordion<'WITHOUT_UNRESOLVABLE_LINKS', string>
  position?: 'top' | 'middle' | 'bottom'
  isNested?: boolean
}

const AccordionBlock = ({ block, position, isNested }: AccordionBlockProps) => {
  const { preTitle, title, showTitle, heading, items, backgroundColour, color } = block.fields
  const top = position === 'top',
    middle = position === 'middle',
    bottom = position === 'bottom'

  const accordionColorCSSVars = {
    '--accordion-color': color,
  } as CSSProperties

  const itemClass = classNames('px-6 sm:px-12 lg:px-0', {
    'mx-auto max-w-4xl': !isNested,
    'pt-3 md:pt-6 lg:pt-12': top && !isNested,
    'pt-0': top && isNested,
    'pb-3': bottom,
    'py-3 md:py-6 lg:py-12': !top && !middle && !bottom,
  })

  const containerClass = classNames({
    'full-width': !isNested,
    'text-white dark': backgroundColour && isDarkColor(backgroundColour),
  })

  const headingClass = classNames('lg:bg-[--accordion-color]', {
    'pb-8': !isNested,
  })

  return (
    <div className={containerClass} style={{ backgroundColor: isNested ? '' : backgroundColour }}>
      <div className={`${itemClass}`}>
        {showTitle === true && !isNested && (
          <div className={`mb-5`}>
            <TitleBlock title={title} preTitle={preTitle} headingType={`h2`} size={`5xl`} />
          </div>
        )}
        <div className={headingClass} style={accordionColorCSSVars}>
          {heading && !isNested && (
            <H3 className={`my-0 font-primary font-bold ${fontSize['2xl']}`}>{heading}</H3>
          )}
          <div className={`mt-6`}>
            {items && items.map(item => <AccordionBlockItem key={item.sys.id} item={item} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccordionBlock
