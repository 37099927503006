import { useState } from 'react'
import { videoProgress, viewVideo, videoComplete } from '../../modules/ecommerce/product-data-layer'
import { ProductVideo } from '../../modules/ecommerce/model/product.model'

const useVideoAnalyticsTracking = (video: ProductVideo, isTrackable?: boolean) => {
  const [lastReportedMilestone, setLastReportedMilestone] = useState(0)

  const milestones = [0.1, 0.25, 0.5, 0.75, 1]

  const handlers = isTrackable
    ? {
        onStart: () => {
          viewVideo({
            videoUrl: video.videoUrl,
            contentfulId: video.contentfulId,
            contentfulTitle: video.contentfulTitle,
          })
        },
        onProgress: ({ played }) => {
          for (const [i, milestone] of milestones.entries()) {
            const nextMilestone = milestones[i + 1]
            if (
              played >= milestone &&
              played < nextMilestone &&
              lastReportedMilestone < milestone
            ) {
              if (milestone === 1) {
                videoComplete({
                  videoUrl: video.videoUrl,
                  contentfulId: video.contentfulId,
                  contentfulTitle: video.contentfulTitle,
                })
              } else {
                videoProgress({
                  videoUrl: video.videoUrl,
                  completePercentage: milestone * 100,
                  contentfulId: video.contentfulId,
                  contentfulTitle: video.contentfulTitle,
                })
              }
              setLastReportedMilestone(milestone)
              break
            }
          }
        },
      }
    : {}

  return { lastReportedMilestone, setLastReportedMilestone, milestones, handlers }
}

export default useVideoAnalyticsTracking
