import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

type HeadBlockProps = {
  noIndex?: boolean
  title?: string
  keywords?: string
  description?: string
  image?: string
  canonicalUrl?: string
  jsonLd?: object
}

const HeadBlock = ({
  noIndex = false,
  title,
  keywords,
  description,
  image,
  canonicalUrl,
  jsonLd,
}: HeadBlockProps) => {
  const router = useRouter()
  const href =
    process.env.NEXT_PUBLIC_BASE_URL &&
    router.asPath &&
    `${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath}`
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {noIndex && <meta name="robots" content="noindex, nofollow" />}
      {!!title && <title>{title}</title>}
      {!!title && <meta property="title" content={`${title}`} />}
      {!!keywords && <meta name="keywords" content={`${keywords}`} />}
      {!!description && <meta name="description" content={`${description}`} />}
      {!!canonicalUrl && (
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_BASE_URL}/${canonicalUrl}`} />
      )}

      <meta property="og:site_name" content="academyEX" />
      {!!title && <meta property="og:title" content={`${title}`} />}
      {!!description && <meta property="og:description" content={`${description}`} />}
      {!!image && <meta property="og:image" content={`https:${image}`} />}
      {!!href && <meta property="og:url" content={href} />}

      {!!title && <meta name="twitter:title" content={`${title}`} />}
      {!!description && <meta name="twitter:description" content={`${description}`} />}
      {!!image && <meta name="twitter:image" content={`https:${image}`} />}
      {!!image && <meta name="twitter:card" content={`https:${image}`} />}

      {jsonLd && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({ '@context': 'https://schema.org', ...jsonLd }),
          }}
        />
      )}
    </Head>
  )
}

export default HeadBlock
