import React, { Dispatch, SetStateAction, useEffect, useCallback } from 'react'
import Button from '../Button/Button'
import { useForm } from 'react-hook-form'
import { TypeSubscribeNewsletterBlock } from '../../types/contentful'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import { H2 } from '../Typography/Typography'
import isDarkColor from '../../helpers/isDarkColor'
import { HubspotEmbeddedForm } from '../MarketoFormButtonBlock/HubspotEmbeddedForm'
import Container from '../Container/Container'
import classNames from 'classnames'

type SubscribeNewsletterBlockProps = {
  entry: TypeSubscribeNewsletterBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

type MarketoForm = {
  onSuccess: Dispatch<SetStateAction<any>> // eslint-disable-line @typescript-eslint/no-explicit-any
  getId: () => string
  vals: (data: object) => object
  submit: () => void
  getValues: () => void
}

const Error = ({ message }: { message: string }) => (
  <p role={`alert`} className="mt-2 mb-0 text-sm text-primary-brand">
    {message}
  </p>
)

const SubscribeNewsletterBlock = ({ entry }: SubscribeNewsletterBlockProps) => {
  const {
    title,
    description,
    footerText,
    backgroundColor,
    marketoFormId,
    placeholderText,
    buttonText,
    submitSuccessMessage,
    theme,
    formType,
    portalId,
    blockColor,
  } = entry.fields

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [hasSubmittedSuccessfully, setHasSubmittedSuccessfully] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)

  // If there is no theme defined, background color will determine the theme
  const themeClass = theme
    ? theme === 'Dark'
      ? 'dark'
      : ''
    : !isDarkColor(backgroundColor)
    ? 'dark'
    : ''

  const isDarkBlockColor = isDarkColor(blockColor) ? 'dark' : ''

  const formFieldData = {
    id: 'Email',
    label: 'Email Address:',
    dataType: 'email',
    validationMessage:
      "Must be valid email. <span class='mktoErrorDetail'>example@yourdomain.com</span>",
    rowNumber: 0,
    columnNumber: 0,
    maxLength: null,
    required: false,
    formPrefill: true,
    fieldMetaData: null,
    visibilityRules: {
      ruleType: 'alwaysShow',
    },
    __typename: 'MarketoFormField',
  }

  const handleMarketoSubmit = data => {
    // Submit the marketo form
    window.MktoForms2?.whenReady(form => {
      const formID: string = form.getId()

      if (formID.toString() === marketoFormId.toString()) {
        form.vals(data)
        setSubmitting(true)
        form.submit()
      }
    })
  }

  const loadMarketo = useCallback(
    () =>
      window?.MktoForms2?.loadForm(
        process.env.NEXT_PUBLIC_MARKETO_URL,
        process.env.NEXT_PUBLIC_MARKETO_MUNCHKIN_ID,
        marketoFormId,
        (form: MarketoForm) => {
          form?.onSuccess(() => {
            setHasSubmittedSuccessfully(true)
            setSubmitting(false)
            return false
          })
        }
      ),
    [marketoFormId]
  )

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_MARKETO_URL &&
      process.env.NEXT_PUBLIC_MARKETO_MUNCHKIN_ID &&
      formType === 'Marketo'
    ) {
      loadMarketo()
    }
  }, [loadMarketo])

  if (
    formType === 'Marketo' &&
    (!process.env.NEXT_PUBLIC_MARKETO_URL || !process.env.NEXT_PUBLIC_MARKETO_MUNCHKIN_ID)
  ) {
    return null
  }

  return (
    <Container className={`${themeClass}`}>
      <div className="full-width py-10 px-4 xl:px-20" style={{ background: `${backgroundColor}` }}>
        <div
          style={{ background: `${blockColor}` }}
          className={classNames(
            'bg-white min-h-[18rem] relative container mx-auto flex flex-col md:flex-row gap-4 md:gap-16 p-6 md:p-10',
            {
              'bg-white dark:bg-[#000]': !blockColor,
              'text-primary-ui-black dark:text-primary-ui-white': !blockColor,
              'text-primary-ui-white': isDarkBlockColor,
              'text-primary-ui-black': !isDarkBlockColor,
            }
          )}
        >
          <div
            className={`absolute left-0 bottom-0 w-0 h-0 border-t-transparent border-r-transparent border-l-transparent`}
            style={{
              borderStyle: 'solid',
              borderWidth: '0 4rem 4rem 0',
              borderBottomColor: `${backgroundColor}`,
            }}
          />
          <div
            className={classNames(
              !!description && 'mb-10',
              formType !== 'Marketo' &&
                'flex flex-col justify-center lg:block w-full md:w-1/2 lg:w-full'
            )}
          >
            <H2 size={`6xl`}>{title}</H2>
            {description && (
              <div className={`mx-auto mt-8 font-normal md:ml-8`}>
                {renderDocumentToReactComponents(description)}
              </div>
            )}
          </div>
          {formType === 'Marketo' && (
            <div className={`md:max-w-[33%] flex flex-col flex-shrink-0 justify-around`}>
              {hasSubmittedSuccessfully ? (
                <p className={`text-center m-0 md:h-[7rem] md:pt-[2rem]`}>
                  {submitSuccessMessage ?? 'Thank you for your subscription.'}
                </p>
              ) : (
                <form onSubmit={handleSubmit(handleMarketoSubmit)} data-lpignore="true">
                  <div className="mt-1 flex flex-col sm:col-span-2 sm:mt-0">
                    <input
                      className={classNames(
                        'block w-full text-primary-ui-black py-3.5 mb-3 max-w-lg rounded-md border-secondary-ui-200 shadow-sm focus:border-primary-brand focus:ring-primary-brand sm:text-sm',
                        formFieldData.dataType !== 'email' && 'sm:max-w-xs'
                      )}
                      type={formFieldData.dataType}
                      id={formFieldData.id}
                      name={formFieldData.id}
                      placeholder={placeholderText ?? 'Enter your email'}
                      {...register(formFieldData.id, {
                        required: formFieldData.required ? 'This field is required' : false,
                      })}
                    />
                    {errors?.[formFieldData.id] && (
                      <Error message={`${errors?.[formFieldData.id]?.message || 'Error'}`} />
                    )}
                    <Button type="submit" size={`xl`}>
                      {submitting ? 'Submitting...' : `${buttonText ?? 'Sign up'}`}
                    </Button>
                  </div>
                </form>
              )}
              <div className="text-center mt-8 md:mt-0 text-sm max-w-xs mx-auto md:pt-10">
                {renderDocumentToReactComponents(footerText)}
              </div>
              <div className={`hidden`}>
                <form id={`mktoForm_${marketoFormId}`} />
              </div>
            </div>
          )}
          {formType === 'Hubspot' && (
            <div className="lg:w-1/2">
              <HubspotEmbeddedForm
                portalId={portalId}
                formId={marketoFormId}
                title={''}
                formInstanceId={entry.sys.id}
              />
              <div className="text-center text-sm mx-auto mt-6 [&>p]:mb-0 text-balance">
                {renderDocumentToReactComponents(footerText)}
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export default SubscribeNewsletterBlock
