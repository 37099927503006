import React from 'react'
import { Icon } from '../Icon/Icon'
import classNames from 'classnames'
import { TypeListItem } from '../../types/contentful'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
const PDFViewerWithNoSSR = dynamic(import('../PDFViewer/PDFViewer'), { ssr: false })

type CTAListItemProps = {
  item: TypeListItem<'WITHOUT_UNRESOLVABLE_LINKS', string>
  parentClassName?: string
  className?: string
  iconClassName?: string
}

const CTAListItem = ({ item, parentClassName, className, iconClassName }: CTAListItemProps) => {
  const router = useRouter()
  const cssVars = {
    '--icon-color': `${item.fields.iconColor}`,
    '--text-color': `${item.fields.colour}`,
  } as React.CSSProperties

  if (item.fields.hideOnInternational && router.asPath.includes('?student_type=international')) {
    return null
  }
  return (
    <div
      className={classNames(
        'flex gap-1 justify-center text-center md:text-left',
        !item.fields?.icon && 'md:block',
        parentClassName
      )}
      key={item?.sys?.id}
      id={item?.sys?.id}
      style={cssVars}
    >
      <Icon
        className={classNames('w-6 h-6', iconClassName)}
        aria-hidden="true"
        name={item.fields.icon}
        textColor={item.fields.iconColor}
      />

      <div
        className={classNames(
          'flex flex-col md:block w-full relative',
          item.fields?.icon
            ? 'items-start lg:items-center [&>*]:text-left [&>p]:mb-2'
            : 'items-center',
          className
        )}
      >
        {/* <h2 className="text-[--text-color]">{listItem?.fields?.title}</h2> */}
        <div className="[&>p:last-child]:mb-0 [&>p:only-child]:my-[.15rem] [&>p:only-child]:mt-[.15rem]">
          {renderDocumentToReactComponents(item.fields.content)}
          {item?.fields?.link && <PDFViewerWithNoSSR file={item?.fields?.link} />}
        </div>
      </div>
    </div>
  )
}

export default CTAListItem
