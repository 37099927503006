import { TypeCourse, TypeEvent, TypeHomeCoursesBlock, TypeTag } from '../../types/contentful'
import CategoryDropdown from './CategoryDropdown'
import Link from 'next/link'
import { getCourses, getEvents } from '../../lib/contentful'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from '../../context/Location/LocationProvider'
import { RenderCard } from '../Card/RenderCard'
import shuffleArray from '../../helpers/arrayRandomise'
import { SSR_LOCALE } from '../../config/locale'
import { useContentfulClient } from '../../context/ContentfulClient/ContentfulClientContext'
import { mergeManyItemFields } from '../../helpers/mergeItemFields'
import { useContentfulResources } from '../ContentfulDocument/ContentfulResourcesProvider'
import { Course, SocialEvent } from '../../types/graphql'
import { ICourseExtended, IEventExtended } from '../../types/types'
import { CourseType } from '../../types/graphql'
import {
  setDisplayedProducts,
  setSelectedProduct,
} from '../../modules/ecommerce/product-data-layer.handler'
import useLocalisedContent from '../../context/Location/useLocalisedContent'
import Container from '../Container/Container'
import { Swiper, SwiperSlide } from 'swiper/react'
import type SwiperCore from 'swiper'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import { buildSwiperDefaultProps } from '../slider.constants'
import { Icon } from '../Icon/Icon'

type HomePageCoursesProps = {
  entry: TypeHomeCoursesBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}
const HomePageCourses = ({ entry }: HomePageCoursesProps) => {
  const [selectedTag, setSelectedTag] = useState<TypeTag<
    'WITHOUT_UNRESOLVABLE_LINKS',
    string
  > | null>(null)
  const [cards, setCards] = useState<(ICourseExtended | IEventExtended)[]>([])
  const { courses, events } = useContentfulResources()

  const localizedCourses = useLocalisedContent(cards)
  const contentfulClient = useContentfulClient()
  const swiperRef = useRef<SwiperCore>()

  const handleSelection = async (tag?: TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>) => {
    const getEventCards = async () => {
      const eventsResult = await getEvents(contentfulClient, tag ? [tag?.fields?.slug] : [], true)
      const contentfulEvents = events.filter(
        (event: SocialEvent) =>
          eventsResult.map(e => e.fields.slug[SSR_LOCALE]).includes(event.slug) &&
          event?.instances?.length
      )

      const filteredEvents = eventsResult.filter(
        (event: TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>) =>
          contentfulEvents.map(e => e.slug).includes(event.fields.slug[SSR_LOCALE])
      )

      return shuffleArray(mergeManyItemFields(filteredEvents, [], contentfulEvents)).slice(0, 4)
    }

    const getCourseCards = async () => {
      const courseResults = await getCourses(contentfulClient, tag ? [tag?.fields?.slug] : [])
      const contentfulCourses = courses.filter(
        (course: Course) =>
          courseResults.map(c => c.fields.slug[SSR_LOCALE]).includes(course.slug) &&
          course?.publicIntakes?.length &&
          !course.title.includes('Sampler') &&
          course.type !== CourseType.FreeCourse
      )

      const filteredCourses = courseResults.filter(
        (course: TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>) =>
          contentfulCourses.map(c => c.slug).includes(course.fields.slug[SSR_LOCALE])
      )

      return shuffleArray(mergeManyItemFields(filteredCourses, contentfulCourses, [])).slice(0, 4)
    }

    if (tag === null && entry?.fields?.defaultFeaturedCards?.length > 0) {
      setSelectedTag(null)

      const defaultFeaturedCards = entry?.fields?.defaultFeaturedCards
      const defaultFeaturedCardsSlugs = defaultFeaturedCards.map(c => c?.fields?.item?.fields?.slug)

      const matchingSlugContentfulCourses = courses.filter(course =>
        defaultFeaturedCardsSlugs.includes(course?.slug)
      )
      const matchingSlugContentfulEvents = events.filter(event =>
        defaultFeaturedCardsSlugs.includes(event?.slug)
      )

      const filteredFeaturedContentBlockItems = defaultFeaturedCards.filter(
        featuredContentBlockItem =>
          [...matchingSlugContentfulCourses, ...matchingSlugContentfulEvents]
            .map(courseOrEvent => courseOrEvent?.slug)
            .includes(featuredContentBlockItem?.fields?.item?.fields?.slug)
      )

      const filteredCoursesAndEvents = filteredFeaturedContentBlockItems.map(
        filteredFeaturedContentBlockItem => filteredFeaturedContentBlockItem?.fields?.item
      ) as (
        | TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>
        | TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>
      )[]

      const mergedCoursesAndEvents = mergeManyItemFields(
        filteredCoursesAndEvents,
        matchingSlugContentfulCourses,
        matchingSlugContentfulEvents
      ) as (ICourseExtended | IEventExtended)[]

      if (filteredCoursesAndEvents.length < 4) {
        const extraCourses = (await getCourseCards())
          .filter(
            (course: TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>) =>
              !mergedCoursesAndEvents.some(card => card?.fields?.slug === course?.fields?.slug)
          )
          .slice(0, 4 - filteredCoursesAndEvents.length)

        mergedCoursesAndEvents.push(...extraCourses)
      }

      mergedCoursesAndEvents.forEach(card => {
        card.fields.size = 'Small Square'
      })

      setCards(mergedCoursesAndEvents)
      return
    }

    let shuffledCards = []

    setSelectedTag(tag)

    if (entry.fields?.showEvent && entry.fields?.showCourses) {
      // if both are selected, show 3 courses and 1 event
      const courses = await getCourseCards()
      const events = await getEventCards()
      shuffledCards =
        events.length > 0 ? shuffleArray([...courses.slice(0, 3), events[0]]) : courses
    } else if (entry.fields?.showCourses && !entry.fields?.showEvent) {
      const courses = await getCourseCards()
      shuffledCards = courses
    } else if (entry.fields?.showEvent && !entry.fields?.showCourses) {
      const events = await getEventCards()
      shuffledCards = events
    } else {
      // if neither are selected, show courses
      const courses = await getCourseCards()
      shuffledCards = courses
    }

    shuffledCards.forEach(card => {
      card.fields.size = {
        [SSR_LOCALE]: 'Small',
      }

      card.fields.shape = {
        [SSR_LOCALE]: 'Square',
      }
    })

    setCards(shuffledCards)
  }

  useEffect(() => {
    handleSelection(null)
  }, [])

  const { country, region } = useLocation()

  useEffect(() => {
    setDisplayedProducts({
      type: 'home',
      products: localizedCourses,
      country,
      region,
    })
  }, [country, region, localizedCourses])

  return (
    <Container
      className={'full-width px-12 py-10'}
      style={{
        backgroundColor: entry?.fields?.backgroundColour,
      }}
    >
      <div className="mx-auto max-w-[90rem]">
        <div className={'absolute top-0 left-0 bottom-16 sm:bottom-6 right-0 -z-10'} />
        <h3 className={'w-full text-center text-3xl md:text-4xl font-bold font-primary'}>
          <CategoryDropdown
            pretext={entry?.fields?.pretext || 'Explore subjects'}
            tags={entry?.fields?.tags}
            selectedTag={selectedTag}
            onSelection={handleSelection}
          />
        </h3>
        <div className={`hidden lg:flex overflow-hidden gap-x-8 justify-center mt-12`}>
          {localizedCourses &&
            localizedCourses.map((item, index) => {
              return (
                <div className="w-[20rem]" key={index}>
                  <RenderCard
                    key={`${item.sys.id}`}
                    item={item}
                    onCardClick={() =>
                      setSelectedProduct({ type: 'home', country, index, product: item })
                    }
                  />
                </div>
              )
            })}
        </div>

        {/* For mobile/tablet */}
        <div className="container mx-auto">
          <div className="lg:hidden">
            <Swiper
              {...buildSwiperDefaultProps({
                horizontalClass:
                  'absolute !flex justify-center z-40 top-5 left-1/2 transform -translate-x-1/2 !h-fit',
              })}
              onBeforeInit={swiper => {
                swiperRef.current = swiper
              }}
              slidesPerView={'auto'}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  ...(localizedCourses?.length % 2 === 0
                    ? { slidesPerGroup: 2 }
                    : { slidesPerGroupAuto: true }),
                },
              }}
              effect="slide"
              key={`swiper-featured-content-block-${entry?.fields?.title}`}
              loop={false}
            >
              {localizedCourses?.map((item, index) => {
                return (
                  <SwiperSlide key={index} className="mt-14 mb-5">
                    <RenderCard
                      item={item}
                      onCardClick={() =>
                        setSelectedProduct({ type: 'home', country, index, product: item })
                      }
                      size="Small Square"
                    />
                  </SwiperSlide>
                )
              })}
              <Icon
                name="ArrowLeft"
                className="cursor-pointer absolute top-2 left-1/2 -translate-x-14 z-50"
                onClick={() => swiperRef.current?.slidePrev()}
              />
              <Icon
                name="ArrowRight"
                className="cursor-pointer absolute top-2 left-1/2 translate-x-10 z-50"
                onClick={() => swiperRef.current?.slideNext()}
              />
            </Swiper>
          </div>
        </div>
        <div className={'flex w-full justify-center items-center mt-[3rem]'}>
          <Link
            href={`/browse/courses${selectedTag ? `/${selectedTag.fields.slug}` : ''}`}
            legacyBehavior
          >
            <div
              className={
                'py-2 px-4 rounded-full bg-secondary-ui-900 text-white font-secondary cursor-pointer hover:opacity-75'
              }
            >
              Show me more
            </div>
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default HomePageCourses
