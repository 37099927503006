import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

interface Word {
  word: string
  id?: string
}

interface InfiniteVerticalScrollProps {
  words: Word[]
  color?: string
}

const InfiniteVerticalScroll = ({ words, color }: InfiniteVerticalScrollProps) => {
  const style = {
    '--font-color': color || '#fff',
  } as React.CSSProperties

  const [isMobile, setIsMobile] = useState(() =>
    typeof window !== 'undefined' ? window.innerWidth <= 1200 : false
  )
  const [isAnimated, setIsAnimated] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200)
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  const [activeIndex, setActiveIndex] = useState(0)

  // Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(words.length / 2)

  // Usd to determine the height/spacing of each item
  const itemHeight = isMobile ? 60 : 80

  // Used to determine at what point an item is moved from the top to the bottom
  const shuffleThreshold = halfwayIndex * itemHeight

  // Used to determine which items should be visible. this prevents the "ghosting" animation
  const visibleStyleThreshold = shuffleThreshold / 2

  const determinePlacement = itemIndex => {
    // If these match, the item is active
    if (activeIndex === itemIndex) return 0

    if (itemIndex >= halfwayIndex) {
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemHeight
      } else {
        return -(words.length + activeIndex - itemIndex) * itemHeight
      }
    }

    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemHeight
    }

    if (itemIndex < activeIndex) {
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (words.length - (activeIndex - itemIndex)) * itemHeight
      }

      return -(activeIndex - itemIndex) * itemHeight
    }
  }

  const handleNextWord = direction => {
    setActiveIndex(prevIndex => {
      if (direction === 'next') {
        // Increment active index and use modulo to wrap around at the end of the array
        return (prevIndex + 1) % words.length
      } else {
        // Decrement active index and wrap around to the end of the array if at the beginning
        return prevIndex === 0 ? words.length - 1 : prevIndex - 1
      }
    })
  }

  useEffect(() => {
    let interval = null

    if (isAnimated) {
      interval = setInterval(() => {
        handleNextWord('next')
      }, 2000)
    } else if (!isAnimated && interval) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [isAnimated])

  return (
    <div
      className={classnames('flex items-center gap-[1rem] cursor-pointer', {
        'flex-row static justify-end top-[6.58rem] lg:left-[5.2vw]': !isMobile,
        'flex-col relative justify-start top-11': isMobile,
      })}
      onClick={() => setIsAnimated(!isAnimated)}
      style={style}
    >
      <div className="font-primary text-[2.5rem] leading-[3rem] md:leading-[4.5rem] lg:text-6xl md:ml-2">
        Find your
      </div>
      <div className="font-primary -mt-4 text-[4.9rem] leading-[90px] md:text-[125px] md:leading-[100px] text-[--font-color] font-bold">
        EX
      </div>

      <div
        className={classnames('relative flex-1 flex lg:mr-12', {
          'justify-start h-[29.3rem] max-w-[22rem] self-start': !isMobile,
          'justify-center h-[9.375rem] w-[8.75rem] self-center': isMobile,
        })}
      >
        {words.map((item, i) => (
          <span
            className={classnames('carousel-item font-primary text-[--font-color]', {
              'top-[12.7rem] text-6xl': !isMobile,
              'top-[7.3rem] text-[2.5rem]': isMobile,
              active: isMobile
                ? (activeIndex - 2 + words.length) % words.length === i
                : activeIndex === i,
              visible: !isMobile
                ? Math.abs(determinePlacement(i)) <= visibleStyleThreshold
                : Math.abs(determinePlacement(i)) <= 60,
            })}
            key={i}
            style={{
              transform: `translateY(${determinePlacement(i)}px)`,
            }}
          >
            {item.word}
          </span>
        ))}
      </div>
    </div>
  )
}

export default InfiniteVerticalScroll
