import Link from 'next/link'
import { TypeNavigationMenu } from '../../types/contentful'
import { Icon } from '../Icon/Icon'
import React from 'react'

const FooterSocialIcons = ({
  menu,
}: {
  menu: TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>
}) => {
  return (
    <ul className={'p-0 list-none flex flex-row gap-4 !ml-0'}>
      {menu?.fields?.items?.map(item => (
        <li key={item.sys.id}>
          <Link href={item.fields.link} target="_blank" rel="external noopener noreferrer">
            <Icon name={item.fields.icon} className="w-7 h-6" />
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default FooterSocialIcons
