import { TypeNavigationMenu } from '../../types/contentful'
import { Icon } from '../Icon/Icon'
import React from 'react'

const SocialIcons = ({
  menu,
}: {
  menu: TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>
}) => {
  return (
    <ul className="h-9 mt-auto flex flex-row p-0 m-0 list-none justify-center space-x-3">
      {menu.fields.items.map(item => (
        <li key={item.sys.id} id={item.sys.id}>
          <div className="mr-3">
            <a href={item.fields.link} target="_blank" rel="external noopener noreferrer">
              <Icon
                name={item.fields.icon}
                className="w-5 h-5 text-primary-ui-black hover:text-accent-4-700"
              />
            </a>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default SocialIcons
