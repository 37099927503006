import Button from '../Button/Button'
import { Icon } from '../Icon/Icon'
import Image from 'next/legacy/image'
import Error404Image from '../../../public/404.png'
import { TypeButton, TypeCustom404Block } from '../../types/contentful'
import ReactMarkdown from 'react-markdown'
import { renderCTAButtons } from '../CTAButtons/CTAButtons'
import { isAcademyExAu } from '../../lib/detectEnvironment'

const Custom404Block = ({
  h2 = 'Oops.',
  h3 = 'We can’t find the page you’re looking for.',
  description,
  ctaButtons,
}: TypeCustom404Block<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']) => {
  return (
    <div className="flex flex-col md:flex-row gap-10 items-start justify-center mx-10 md:mx-0 mt-navbar py-20">
      <figure className="h-72 w-72 relative tab flex-shrink-0">
        <Image
          src={Error404Image}
          layout="fill"
          objectFit="cover"
          alt="404 Image - woman hiding behind book"
        />
      </figure>

      <div className="flex flex-col justify-start items-start gap-8">
        <header className={`font-primary text-primary-brand-orange`}>
          <h2 className="text-5xl md:text-6xl font-extrabold leading-10 mb-6">{h2}</h2>
          <h3 className="text-2xl md:text-3xl font-extrabold leading-9">{h3}</h3>
        </header>
        {description ? (
          <ReactMarkdown className={'text-lg md:text-xl font-normal leading-1 md:leading-9 mb-0'}>
            {description}
          </ReactMarkdown>
        ) : (
          <p className="text-lg md:text-xl font-normal leading-1 md:leading-9 mb-0">
            It may have expired or there could be a typo. <br />
            To get back on track, try visiting our homepage or contact us.
          </p>
        )}

        <div className="flex flex-wrap gap-4 md:gap-8">
          {!ctaButtons ? (
            <>
              <Button className="justify-center gap-3" buttonStyle={'solid'} size={'xl'} href={'/'}>
                <Icon name="Home" className="w-5 h-5 mb-0.5" />
                Home
              </Button>
              <Button
                className="justify-center gap-3"
                buttonStyle={'outline'}
                size={'xl'}
                href={
                  isAcademyExAu() ? '/contact-us' : '/contact' // This should be for .com.au
                }
              >
                <Icon name="MailOutline" className="w-5 h-5" />
                Contact us
              </Button>
            </>
          ) : (
            renderCTAButtons({
              buttons: ctaButtons as unknown as TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>[],
            })
          )}
        </div>
      </div>
    </div>
  )
}

export default Custom404Block
